import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { TableCell, TableRow, useTheme } from '@mui/material'
import { DataTableWrap, DetailCard } from 'components'
import { Box, Button, DataTable, Icon, Menu, Typography, unityTheme } from '@ntpkunity/controls'
import { formatCurrency } from '@helpers/methods'
import { CreditApplicationCases } from '@helpers/constants'
import { CreditApplicationStatus, Status } from '@helpers/enums'
import { useUpdateOrderStatusAndLender } from '@apis/dealer-workqueue.service'
import { useStoreContext } from '@store/storeContext'
import { ILender } from 'controls/multi-lender-dialog'
import { useOrderDocument, useOrderFnIDocument } from '@apis/order-management.service'

interface IOrderSubmission {
  id: number
  order_id: number
  lender_name: string
  provider: string
  status: string
  decision: {
    net_financed_amount: number
    apr: number
    terms: number
    tier: string
  }
  lender_id: number
  is_continue_order: boolean
}

interface ICreditApplicationProps {
  order_detail: any
  openSlModal: any
  lenders: any
  setSelectedLenders: (lenders: number[]) => void
}

const CreditApplication = ({
  order_detail,
  setSelectedLenders,
  openSlModal,
  lenders
}: ICreditApplicationProps) => {
  const theme = useTheme()
  const navigate = useNavigate()
  const { actions } = useStoreContext()

  const { mutate: updateOrderStatus, isLoading: orderApproveLoading } =
    useUpdateOrderStatusAndLender()
  const { mutateAsync: mutateOrderDocument } = useOrderDocument()
  const { mutateAsync: mutateOrderFnIDocument } = useOrderFnIDocument()

  const [orderSubmissions, setOrderSubmissions] = useState<IOrderSubmission[]>([])

  useEffect(() => {
    if (order_detail?.order_submissions?.length > 0) {
      const updatedOrderSubmissions = order_detail?.order_submissions?.map(
        (item: IOrderSubmission) => {
          let updatedItem: any = item
          if (item?.decision) {
            updatedItem.is_continue_order = checkOrderDetails(item)
            updatedItem.decision.net_financed_amount =
              item?.decision?.net_financed_amount &&
              `$${formatCurrency(item?.decision?.net_financed_amount)}`
            updatedItem.decision.apr = item?.decision?.apr && `${item?.decision?.apr}%`
            updatedItem.decision.terms = item?.decision?.terms && `${item?.decision?.terms} Months`
          }
          return updatedItem || {}
        }
      )

      setOrderSubmissions(updatedOrderSubmissions)
    }
  }, [order_detail?.order_submissions])

  const handleOptionClick = (
    isCreditApproved: boolean,
    isContinueOrder: boolean,
    lender_id: number,
    submission_id: number
  ) => {
    if (!isCreditApproved) {
      navigate(
        `/dms/order-stipulation/?vin=${order_detail?.Vehicle_details?.vin}&reference_id=${order_detail?.reference_number}&customer_reference_id=${order_detail?.customer_info?.reference_id}`,
        {
          state: { submission_id }
        }
      )
      return
    } else if (isCreditApproved && !isContinueOrder) {
      navigate(
        `/dms/desking?reference_id=${order_detail?.reference_number}&customer_reference_id=${order_detail?.customer_info?.reference_id}`,
        {
          state: { submission_id }
        }
      )
      return
    }

    const payload = {
      order_id: order_detail.reference_number,
      lender_id
    }

    updateOrderStatus(payload, {
      async onSuccess(response) {
        actions.setToast({
          toastMessage: 'Order Approved Successfully',
          toastState: true
        })
        if (response?.status === Status.Approved) {
          const [_orderDocumentResponse, _orderFnIDocumentResponse] = await Promise.all([
            mutateOrderDocument(response?.credit_approval_data),
            mutateOrderFnIDocument(response?.credit_approval_data)
          ])
        }
        navigate(`/dms/order-approved/${order_detail?.reference_number}`)
      },
      onError(error: any) {
        actions.setToast({
          toastMessage: error.error.toString(),
          toastState: true,
          variant: 'error'
        })
      }
    })
  }

  const handleOpenSelectModal = () => {
    if (lenders && lenders?.length > 0) {
      const defaultLender = lenders?.find(
        (lender: ILender) => lender.default_lender && !lender.is_already_selected
      )
      setSelectedLenders((defaultLender?.id && [defaultLender.id]) || [])

      openSlModal()
    }
  }

  const checkOrderDetails = (item: IOrderSubmission): boolean => {
    if (
      !item?.decision?.tier &&
      parseFloat(item?.decision?.apr?.toString() ?? '') === order_detail?.apr &&
      parseFloat(item?.decision?.net_financed_amount?.toString() ?? '') >=
        order_detail?.net_finance_amount &&
      parseFloat(item?.decision?.terms?.toString() ?? '') === order_detail?.contract_term
    ) {
      return true
    }

    return false
  }

  return (
    <DetailCard className="detail-card" mb={2}>
      <Box theme={theme} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
        <Typography theme={theme} component="h4" variant="h4" mb={2}>
          Credit Application
        </Typography>
        {lenders?.filter((item: ILender) => !item?.is_already_selected)?.length > 0 &&
          order_detail?.status === Status.FullyReceived && (
            <Menu
              theme={theme}
              disablePortal
              options={[
                {
                  optionText: 'Add more lenders ',
                  optionValue: 'AddMoreLenders '
                }
              ]}
              handleOptionClick={handleOpenSelectModal}
              render={(onMenuSelection: any) => (
                <Button defaultBtn iconText={<Icon name="MoreIcon" />} onClick={onMenuSelection} />
              )}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left'
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
            />
          )}
      </Box>
      <DataTableWrap className="table-pagination simple-table">
        <DataTable
          theme={unityTheme}
          variant="basic"
          theadChildren={
            <>
              <TableRow>
                <TableCell id="statusHead">Status</TableCell>
                <TableCell id="lenderHead">Lender</TableCell>
                <TableCell id="nfaHead">NFA</TableCell>
                <TableCell id="ratetHead">Rate</TableCell>
                <TableCell id="termHead">Term</TableCell>
                <TableCell className="action-cell fixed-cell"></TableCell>
              </TableRow>
            </>
          }
          tbodyChildren={
            <>
              {orderSubmissions?.map((item: IOrderSubmission, index: number) => {
                const key: string = item?.status
                const isCreditApproved: boolean = CreditApplicationStatus.APPROVED === key

                return (
                  <TableRow className="child-tr" key={index}>
                    <TableCell>
                      <Box theme={theme} className="center">
                        <Icon
                          name={key && CreditApplicationCases[key]?.icon}
                          className={key && CreditApplicationCases[key]?.class}
                        />
                        {key || '-'}
                      </Box>
                    </TableCell>
                    <TableCell>{item?.lender_name || '-'}</TableCell>
                    <TableCell>{item?.decision?.net_financed_amount || '-'}</TableCell>
                    <TableCell>{item?.decision?.apr || '-'}</TableCell>
                    <TableCell>{item?.decision?.terms || '-'}</TableCell>
                    {[
                      CreditApplicationStatus.APPROVED,
                      CreditApplicationStatus.CONDITIONED
                    ]?.includes(key as unknown as CreditApplicationStatus) &&
                      [Status.FullyReceived, Status.Conditioned]?.includes(
                        order_detail?.status
                      ) && (
                        <TableCell className="action-cell fixed-cell">
                          <Menu
                            theme={theme}
                            disablePortal
                            options={[
                              {
                                optionText: isCreditApproved
                                  ? item?.is_continue_order
                                    ? 'Continue Order'
                                    : 'Update Order'
                                  : 'View Conditions',
                                optionValue: 'orderOption',
                                disabled: orderApproveLoading
                              }
                            ]}
                            handleOptionClick={() =>
                              handleOptionClick(
                                isCreditApproved,
                                item?.is_continue_order,
                                item.lender_id,
                                item.id
                              )
                            }
                            render={(onMenuSelection: any) => (
                              <Button
                                defaultBtn
                                iconText={<Icon name="MoreIcon" />}
                                onClick={onMenuSelection}
                              />
                            )}
                            anchorOrigin={{
                              vertical: 'top',
                              horizontal: 'left'
                            }}
                            transformOrigin={{
                              vertical: 'top',
                              horizontal: 'right'
                            }}
                          />
                        </TableCell>
                      )}
                  </TableRow>
                )
              })}
            </>
          }
        />
      </DataTableWrap>
    </DetailCard>
  )
}

export default CreditApplication
