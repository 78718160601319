import { FC, useEffect, useState } from 'react'
import { useTheme } from '@mui/material'
import {
  Box,
  Typography,
  Button,
  // Tooltip,
  Icon,
  Dialog,
  Menu
} from '@ntpkunity/controls'
import { DocumentViewModal, DocumentViewer } from '@ntpkunity/controls-common'
import { DetailCard } from 'components'
import { useGetPreSignedUrl, useUpdateUnlockDocumentPackage } from '@apis/order-management.service'
import { InsuranceDocument, Status } from '@helpers/enums'
import ContractDocuments from './contract-documents'
import { capitalizeWords } from '@helpers/methods'
import { ContractstWrap } from './contracts.style'
interface IContractsProps {
  orderStatus: string
  dealerId: string
  referenceNumber: string
  referenceId: string
  contractDocument?: any
  generalDocument?: any
  creditDocument?: any
  setApprovedDoc?: any
  orderObject?: any
  setFetchDocumentPackages?: any
}
const Contracts: FC<IContractsProps> = ({
  dealerId,
  referenceNumber,
  referenceId,
  contractDocument,
  generalDocument,
  creditDocument,
  setApprovedDoc,
  orderObject,
  setFetchDocumentPackages
}) => {
  const { mutate: getPreSignedUrl } = useGetPreSignedUrl()
  const { mutate: updateUnlockDocumentPackage } = useUpdateUnlockDocumentPackage()
  const theme = useTheme()
  const [openDocumentViewer, setOpenDocumentViewer] = useState<boolean>(false)
  const [viewDocument, setViewDocument] = useState<any>({})
  const [openDocumentViewMode, setOpenDocumentViewMode] = useState<boolean>(false)

  useEffect(() => {
    if (viewDocument && Object.keys(viewDocument).length > 0 && !openDocumentViewer) {
      if (setApprovedDoc) {
        setApprovedDoc(!openDocumentViewer)
      }
    }

    if (!openDocumentViewer && !openDocumentViewMode) {
      if (setFetchDocumentPackages) {
        setFetchDocumentPackages(true)
      }
    }
  }, [openDocumentViewer])

  const handleDownloadAll = (type: string) => {
    const openDocumentsOneByOne = async (documents: any[]) => {
      for (const document of documents) {
        try {
          const url = await new Promise<string>((resolve, reject) => {
            getPreSignedUrl(
              { key: document?.generated_document_uri },
              {
                onSuccess(res: any) {
                  resolve(res)
                },
                onError(err: any) {
                  reject(err)
                }
              }
            )
          })

          window.open(url, '_blank')
        } catch (error) {
        }
      }
    }

    if (type == InsuranceDocument.Contract) {
      openDocumentsOneByOne(contractDocument?.[0]?.documents || [])
    } else if (type == InsuranceDocument.General) {
      openDocumentsOneByOne(generalDocument?.[0]?.documents || [])
    } else if (type == InsuranceDocument.Credit) {
      openDocumentsOneByOne(creditDocument?.[0]?.documents || [])
    }
  }

  const handleClose = () => {
    setOpenDocumentViewMode(false)
  }
  const hasCreditDocuments = creditDocument?.some((documentData: any) => documentData?.documents?.length > 0)

  const handleDocumentSigningSession = (document: any) => {
    updateUnlockDocumentPackage({ order_id: orderObject?.reference_number, doc_package_id: document?.identifier }, {
      onSuccess() {
        setFetchDocumentPackages(true)
      }
    })
  }

  return (
    <>
      {((contractDocument &&
        Array.isArray(contractDocument) &&
        contractDocument?.[0] &&
        contractDocument?.[0]?.documents &&
        contractDocument?.[0]?.documents?.length > 0) && (
          <DetailCard className="detail-card" mb={2}>
            <Box theme={theme} display={'flex'} justifyContent={'space-between'} mb={2}>
              <Box theme={theme}>
                <Typography component="h4" variant="h4" theme={theme} children={orderObject?.status === Status.Approved ? "Generated Documents" : "Documents"} />
                {contractDocument?.[0]?.is_locked ? (
                   <Typography component="p" variant="caption" theme={theme} className='text-danger'>
                    {"Please unlock the document signing session for the customer."}
                  </Typography>
                ) : null}
              </Box>
              {contractDocument?.[0]?.is_locked && (<Menu
                sx={{ ml: 2 }}
                theme={theme}
                options={[
                  {
                    optionText: (
                      <>
                        Unlock Signing Session
                      </>
                    ),
                    optionkey: 'UnlockSigningSession',
                    optionValue: contractDocument?.[0]
                  }
                ]}
                handleOptionClick={(_event: any, key: any, value: any) => {
                  switch (key) {
                    case 'UnlockSigningSession':
                      if (value) {
                        handleDocumentSigningSession(value)
                      }
                      break
                    default:
                      break
                  }
                }}
                render={(handleOptionClick: any) => (
                  <Box theme={theme}>
                    <Button
                      theme={theme}
                      defaultBtn
                      iconText={<Icon name="MoreIcon" />}
                      onClick={handleOptionClick}
                    />
                  </Box>
                )}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right'
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
              />)}
            </Box>
            {contractDocument?.[0]?.documents?.length > 0 && (
              <Box className="card-body" theme={theme}>
                <Box className="row" mb={3} theme={theme}>
                  <Typography
                    component="b"
                    variant="caption"
                    theme={theme}
                    className="fw-600 text-uppercase"
                    children={`${capitalizeWords(contractDocument?.[0]?.signature_type)} Signature Document(s)`}
                  />
                  <Button
                    defaultBtn
                    iconText={<Icon name="SaveIcon" />}
                    onClick={() => {
                      handleDownloadAll(InsuranceDocument.Contract)
                    }}
                  />
                </Box>
                {contractDocument?.[0]?.documents?.map((document: any, index: number) => (
                  <Box theme={theme} key={index} className="secondary-wrap with-border">
                    {(orderObject?.has_insurance && orderObject?.status === Status.Approved) ? (
                      <ContractDocuments
                        document={document}
                        setOpenDocumentViewer={setOpenDocumentViewer}
                        setViewDocument={setViewDocument}
                        setOpenDocumentViewMode={setOpenDocumentViewMode}
                        hasInsurance={orderObject?.Insurance_Inforation ? true : ''}
                        type={contractDocument?.[0]?.type}
                        isLocked={contractDocument?.[0]?.is_locked}
                      />
                    ) : (
                      <ContractDocuments
                        document={document}
                        setOpenDocumentViewer={setOpenDocumentViewer}
                        setOpenDocumentViewMode={setOpenDocumentViewMode}
                        setViewDocument={setViewDocument}
                        hasInsurance={orderObject?.Insurance_Inforation ? true : ''}
                        type={contractDocument?.[0]?.type + '_'}
                        isLocked={contractDocument?.[0]?.is_locked}
                      />
                    )}
                  </Box>
                ))}
              </Box>
            )}
          </DetailCard >
        ))}
      {
        (
          (generalDocument &&
            Array.isArray(generalDocument) &&
            generalDocument?.[0] &&
            generalDocument?.[0]?.documents &&
            generalDocument?.[0]?.documents?.length > 0) ||
          (creditDocument &&
            Array.isArray(creditDocument) &&
            hasCreditDocuments)
        ) &&
        (
          <DetailCard className="detail-card" mb={2}>
            <Box theme={theme} display={'flex'} justifyContent={'space-between'} mb={2}>
              <Box theme={theme}>
                <Typography component="h4" variant="h4" theme={theme} children={orderObject?.has_insurance ? "Documents" : "Generated Documents"} />
                {generalDocument?.[0]?.is_locked || creditDocument?.[0]?.is_locked ? (
                  <Typography component="p" variant="caption" theme={theme} className='text-danger'>
                    {"Please unlock the document signing session for the customer."}
                  </Typography>
                ) : null}
              </Box>
              {(!orderObject?.has_insurance && (generalDocument?.[0]?.is_locked || creditDocument?.[0]?.is_locked)) && (
                <Menu
                  sx={{ ml: 2 }}
                  theme={theme}
                  options={[
                    {
                      optionText: (
                        <>
                          Unlock Signing Session
                        </>
                      ),
                      optionkey: 'UnlockSigningSession',
                      optionValue: generalDocument?.[0] ?? creditDocument?.[0]
                    }
                  ]}
                  handleOptionClick={(_event: any, key: any, value: any) => {
                    switch (key) {
                      case 'UnlockSigningSession':
                        if (value) {
                          if (generalDocument?.[0]?.documents?.length > 0) {
                            handleDocumentSigningSession(generalDocument?.[0])
                          }
                          if (creditDocument?.[0]?.documents?.length > 0) {
                            handleDocumentSigningSession(creditDocument?.[0])
                          }
                        }
                        break
                      default:
                        break
                    }
                  }}
                  render={(handleOptionClick: any) => (
                    <Box theme={theme}>
                      <Button
                        theme={theme}
                        defaultBtn
                        iconText={<Icon name="MoreIcon" />}
                        onClick={handleOptionClick}
                      />
                    </Box>
                  )}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                  }}
                />
              )}
            </Box>
            {generalDocument?.[0]?.documents?.length > 0 && (
              <Box className="card-body" theme={theme}>
                <Box className="row" mb={3} theme={theme}>
                  <Typography
                    component="b"
                    variant="caption"
                    theme={theme}
                    className="fw-600 text-uppercase"
                    children={`${capitalizeWords(generalDocument?.[0]?.signature_type)} Signature Document(s)`}
                  />
                  <Button
                    defaultBtn
                    iconText={<Icon name="SaveIcon" />}
                    onClick={() => {
                      handleDownloadAll(InsuranceDocument.General)
                    }}
                  />
                </Box>
                {generalDocument?.[0]?.documents?.map((document: any, index: number) => (
                  <Box theme={theme} key={index} className="secondary-wrap with-border">
                    {orderObject?.has_insurance ? (
                      <ContractDocuments
                        document={document}
                        setOpenDocumentViewer={setOpenDocumentViewer}
                        setViewDocument={setViewDocument}
                        setOpenDocumentViewMode={setOpenDocumentViewMode}
                        hasInsurance={orderObject?.Insurance_Inforation ? true : ''}
                        type={generalDocument?.[0]?.type}
                        isLocked={generalDocument?.[0]?.is_locked}
                      />
                    ) : (
                      <ContractDocuments
                        document={document}
                        setOpenDocumentViewer={setOpenDocumentViewer}
                        setOpenDocumentViewMode={setOpenDocumentViewMode}
                        setViewDocument={setViewDocument}
                        hasInsurance={false}
                        type={generalDocument?.[0]?.type}
                        isLocked={generalDocument?.[0]?.is_locked}
                      />
                    )}
                  </Box>
                ))}
              </Box>
            )}
            {hasCreditDocuments && (
              <Box className="card-body" theme={theme}>
                <Box className="row" mb={3} theme={theme}>
                  <Typography
                    component="b"
                    variant="caption"
                    theme={theme}
                    className="fw-600 text-uppercase"
                    children={`${capitalizeWords(creditDocument?.[0]?.signature_type)} Signature Document(s)`}
                  />
                  <Button
                    defaultBtn
                    iconText={<Icon name="SaveIcon" />}
                    onClick={() => {
                      handleDownloadAll(InsuranceDocument.Credit)
                    }}
                  />
                </Box>

                {creditDocument?.map((documentData: any) => (
                  documentData?.documents?.length > 0 && (
                    documentData?.documents?.map((document: any, docIndex: number) => (
                        <Box theme={theme} key={docIndex} className="secondary-wrap with-border">
                        {orderObject?.has_insurance ? (
                          <ContractDocuments
                            document={document}
                            setOpenDocumentViewer={setOpenDocumentViewer}
                            setViewDocument={setViewDocument}
                            setOpenDocumentViewMode={setOpenDocumentViewMode}
                            hasInsurance={orderObject?.Insurance_Inforation ? true : ''}
                            type={creditDocument?.[0]?.type}
                            isLocked={creditDocument?.[0]?.is_locked}
                          />
                        ) : (
                          <ContractDocuments
                            document={document}
                            setOpenDocumentViewer={setOpenDocumentViewer}
                            setOpenDocumentViewMode={setOpenDocumentViewMode}
                            setViewDocument={setViewDocument}
                            hasInsurance={false}
                            type={creditDocument?.[0]?.type}
                            isLocked={creditDocument?.[0]?.is_locked}
                          />
                        )}
                      </Box>
                    ))
                  )
                ))}
              </Box>
            )}
          </DetailCard>
        )
      }
      {
        openDocumentViewer && (
          <>
            <DocumentViewModal
              theme={theme}
              selectedDocument={viewDocument}
              openPopup={openDocumentViewer}
              referenceId={referenceId}
              referenceNumber={referenceNumber}
              dealerId={dealerId}
              setOpenPopup={setOpenDocumentViewer}
            />
          </>
        )
      }
      {
        openDocumentViewMode && (
          <ContractstWrap theme={theme} className="contract-wrap">
            <Dialog
              disablePortal
              theme={theme}
              variant={undefined}
              open={openDocumentViewMode}
              size="xl"
              onCloseDialog={handleClose}
              noFooter
              title={viewDocument?.title}
            >
              <DocumentViewer
                theme={theme}
                selectedDocument={viewDocument}
                referenceId={referenceId}
                dealerId={dealerId}
                documentPackageIdentifier={viewDocument?.identifier}
                setOpenPopup={setOpenDocumentViewMode}
                viewOnly
              />
            </Dialog>
          </ContractstWrap>
        )
      }
    </>
  )
}

export default Contracts
