import { FC, useState, useEffect } from 'react'
import { useForm, Controller, SubmitHandler } from 'react-hook-form'
import {
  EMAIL_REGEX,
  PHONE_REGEX,
  DisclaimerNames,
  COAPPLICANT_EMAIL_UNIQUENESS,
  ALPHABETS_REGEX,
  ALPHABETS_REQUIRED
} from '@helpers/constants'
import { useTheme, Link } from '@mui/material'
import { CustomTheme } from '@styles/customTheme'
import { Button, Box, Input, Typography, Dialog, Select, Grid } from '@ntpkunity/controls'
import { useDisclaimers } from '@hooks/useDisclaimer'
import { validation } from '@helpers/methods'
import { useStoreContext } from '../store/storeContext'
import { useCheckCustomerStatus } from '@apis/customer-management.service'
import { useGetCustomerData, useUpdateCustomerContact } from '@apis/customer-detail.service'
import { useGetRating } from '@apis/configurations.service'
import DisableLoader from '@public/assets/images/loader-disabled.gif'
import { empty, mapPayload } from '@helpers/lead'
import { updateLead } from '@apis/lead-management'
import { LeadKey } from '@helpers/enums/lead'
import { ValidationMessages } from '@helpers/enums'

interface IFormInputs {
  customer_addresses: any
  email: string
  zipCode: string
  preferred_contact: string
  consent: boolean
  mobile_number: string
  credit_score: string
  first_name: string
  last_name: string
}
const CustomerDetailsControl: FC<{
  defaultData?: any
  closedModelFunction?: any
  creditScore?: boolean
  checkCustomerStatusData: any
  coApplicantOrderId?: number
  applicant_email?: string
  disableEmail?: boolean
}> = ({
  defaultData,
  closedModelFunction,
  checkCustomerStatusData,
  creditScore,
  coApplicantOrderId,
  applicant_email,
  disableEmail
}) => {
  const { states } = useStoreContext()
  const theme = useTheme()
  const form: {
    handleSubmit: any
    control: any
    formState: any
    setError: any
    getValues: any
    setValue: any
    watch: any
    trigger: any
    clearErrors: any
  } = useForm<IFormInputs>({
    defaultValues: defaultData,
    mode: 'onBlur'
  })

  const { disclaimers } = useDisclaimers([
    DisclaimerNames?.TERMS_AND_CONDITIONS,
    DisclaimerNames?.CCPA,
    DisclaimerNames?.TCPA
  ])

  const {
    mutate: customerStatus,
    data: customerStatusData,
    error: customerStatusError,
    isLoading: customerStatusIsLoading
  } = useCheckCustomerStatus()
  const { mutate: updateCustomerByReferenceId, isLoading: creatingUpdatingCustomer } =
    useUpdateCustomerContact()
  const { mutate: getCustomer } = useGetCustomerData()
  const { mutate: getCreditRating, data: _creditRatingData } = useGetRating()

  const [customerData, setCustomerData] = useState<any>(defaultData)
  const [termsConditionsModal, setTermsConditionsModal] = useState<any>(false)
  const [closeTcpaModal, setCloseTcpaModal] = useState<any>(false)
  const [closeCcpaDisclosureModal, setCloseCcpaDisclosureModal] = useState<any>(false)

  const { actions } = useStoreContext()
  const creditTerms = states?.dealDesk?.creditTerms || []
  const selectedCreditScore = defaultData?.credit_score ?? customerData?.credit_score
  const matchedTerm = selectedCreditScore ? (creditTerms?.find(
    (rating) => (selectedCreditScore >= rating?.score_from) && (selectedCreditScore <= rating?.score_to)
  ) ?? null) : null
  const creditRatingDetail = matchedTerm
    ? `${matchedTerm?.description}: ${matchedTerm?.score_from} - ${matchedTerm?.score_to}`
    : null

  useEffect(() => {
    if (customerStatusError) {
      form.setValue('consent', false)
    }
    if (creditScore) {
      getCreditRating({ company_id: states?.dealerInfo?.company_id })
    }
  }, [customerStatusError, form.formState.errors?.email])

  useEffect(() => {
    if (creditRatingDetail) {
      form.setValue("credit_score", creditRatingDetail)
    }
  }, [creditRatingDetail, form.setValue])

  const onSubmit: SubmitHandler<IFormInputs> = (data: IFormInputs) => {
    const creditScoreRange = data?.credit_score?.split(' - ') ?? ['0', '0']
    const lowerBound = parseInt(creditScoreRange[0]?.split(' ')[1] ?? 0)
    const upperBound = parseInt(creditScoreRange[1] ?? 0)
    const selectedCreditScore = (customerData?.credit_score >= lowerBound && customerData?.credit_score <= upperBound) ? customerData?.credit_score : lowerBound
    const creditScoreExpiry = selectedCreditScore !== customerData?.credit_score ? null : customerData?.credit_check_expiry
    const isCreditScoreChanged = lowerBound !== customerData?.credit_score
    const onlyCreditScoreChanged =
      selectedCreditScore !== customerData?.credit_score &&
      data?.preferred_contact === customerData?.preferred_contact &&
      (data?.preferred_contact !== "Email" ? data?.mobile_number === customerData?.mobile_number : true)
    if (
      isCreditScoreChanged ||
      data?.preferred_contact != customerData?.preferred_contact ||
      (data?.mobile_number && (data?.mobile_number != customerData?.mobile_number)) ||
      coApplicantOrderId
    ) {
      updateCustomerByReferenceId(
        {
          customer_profile: {
            updated_by: customerData?.email,
            preferred_contact: data?.preferred_contact,
            mobile_number: data?.mobile_number,
            first_name: data?.first_name ?? undefined,
            last_name: data?.last_name ?? undefined,
            ...(data?.credit_score && {
              credit_score: selectedCreditScore,
              credit_check_expiry: creditScoreExpiry
            })
          },
          reference_id: customerData?.reference_id,
          ...(coApplicantOrderId && { order_id: coApplicantOrderId })
        },
        {
          onSuccess: (_) => {
            const updatedData = {
              ...customerStatusData,
              ...data,
              reference_id: customerData?.reference_id,
              credit_rating: data?.credit_score?.split(': ')?.[0],
              preferred_contact: data?.preferred_contact,
              mobile_number: data?.mobile_number,
              ...(data?.credit_score && {
              credit_score: selectedCreditScore,
              credit_check_expiry: creditScoreExpiry
            })
            }
            checkCustomerStatusData(updatedData)
            closedModelFunction?.(false)
          },
          onError: (error: any) => {
            actions.setToast({
              toastMessage: error?.error?.response?.data?.detail?.toString(),
              toastState: true,
              variant: 'error'
            })
          }
        }
      )
    } else if (onlyCreditScoreChanged) {
      const updatedData = {
        ...customerStatusData,
        ...data,
        reference_id: customerData?.reference_id,
        credit_rating: data?.credit_score?.split(': ')?.[0],
        credit_score: selectedCreditScore,
        credit_expiry_date: creditScoreExpiry
      }
      checkCustomerStatusData(updatedData)
      closedModelFunction?.(false)
    } else {
      getCustomer(
        { reference_id: customerStatusData?.reference_id },
        {
          onSuccess(response) {
            const updatedData = {
              ...customerStatusData,
              ...response,
              credit_rating: data?.credit_score?.split(': ')?.[0]
            }
            checkCustomerStatusData(updatedData)
            closedModelFunction?.(false)
          }
        }
      )
    }
    if (states?.dealerInfo?.id && customerData?.reference_id) {
      empty()
      let lead = mapPayload(
        states?.dealerInfo?.id,
        customerData?.reference_id,
        LeadKey.Customer_Detail,
        { email: customerData?.email }
      )
      updateLead(lead)
    }
  }

  if (termsConditionsModal) {
    return (
      <CustomTheme className="custom-theme" theme={theme}>
        <Dialog
          disablePortal
          theme={theme}
          title={'Terms and Conditions'}
          open
          onCloseDialog={() => {
            setTermsConditionsModal(false)
          }}
          noFooter
        >
          <form onSubmit={form.handleSubmit()} id="customerForm">
            <Box theme={theme} className="custom-p">
              <div
                dangerouslySetInnerHTML={{
                  __html: disclaimers?.TERMS_AND_CONDITIONS as string
                }}
              ></div>
            </Box>
          </form>
        </Dialog>
      </CustomTheme>
    )
  }
  if (closeCcpaDisclosureModal) {
    return (
      <CustomTheme className="custom-theme" theme={theme}>
        <Dialog
          disablePortal
          theme={theme}
          title={'CCPA Disclosure'}
          open
          onCloseDialog={() => {
            setCloseCcpaDisclosureModal(false)
          }}
          noFooter
        >
          <form onSubmit={form.handleSubmit()} id="customerForm">
            <Box theme={theme} className="custom-p">
              <div dangerouslySetInnerHTML={{ __html: disclaimers?.CCPA as string }}></div>
            </Box>
          </form>
        </Dialog>
      </CustomTheme>
    )
  }
  if (closeTcpaModal) {
    return (
      <CustomTheme className="custom-theme" theme={theme}>
        <Dialog
          disablePortal
          theme={theme}
          title={'TCPA Disclosure'}
          open
          onCloseDialog={() => {
            setCloseTcpaModal(false)
          }}
          noFooter
        >
          <form onSubmit={form.handleSubmit()} id="customerForm">
            <Box theme={theme} className="custom-p">
              <div dangerouslySetInnerHTML={{ __html: disclaimers?.TCPA as string }}></div>
            </Box>
          </form>
        </Dialog>
      </CustomTheme>
    )
  }
  const checkCustomerStatus = () => {
    form.trigger(['email'])
    setTimeout(() => {
      if (!form.formState.errors?.email) {
        customerStatus(
          { email: form.getValues('email') },
          {
            onSuccess(res: any) {
              setCustomerData(res)
              if (res && res?.consent) {
                form.setValue('consent', true)
                form.setValue('preferred_contact', res?.preferred_contact)
              }
              if (coApplicantOrderId) {
                form.setValue('first_name', res?.first_name ?? '')
                form.setValue('last_name', res?.last_name ?? '')
              }
              if (res && res?.preferred_contact != 'Email') {
                form.setValue('mobile_number', res?.mobile_number ?? '')
              } else {
                form.setValue('consent', false)
              }
              if (res?.credit_score) {
                const creditScore = res?.credit_score
                const matchedTerm = creditTerms?.find(
                  (rating) => creditScore >= rating?.score_from && creditScore <= rating?.score_to
                )
                const creditRatingDetail = matchedTerm
                  ? `${matchedTerm?.description}: ${matchedTerm?.score_from} - ${matchedTerm?.score_to}`
                  : null

                form.setValue('credit_score', creditRatingDetail)
              }
              // navigate(`/lane/create-order/:vin/${res?.reference_id}`)
            },
            onError() {
              setCustomerData(null)
            }
          }
        )
      }
    })
  }
  const preferredContactMethodPhoneOrText = form.watch('preferred_contact') === 'Mobile Phone'
  const customEmailValidator = (handleUniquenessCheck: boolean) => ({
    required: {
      value: true,
      message: `${ValidationMessages.EMAIL} is required`
    },
    validate: {
      matchesPattern: (value: string | undefined) => {
        if (value && !EMAIL_REGEX.exec(value)) {
          return ValidationMessages.EMAIL_INVALID
        }
        if (handleUniquenessCheck && value === applicant_email) {
          return COAPPLICANT_EMAIL_UNIQUENESS
        }
        return true
      }
    }
  })

  return (
    <CustomTheme className="custom-theme" theme={theme}>
      <Dialog
        disablePortal
        theme={theme}
        title={coApplicantOrderId ? 'Enter Co-Applicant’s Details' : 'Enter Customer’s Details'}
        size="sm"
        open
        onCloseDialog={() => {
          closedModelFunction?.(false)
        }}
        customFooter={
          <>
            <Button
              theme={theme}
              secondary
              text={'Cancel'}
              onClick={() => {
                closedModelFunction?.(false)
              }}
            ></Button>
            <Button
              theme={theme}
              type="submit"
              primary
              disabled={customerStatusIsLoading || creatingUpdatingCustomer}
              text={coApplicantOrderId ? 'Add Co-Applicant' : 'Save Changes'}
              form={'customerForm'}
              startIcon={creatingUpdatingCustomer && <img src={DisableLoader} alt="Loader" />}
            ></Button>
          </>
        }
      >
        <form onSubmit={form.handleSubmit(onSubmit)} id="customerForm">
          <Controller
            name="email"
            control={form.control}
            defaultValue={''}
            rules={customEmailValidator(!!coApplicantOrderId)}
            render={({ field }) => (
              <Input
                theme={theme}
                testid="email"
                fullWidth
                label={'Email Address'}
                disabled={disableEmail}
                placeholder={'Please enter email'}
                type={'text'}
                {...field}
                onBlur={() => checkCustomerStatus()}
                error={form.formState.errors?.email?.message}
                helperText={customerStatusIsLoading && 'Waiting'}
              />
            )}
          />
          {coApplicantOrderId && (
            <Grid theme={theme} container columnSpacing={2} mb={3}>
              <Grid theme={theme} item xs={6}>
                <Controller
                  name="first_name"
                  control={form.control}
                  defaultValue={''}
                  rules={validation('First Name', true, true, ALPHABETS_REGEX, ALPHABETS_REQUIRED)}
                  render={({ field }) => (
                    <Input
                      theme={theme}
                      disabled={customerStatusIsLoading}
                      testid="first_name"
                      fullWidth
                      label={'First Name'}
                      placeholder={'Please enter first name'}
                      type={'text'}
                      {...field}
                      error={form.formState.errors?.first_name?.message}
                    />
                  )}
                />
              </Grid>
              <Grid theme={theme} item xs={6}>
                <Controller
                  name="last_name"
                  control={form.control}
                  defaultValue={''}
                  rules={validation('Last Name', true, true, ALPHABETS_REGEX, ALPHABETS_REQUIRED)}
                  render={({ field }) => (
                    <Input
                      theme={theme}
                      disabled={customerStatusIsLoading}
                      testid="last_name"
                      fullWidth
                      label={'Last Name'}
                      placeholder={'Please enter last name'}
                      type={'text'}
                      {...field}
                      error={form.formState.errors?.last_name?.message}
                    />
                  )}
                />
              </Grid>
            </Grid>
          )}
          <Controller
            name="preferred_contact"
            rules={validation('Preferred Contact', true)}
            control={form.control}
            defaultValue={''}
            render={({ field }) => (
              <Select
                disablePortal={false}
                theme={theme}
                disabled={customerStatusIsLoading}
                label={'Preferred Communication'}
                items={[
                  { text: 'Mobile Phone', value: 'Mobile Phone' },
                  { text: 'Email', value: 'Email' }
                ]}
                {...field}
                selectError={form?.formState?.errors?.preferred_contact?.message}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right'
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
              />
            )}
          />
          {preferredContactMethodPhoneOrText && (
            <Controller
              name="mobile_number"
              control={form.control}
              defaultValue={''}
              rules={validation('Phone', true, true, PHONE_REGEX, 'Valid Phone number is Required')}
              render={({ field }) => (
                <Box theme={theme} mb={3}>
                  <Input
                    theme={theme}
                    disabled={customerStatusIsLoading}
                    masking
                    maskNumeric
                    format="###-###-####"
                    fullWidth
                    label={'Mobile Phone Number'}
                    type={'text'}
                    {...field}
                    error={form.formState.errors?.mobile_number?.message}
                  />
                </Box>
              )}
            />
          )}
          {creditScore && (
            <Controller
              name="credit_score"
              rules={validation('Credit Score', true)}
              control={form.control}
              defaultValue={creditRatingDetail || ''}
              render={({ field }) => (
                <Select
                  disablePortal={false}
                  theme={theme}
                  disabled={customerStatusIsLoading}
                  label={'Credit Score'}
                  items={_creditRatingData?.map((item: any) => ({
                    text: `${item?.description}: ${item?.score_from} - ${item?.score_to}`,
                    value: `${item?.description}: ${item?.score_from} - ${item?.score_to}`
                  }))}
                  {...field}
                  selectError={form?.formState?.errors?.credit_score?.message}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                  }}
                />
              )}
            />
          )}
          <Box theme={theme}>
            <Typography theme={theme} variant="caption" component="span" className="text-muted">
              {(customerData?.consent?.length >= 2 && form.watch('preferred_contact') == 'Email') ||
              (customerData?.consent?.length >= 3 &&
                ['Mobile Phone'].includes(form.watch('preferred_contact'))) ? (
                false
              ) : (
                <span>
                  By continuing, you agree to the
                  {
                    <Link
                      href="javascript:void(0)"
                      className="link hover-underline"
                      onClick={() => {
                        setTermsConditionsModal(true)
                      }}
                    >
                      {' '}
                      terms and conditions,{' '}
                    </Link>
                  }
                  to the
                  {
                    <Link
                      href="javascript:void(0)"
                      className="link hover-underline"
                      onClick={() => {
                        setCloseCcpaDisclosureModal(true)
                      }}
                    >
                      {' '}
                      CCPA disclosure
                    </Link>
                  }
                  {preferredContactMethodPhoneOrText ? (
                    <>
                      , to the
                      {
                        <Link
                          href="javascript:void(0)"
                          className="link hover-underline"
                          onClick={() => {
                            setCloseTcpaModal(true)
                          }}
                        >
                          {' '}
                          TCPA disclosure{' '}
                        </Link>
                      }
                    </>
                  ) : (
                    ' '
                  )}
                  and to receive marketing material related to product(s).
                </span>
              )}
            </Typography>
          </Box>
        </form>
      </Dialog>
    </CustomTheme>
  )
}

export default CustomerDetailsControl
