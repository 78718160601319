export const EP_AUTH_LOGIN = 'dealer/authenticate'
export const EP_CHANGE_PASSWORD = 'dealer/change-password'
export const EP_AUTH_GET_USERS = 'users/all'
export const EP_CUSTOMER_STATUS_BY_EMAIL = 'customer-by-email?email'
export const EP_CUSTOMER_CHECK_CREATE_BY_EMAIL = 'check-customer-by-email'
export const EP_CREATE_CUSTOMER_CONSENT = 'address'
export const EP_SAVE_CUSTOMER_CONSENT = 'consents'
export const EP_VEHICLE = 'vehicle'
export const EP_GET_VEHICLES_BY_DEALER_CODE = 'vehicle/by-dealer-code'
export const EP_GET_REFERENCE_DATA_BY_DEALER_CODE = 'config/inventory/reference-data-by-dealer-code'
export const EP_GET_INVENTORY_MODELS = 'config/get-inventory-models'
export const EP_GET_INVENTORY_TRIMS = 'config/get-inventory-trims'
export const EP_GET_VEHICLES_BY_FILTER = 'config/inventory/filter'
export const EP_GET_VEHICLES_INVENTORY_BY_DEALER_CODE = 'config/inventory/filter'
export const EP_VEHICLE_DETAIL = 'config/vehicle-detail'
export const EP_REFERRAL_INVITE = 'referral'
export const EP_SAVE_ADD_ON = 'dealer/option'
export const PRE_QUALIFICATION = 'customer/prequalification_check'
export const EP_ADD_ON_ACCESSORIES = 'config/dealer/option/filter'
export const EP_GET_TRIMS = 'config/asset-model-trim'
export const EP_GET_MASTER_LIST_ACCESSORIES = 'master-list-accessories'
export const EP_GET_DEALER_FEE = 'dealer/fee'
export const EP_GET_DEALER_FEE_BY_DEALER_CODE = 'config/dealer/fee/dealer-code'
export const EP_GET_DEALER_PROFILE_BY_ID = 'config/dealer/profile'
export const EP_DEALER_PREFERENCE = 'dealer/preference'
export const EP_DEALER_PAYMENT = 'dealer/payment-detail'
export const EP_DEALER_PAYMENT_BY_DEALER_ID = 'dealer/payment-detail_by_dealer_id'
export const EP_DEALER_PREFERENCE_BY_DEALER_CODE = 'config/dealer/preference-by-dealer-code'
export const EP_DEALER_TRADEIN = 'dealer/tradein'
export const EP_DEALER_TRADEIN_BY_DEALER_ID = 'dealer/tradein-by-dealer-id'
export const EP_SAVE_DEALER_PROFILE = 'dealer/profile'
export const EP_SAVE_PEN_DEALER_ID = 'dealer/profile/pen-dealer-id'
export const EP_SAVE_ADDRESS = 'address'
export const EP_GET_STATES = 'config/state'
export const EP_GET_CONFIG_CONTRACT_TEMRS = 'config/contract-term'
export const EP_GET_CONFIG_SETUP_DATA = 'get-setup-data'
export const EP_GET_FARABI_BOT_DATA = 'affordability-bot'
export const EP_GET_FIND_VEHICLES = 'affordability-check/'
export const EP_GET_FIND_MONTHLY_PAYMENTS = 'affordability-check/monthly-payment-calculation/V2/'
export const EP_GET_CONFIG_ALLOWED_MILEAGE = 'config/contract-allowed-annum-mileage'
export const EP_GET_FEES = 'config/fee-name'
export const EP_GET_INVENTORY_OPTIONS_DETAIL = 'config/inventory/attached-option'
export const EP_UPDATE_VEHICLE_DETAIL = 'vehicle-detail'
export const EP_GET_FINANCIAL_INSURANCE_PRODUCTS = 'financial-insurance-products'
export const EP_GET_FINANCIAL_INSURANCE = 'financial-insurance'
export const EP_GET_FINANCIAL_INSURANCE_PRODUCTS_BY_DEALER_ID = 'config/financial-insurances/filter'
export const EP_CHECK_FINANCIAL_INSURANCE_PRODUCTS_BY_DEALER_ID = 'config/check/financial-insurance'
export const EP_PEN_GET_PROVIDER = 'pen/get-providers'
export const EP_PEN_GET_PRODUCT = 'pen/get-products'
export const EP_PEN_SET_DEALER = 'pen/set-dealer'
export const EP_GET_OPTIONS = 'config/dealer/option-model-name'
export const EP_DEALER_CONFIGURATIONS = 'dealer/configurations'
export const EP_GET_RATING = 'config/credit-ratings'
export const EP_GET_CONFIGURED_QUOTATION = 'configure/quotation'
export const EP_ORDER_DOCUMENT = 'order-document'
export const EP_ORDER_DOCUMENT_FNI = 'order-document-fni'
export const EP_CONVERT_QUOTATION_TO_APPLICATION = 'configure/quotation-to-order'
export const EP_GET_QUOTATION_FEE = 'configure/quotation/fee'
export const EP_VEHICLE_BY_VIN = 'config/vehicle'
export const EP_VEHICLE_BY_MODEL_CODE = 'configure/assets-by-trim-code' // quoate-comparison changed to configure/assets-by-trim-code/
export const EP_CALCULATE_ORDER_QUOTATION = 'quote/calculation/V2'
export const EP_GET_WORK_QUEUE = 'configure/workqueue/search'
export const EP_SAVE_ORDER_QUOTATION = 'configure/save-quotation'
export const EP_HUBEX_GET_FNIPRODUCT_RATES = 'get-rates'
export const EP_ORDER_EXPIRY_DATE = 'order/expiry'
export const EP_ORDER_BY_ID = 'configure/order/filter'
export const EP_GET_READY_CHECKLIST = 'get_event_associated_checklist/'
export const EP_READY_CHECKLIST_EVALUATE = 'config/checklist_response_verification/'
export const EP_GET_COUNTRIES = 'config/countries'
export const EP_GET_INSURANCE_INFO = 'customer/insurance-information-by-customer'
export const EP_GET_CUSTOMER_LICENSE_INFO = 'customer/license-info'
export const EP_GET_CUSTOMER_DETAILS = 'customer'
export const EP_GET_COMPANY_BY_TENANT_ID = 'config/company/by-tenant-id'
export const EP_GET_INTEGRATIONS_BY_COMPANY_ID = 'config/integration/company'
export const EP_GET_DEALER_AND_LENDER = 'config/dealer/find/by-tenant'
export const EP_GET_ODOMETER = 'config/odometer'
export const EP_GET_REASONS_BY_LENDER_ID = 'config/reason'
export const EP_GET_CUSTOMER_NAMES = 'customers'
export const EP_DOWNLOAD_DOCUMENT = 'customer/document/download'
export const EP_UPLOAD_DOCUMENT = 'customer/document'
export const EP_UPLOAD_DOCUMENT_by_CUSTOMER_REFERENCE = 'customer/documents'
export const EP_DELETE_DOCUMENT_BY_IDENTIFIER = 'customer/delete-document'
export const EP_SIGN_DOCUMENT_BY_ROLE = 'configure/by-role'
export const EP_FRAUD_CHECK = 'customer/fraud-check'
export const EP_DOCUMENT_PACKAGE_BY_REFERENCE_ID = `configure/document-package-by-reference-id`
export const EP_GET_SUBMITTED_ORDER_INFORMATION = 'order/order_information_for_submitted_order'
export const EP_GET_SAVED_CHECKLIST_OPTIONS = 'order/order-checklist'
export const EP_SAVE_CHECKLIST_PROGRESS = 'order/order-checklist'
export const EP_GET_AD_USER = 'user/ad-user'
export const UPDATE_ORDER_STATUS = 'configure/order/update-status'
export const UPDATE_ORDER_STATUS_AND_LENDER = 'order/approve-order-by-lender'
export const EP_GET_PROGRAMS = 'config/programs/all/'
export const EP_SAVE_CONTACT_DETAILS = 'customer/'
export const EP_SAVE_EMPLOYMENT_DETAILS = 'customer/employment-info'
export const LICENSE_DETAILS = 'customer/license-info'
export const EP_GET_INTEGRATION_BY_PROVIDER_NAME = 'integration/by_provider_name'
export const LICENSE_VERIFICATION_MITEK = '/mitek/verify-license'
export const ADD_FINANCE_DETAILS = 'customer/personal-finance'
export const EP_GET_EMPLOYMENT_INFO = 'customer/employment-info'
export const EP_UPDATE_EMPLOYMENT_DETAILS = 'customer/employment-info'
export const EP_FETCH_INSURANCE_DETAILS = 'customer/insurance-information'
export const EP_ADD_INSURANCE_DETAILS = 'configure/insurance'
export const LICENSE_VERIFICATION = 'customer/license-verification'
export const EP_GET_PREFIX = 'config/prefix'
export const EP_GET_CUSTOMER = 'customer'
export const EP_CANCEL_ORDER = 'order/update-credit-approver-decision/'
export const EP_CREDIT_REQUEST = 'credit-request'
export const EP_GET_ORDER_INFORMATION = 'order/order_information'
export const EP_GET_CURRENCIES = 'config/currencies'
export const EP_UPDATE_PAYMENT_STATUS = 'order/update-payment-status'
export const EP_GET_ALL_ORDERS = 'all-quotation-by-reference-number'
export const EP_GET_ALL_ORDERS_DOC_PACKAGES = 'configure/all-document-packages-by-reference-id'
export const EP_GET_PRE_SIGNED_URL = 'configure/document-pre-signed-url'
export const EP_GET_LENDER_INTEGRATIONS = 'integration/lender'
export const EP_UPDATE_CONTACT_DETAILS = 'customer'
export const EP_FINANCIAL_STIPULATION = 'stipulation/financial'
export const EP_Appointment = 'configure/appointment'
export const EP_UPDATE_SUBSCRIPTION = 'return/proceed-request'
export const EP_UPDATE_RETURN = 'return/update-request'
export const EP_GET_RETURNS = 'return/get-requests'
export const GET_USER_PROFILE = 'ums/user-profile'
export const EP_UPLOAD_DOCUMENT_BY_REFERENCE_NUMBER = 'order/order_information'
export const EP_UPLOAD_FILE = 'order/upload-signed-document'
export const EP_VERIFY_DOCUMENT = 'order/order-verify-document'
export const EP_STIPULATION = 'stipulation/stipulation-by-lender'
export const EP_CREDIT_RESPONSE = 'order/credit-decision'
export const EP_GET_CONTRACT = 'contract'
export const EP_GET_PAYMENT_DETAIL = 'get-payment-methods'
export const EP_CREATE_PAYMENT_DETAIL = 'add-payment-details/'
export const EP_REMOVE_PAYMENT_DETAIL = 'remove-payment-details'
export const EP_GET_ALL_QUOTATIONS = 'configure/all-quotation'
export const EP_GET_FP_BY_ID = 'config/financial-product'
export const EP_CUSTOMER_SIGNATURE = 'customer/signature'
export const EP_CHECK_DMS_CUSTOMER = 'check-dms-customer'
export const EP_UPDATE_DMS_CUSTOMER = 'update-dms-customer'
export const EP_CUSTOMER_SIGNATURE_BY_DEALER = 'customer/signature/by-dealer-id'
export const EP_CUSTOMER_DOCUMENT_SIGNATURE = 'customer/document/signature-document'
export const EP_CUSTOMER_SEARCH = 'customer/all-customers-dynamic-search'
export const EP_GET_ORDER_BY_CUSTOMER = 'configure/quotation-by-customers'
export const EP_GET_CONTRACTS = 'contracts'
export const EP_GET_CONTRACTS_SEARCH = 'contracts/search'
export const EP_GET_ACTIVE_ASSET_USAGE = 'asset-usage/active'
export const EP_AN_DEALERS = 'an-dealers'
export const EP_UPDATE_ORDER_TO_QUOTATION = 'configure/order-to-quotation'
export const EP_EXTERNAL_KEYS = 'external-keys/all'
export const EP_DYNAMIC_ORDERS_DATA = 'customer/all-customers-orders-dynamic-search'
export const EP_SUBMIT_ORDER = 'submit-order'
export const CUSTOMER_MANDATORY_DISCLAIMERS = 'configure/customer-mandatory-disclaimers'
export const EP_GET_DISCLAIMERS_BY_NAME = 'configure/disclaimer-by-name'
export const EP_SET_TRADE_IN_INSPECTION_STATUS = 'set-trade-in-inspection-status'
export const EP_GET_CHECK_S3_CREDENTIALS = 'config/check-s3-credentials'
export const EP_GET_BUCKET_UPLOAD_FILE = 'config/bucket/upload-file'
export const EP_GET_BUCKET_DELETE_FILE = 'config/bucket/delete-file'
export const EP_GET_OPTION_CATEGORY = 'config/option-category'
export const EP_GET_ASSET_CONDITION = 'config/asset-conditions'
export const EP_DEALER_OPTION = 'config/dealer/option'
export const DISCLAIMER_AGREEMENT = 'configure/disclaimer-agreement'
export const DMS_CUSTOMER_DISCLAIMER_AGREEMENT = 'configure/disclaimer-agreement'
export const DMS_GET_LEADS = 'lead'
export const EP_UPDATE_ORDER_ASSET = 'order/asset'
export const GET_VEHICLE = 'config/vehicle'
export const EP_GET_DEALER_LENDER = 'config/lender/dealer-lenders'
export const DMS_GET_DEALERS = 'dealer/get-associated-dealers'
export const EP_GET_INDIVIDUAL_AGREEMENT = 'individualized_agreement'
export const EP_GET_INDIVIDUAL = 'config/dealer'
export const EP_GET_SINGLE_ORDER_SUBMISSION = 'order/get-order-submission-by-id'
export const EP_RESUBMIT_CONDITIONED_ORDER = 'order/re-submit'
export const EP_UPDATE_ORDER_INDIVIDUAL_AGREEMENT = 'configure/individualized-agreement'
export const EP_GET_ALL_ORDER_DOCUMENTS = 'order/order_insurance_documents'
export const EP_DELETED_DOCUMENTS = 'order/insurance-document-deleted'
export const EP_UPDATE_INSURANCE_INFORMATION = 'customer/update-insurance-information'
export const EP_SEND_ORDER_LINK = 'customer/send-order-link'
export const EP_CONTRACT_DOCUMENT = 'contract-document'
export const GET_CUSTOMER_ORDER_SUBMISSIONS = 'order/get-customer-order-submissions'
export const EP_CUSTOMER_LICENSE_INFO_BY_PASS = 'customer/license-info/by-pass'
export const EP_GET_INSURANCE_COMPANIES = 'config/insurance-companies/active'
export const GET_RESERVATION = 'config/reservation-deposit'
export const EP_GET_PROVDER_BY_INTEGRATION_TYPE = 'config/integration/integration-type'
export const EP_GET_RATES = 'tax/get-taxes'
export const EP_HUBEX_GET_DEALER_SURCHARGES = 'get-dealer-surcharges'
export const EP_GET_DEALER_HOLIDAYS = 'config/dealer/holiday/dealer-code'
export const EP_GET_FINANCIAL_INSURANCE_PRODUCT_BUNDLES = 'config/financial-insurance-bundle'
export const EP_GET_ALL_FINANCIAL_INSURANCE_PRODUCTS_BY_DEALER_CODE =
  'config/financial-insurance-by-finance_type'
export const EP_WORKDAY = 'workday'
export const EP_CALCULATE_MONTHLY_IMPACTS = 'quote/calculation/monthlyImpact'
export const EP_DELETE_ORDER_CUSTOMER = 'order-customer'
export const EP_UPDATE_DEAL_INSURANCE_INDICATOR = 'configure/order/insurance-indicator'
export const EP_GET_REBATES = 'config/rebate/filter'
export const EP_UPLOAD_GENERAL_FILE = 'upload-document'
export const EP_UPDATE_ORDER_REBATE_DOCUMENT = 'order/order-rebate-documents'
export const SAVE_CUSTOMER_DISCLOSURE = 'customer/save-disclosure'
export const GET_CUSTOMER_DISCLOSURE = 'customer/get-disclosure'
export const EP_UNLOCK_DOCUMENT_PACKAGE = 'configure/document-package-unlock'
export const GET_ASSETS_BY_MODEL_NAME = 'config/asset-model/by-name'
export const EP_DEAL_RECALCULATION = 'order-recalculation'
export const EP_GET_INTEGRATION_BY_TYPE = 'config/integration/integration-type'
