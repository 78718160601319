import { styled } from '@mui/material'
import { Box, IBoxProps } from '@ntpkunity/controls'

export const TableListWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.table-list-wrap': {
    '.table-list-ul': {
      margin: 0,
      padding: 0,
      listStyleType: 'none',
      '.table-list-li': {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: '8px',
        borderBottom: '1px solid' + theme.palette.grey[50],
        minHeight: 32,
        whiteSpace: 'nowrap',
        '.label-col, .value-col': {
          width: '50%'
        },
        '.auto-search': {
          'span.MuiTypography-body2': {
            display: 'none'
          },
          '.btn-toggle': {
            '.MuiButtonBase-root': {
              color: theme.palette.primary.main,
              fontSize: theme.typography.caption.fontSize,
              fontWeight: theme.typography.fontWeightRegular,
              '.btn-label': {
                width: 'calc(100% - 16px)',
                textAlign: 'right'
              },
              svg: {
                width: 16,
                height: 16,

                path: {
                  stroke: theme.palette.grey[300]
                }
              }
            }
          },
          '.list-wrap': {
            width: 250,
            marginTop: 4,
            '.u-custom-autocomplete .MuiAutocomplete-root .u-form-group': {
              '.MuiInputBase-root': {
                height: 32,
                '.MuiInputBase-input': {
                  padding: '4px 18px 4px 12px',
                  fontSize: theme.typography.caption.fontSize
                }
              },
              '.MuiAutocomplete-endAdornment': {
                top: 'calc(50% - 12px)',
                '.MuiButtonBase-root': {
                  svg: {
                    width: 16,
                    height: 16
                  }
                }
              }
            },
            '.MuiAutocomplete-popper': {
              textAlign: 'left',
              width: '100%',
              '.MuiPaper-root .MuiAutocomplete-listbox .MuiAutocomplete-option, .MuiAutocomplete-noOptions':
                {
                  fontSize: theme.typography.caption.fontSize,
                  padding: 8,
                  lineHeight: 'normal'
                }
            }
          }
        },
        '.table-list-li-form': {
          '.u-form-group': {
            width: 80,
            float: 'right',
            '.MuiFormControl-root': {
              '.u-form-control, .u-form-control.u-select': {
                height: 24,
                '.MuiInputBase-input': {
                  padding: '4px 8px 4px 0px'
                },
                '.MuiSelect-icon': {
                  right: 0,
                  width: 16,
                  height: 16
                },
                '.MuiPaper-root.MuiMenu-paper': {
                  top: '38px!important',
                  minWidth: '250px !important ',
                  '.MuiList-root.MuiMenu-list': {
                    '.MuiButtonBase-root.u-dropdown-item': {
                      padding: 8,
                      fontSize: theme.typography.caption.fontSize,
                      textAlign: 'left'
                    }
                  }
                }
              }
            }
          },
          '&.no-bg': {
            '.u-form-group': {
              width: 'auto',
              '.MuiFormControl-root': {
                position: 'relative',
                top: 2,
                '.u-form-control, .u-form-control.u-select': {
                  height: 13,
                  backgroundColor: 'transparent',
                  '.MuiInputBase-input': {
                    padding: '0 16px 0 0',
                    fontSize: theme.typography.caption.fontSize,
                    color: theme.palette.primary.main
                  },
                  '.MuiOutlinedInput-notchedOutline': {
                    border: '0 solid transparent',
                    borderRadius: 0
                  }
                }
              }
            }
          }
        }
      }
    },
    '&.with-bg': {
      backgroundColor: theme.palette.common.white,
      borderRadius: 4,
      padding: '0 12px'
    }
  }
}))
