import { StyleVariables, lightTheme } from './theme'
import { styled } from '@mui/system'

export const CustomTheme = styled('div')(({ theme }) => ({
  '&.custom-theme': {
    '.MuiTypography-root': {
      fontFamily: StyleVariables.defaultFontFamily
    },
    '.fw-300': {
      fontWeight: lightTheme.typography.fontWeightLight
    },
    '.fw-400': {
      fontWeight: lightTheme.typography.fontWeightRegular
    },
    '.fw-500': {
      fontWeight: lightTheme.typography.fontWeightMedium
    },
    '.fw-600': {
      fontWeight: lightTheme.customVariables.fontWeightSemiBold
    },
    '.fw-700': {
      fontWeight: lightTheme.typography.fontWeightBold
    },
    '.text-uppercase': {
      textTransform: 'uppercase'
    },
    '.text-primary': {
      color: lightTheme.palette.primary.main
    },
    '.text-secondary': {
      color: lightTheme.palette.grey[800]
    },
    '.text-danger': {
      color: lightTheme.palette.error.main + '!important'
    },
    '.primary-trans-bg': {
      backgroundColor: StyleVariables.primaryTransBg
    },
    '.grey-bg': {
      backgroundColor: lightTheme.customVariables.tableStripedBg
    },
    '.MuiButtonBase-root.MuiButton-root.btn': {
      '&.btn-primary': {
        border: 'none',
        color: theme.palette.common.white,
        '&:hover': {
          background: theme.palette.grey[900] + '!important',
          backgroundColor: theme.palette.grey[900] + '!important',
          borderColor: theme.palette.grey[900] + '!important',
          color: [theme.palette.common.white]
        },
        '&.with-icon:hover': {
          svg: {
            path: {
              stroke: theme.palette.common.white + '!important'
            }
          }
        }
      },
      '&.Mui-disabled, &:disabled': {
        opacity: 0.5,
        cursor: 'not-allowed'
      }
    },

    '.u-dialog': {
      '&.confirmation-dialog .u-dialog-action .btn.btn-secondary': {
        marginTop: 0
      }
    },
    '.MuiModal-root-MuiPopover-root-MuiMenu-root, .u-dropdown-menu': {
      '.u-dropdown-item:hover': {
        fontWeight: lightTheme.typography.fontWeightRegular + '!important'
      }
    },

    '.MuiPopper-root .MuiPaper-root .MuiCalendarPicker-root': {
      '.PrivatePickersFadeTransitionGroup-root, .PrivatePickersFadeTransitionGroup-root': {
        color: lightTheme.palette.grey[900]
      }
    },

    '.link': {
      color: theme.palette.primary.main,
      cursor: 'pointer',
      '&.hover-underline:hover': {
        textDecoration: 'underline'
      },
      '&.disabled': {
        color: theme.palette.text.disabled + '!important',
        cursor: 'not-allowed',
        '&:hover': {
          textDecoration: 'none'
        }
      },
      '&.text-danger': {
        color: lightTheme.palette.error.main + '!important'
      }
    },

    '.dialog.date-time-picker': {
      '.MuiInputBase-root': {
        '&:hover': {
          '.MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.grey[900]
          }
        }
      }
    },
    '.u-form-group .u-form-control:hover fieldset': {
      borderColor: theme.palette.grey[900]
    },
    '.u-date-picker.u-form-group .u-form-control .MuiInputBase-formControl:hover fieldset': {
      borderColor: theme.palette.grey[900]
    },
    '.u-aside-wrap': {
      '.u-side-nav': {
        '.MuiDrawer-paper .side-nav-area-wrap .aside-content-wrap': {
          '.u-nav-wrap': {
            '.u-list-item:active, .u-list-item:focus': {
              backgroundColor: theme.palette.primary.main,

              'svg path, svg rect': {
                stroke: theme.palette.common.white + '!important'
              }
            }
          }
        }
      }
    },
    '.custom-p': {
      fontSize: lightTheme.typography.htmlFontSize,
      lineHeight: '20px',
      marginBottom: 16,
      fontWeight: lightTheme.typography.fontWeightRegular,
      fontFamily: lightTheme.typography.fontFamily
    },
    //////////////////////////////////////////////////////
    '.signature-popup-wrap': {
      '.top-bar': {
        display: 'none'
      },
      '.MuiDialog-root.u-dialog.MuiModal-root': {
        left: 80,
        top: 31,
        bottom: 11
      },
      '.u-dialog': {
        '.u-dialog-close svg path': {
          stroke: lightTheme.palette.common.white
        },
        '.wrap': {
          height: 'initial',
          '.MuiGrid-container .iframe, .drawer-wrap .scroll-container': {
            height: 'calc(100vh - 80px)'
          },
          '.MuiGrid-container .iframe .viewport': {
            height: 'calc(100vh - 84px)',
            iframe: {
              height: 'calc(100vh - 175px)'
            }
          },
          '.drawer-wrap .scroll-container': {
            padding: '40px 25px',
            '.MuiTypography-h3': {
              fontSize: '24px',
              lineHeight: '32px',
              fontWeight: '700'
            },
            '.MuiTypography-body2': {
              fontSize: '12px',
              lineHeight: '16px',
              fontWeight: '400'
            }
          }
        }
      },
      '.u-dialog.u-dialog-xl .MuiDialog-container .MuiDialog-paper': {
        margin: 'initial',
        position: 'initial',
        bottom: 'initial',
        borderRadius: 16,
        maxHeight: 'initial',
        maxWidth: '95%',
        '.u-dialog-title': {
          display: 'inline-block',
          position: 'absolute',
          width: '95%',
          top: -20,
          padding: 0,
          '.title-text': {
            display: 'none'
          }
        }
      }
    }
  }
}))
