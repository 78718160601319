import { styled } from '@mui/material/styles'
import { Box, IBoxProps } from '@ntpkunity/controls'

export const DuoTabWrap: any = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.duo-tab-wrap': {
    '&.align-center': {
      '.TabsUnstyled-root': {
        '.TabsListUnstyled-root': {
          justifyContent: 'center',
          margin: '0 auto 24px auto',
          width: 'max-content',
          '.TabUnstyled-root': {
            width: 'auto'
          }
        },
        '.tab-panel-default': {
          '.TabsUnstyled-root .TabsListUnstyled-root': {
            justifyContent: 'inherit',
            margin: 'inherit',
            width: 'inherit',
            '.TabUnstyled-root': {
              width: 'inherit'
            }
          }
        }
      }
    },
    '&.sm': {
      '.TabsUnstyled-root': {
        '.TabsListUnstyled-root': {
          '.TabUnstyled-root': {
            padding: '4px 12px 4px 12px',
            '&.Mui-selected': {
              fontWeight: theme.typography.fontWeightMedium
            }
          }
        },
        '.tab-panel-default': {
          '.TabsUnstyled-root': {
            '.TabsListUnstyled-root': {
              '.TabUnstyled-root': {
                padding: '8px 16px',
                '&.Mui-selected': {
                  fontWeight: theme.typography.fontWeightMedium
                }
              }
            }
          }
        }
      }
    },
    '&.fixed-child-tab-height': {
      height: '74vh',
      '.tab-panel-default .TabsUnstyled-root': {
        height: '62.4vh'
      }
    }
  }
}))
