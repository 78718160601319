import { useGetVehicleOptions } from '@apis/dealer-configurations.service'
import { InstallationMode } from '@helpers/enums'
import { useTheme } from '@mui/material'
import { DuoTab, Box } from '@ntpkunity/controls'
import { useStoreContext } from '@store/storeContext'
import { DialogTotal } from 'components'
import React, { FC, useEffect, useMemo, useState } from 'react'
import { IOption } from './addon-interface'
import { StickyDuoTab } from './addon-style'
import OptionsListPopup from './options-list-popup'

const AddOnOption: FC<{
  defaultOptions?: IOption[]
  defaultCurrency?: any
  setSelectedObjectsArray: any
  modelName: string
  vin: string
}> = ({ defaultOptions, defaultCurrency, setSelectedObjectsArray, modelName, vin }: any) => {
  const theme = useTheme()
  const { states } = useStoreContext()
  const [totalSumArray, setTotalSumArray] = useState<any>([])
  const [addedItems, setAddedItems] = useState<any>([])
  const [popupAddedItems, setPopupAddedItems] = useState<any>([])
  const { data } = useGetVehicleOptions(vin, states?.dealerAndLenderInfo?.dealer_code, modelName)
  const dealerOptions = Array.isArray(data) ? data : []
  useEffect(() => {
    if (defaultOptions) {
      const data = defaultOptions?.filter(
        (f: any) => !addedItems.find((e: any) => e === (f.option_id ?? f.id))
      )
      setAddedItems([...data.map((e: any) => e.option_id ?? e.id)])
      setPopupAddedItems([...data.map((e: any) => e.option_id ?? e.id)])
      setTotalSumArray([...defaultOptions])
      setSelectedObjectsArray([...defaultOptions])
    }
  }, [])

  const preInstalledOptions = (dealerOptions || [])?.filter((item: any) => {
    return item.category_name
      ? item.installation_mode === InstallationMode.PRE_INSTALLED && item.is_active === true
      : null
  })
  const callBack = (type: string, data: any) => {
    setTotalSumArray((prev: any) => {
      const updatedArray =
        type === 'add'
          ? [...prev, data]
          : prev?.filter((item: any) => (item.option_id ?? item.id) !== (data.option_id ?? data.id))
      setSelectedObjectsArray(updatedArray)
      return updatedArray
    })
  }
  const calculatedTotalSum = useMemo(() => {
    return totalSumArray.reduce((lastSum: number, obj: any) => {
      if (preInstalledOptions?.length > 0) {
        const matchingOption = preInstalledOptions.find(
          (preInstalledOption: any) =>
            preInstalledOption.id === obj.id || preInstalledOption.id === obj.option_id
        )
        if (matchingOption) {
          obj.applied_price = obj.is_price_inclusive
            ? 0
            : matchingOption.applied_price || matchingOption.price
        }
      }
      return lastSum + (obj.applied_price ?? obj.price ?? 0)
    }, 0)
  }, [totalSumArray, preInstalledOptions])

  const selectedArray = [
    ...Array.from(
      new Set(
        dealerOptions
          ?.filter((x: any) => x.is_active == true)
          ?.map((item: any) => item.category_name)
      )
    )
  ]

  const preinstalled = [
    {
      title: InstallationMode.PRE_INSTALLED,
      content: (
        <OptionsListPopup
          callBack={callBack}
          data={preInstalledOptions}
          addedItems={popupAddedItems}
          defaultCurrency={defaultCurrency}
          setAddedItems={setPopupAddedItems}
          preInstalledOptions={preInstalledOptions}
        />
      )
    }
  ]

  const categories_data = selectedArray?.map((result: any) => {
    {
      return {
        title: result,
        content: dealerOptions && (
          <OptionsListPopup
            callBack={callBack}
            data={dealerOptions?.filter((item: any) => {
              return item.category_name
                ? item.category_name == result &&
                    item.installation_mode !== InstallationMode.PRE_INSTALLED &&
                    item.is_active === true
                : null
            })}
            defaultCurrency={defaultCurrency}
            addedItems={popupAddedItems}
            setAddedItems={setPopupAddedItems}
          />
        )
      }
    }
  })
  const tabs = preinstalled.concat(categories_data)
  return (
    <>
      <StickyDuoTab theme={theme}>
        <DuoTab theme={theme} varient={'underline'} items={tabs} defaultTabIndex={0} />
      </StickyDuoTab>
      <DialogTotal
        theme={theme}
        className="dialog-total"
        display={'flex'}
        gap={2}
        justifyContent={'space-between'}
      >
        <Box theme={theme}>Total</Box>
        <Box theme={theme} className="primary" textAlign="right">
          {defaultCurrency}
          {calculatedTotalSum.toFixed(2)}
        </Box>
      </DialogTotal>
    </>
  )
}

export default React.memo(AddOnOption)
