import { FC, useEffect, useState } from 'react'
import { Link, useTheme } from '@mui/material'
import { DetailCard, ConfirmationDialog } from 'components'
import { Typography, Grid, Box, Button, Icon, Menu, FileUploadPreview, Dialog } from '@ntpkunity/controls'
import { InsuranceRecord } from 'models/order-information.interface'
import {
  useAddInsuranceInformation,
  useGetOrderInsuranceDocs,
  useGetPreSignedUrl,
  useUpdateInsuranceInformation,
  useDeletedOrderInsuranceDocs
} from '@apis/order-management.service'
import InsuranceControl from 'controls/InsuranceControl/insurance-control'
import { useParams } from 'react-router-dom'
import dayjs from 'dayjs'
import { formatPhoneNumber, utcToLocal } from '@helpers/methods'
import { URL_PATTERN_REGEX } from '@helpers/constants'
import { DialogMessages } from '@helpers/enums'

const InsuranceDetails: FC<{
  insurance: InsuranceRecord
  referenceId?: string
  setAddInsurance?: any
  setAddInsuranceVerified?: any
  isInsuranceEditingDisabled?: boolean
  hideMenuOption?: boolean
}> = ({
  insurance,
  referenceId,
  setAddInsurance,
  isInsuranceEditingDisabled = false,
  setAddInsuranceVerified,
  hideMenuOption = false
}) => {
    const theme = useTheme()
    const { reference_id } = useParams()
    const [insuranceData, setInsuranceData] = useState(insurance)
    const { mutateAsync: getPreSignedUrl } = useGetPreSignedUrl()
    const [openInsuranceDialog, setInsuranceOpenDialog] = useState(false)
    const { mutate: updateInsurance, isLoading: _insuranceLoading } = useUpdateInsuranceInformation()
    const { mutate: addInsurance, isLoading: _addInsuranceLoading } = useAddInsuranceInformation()
    const { data: orderInsuranceDocuments } = useGetOrderInsuranceDocs(reference_id)
    const { mutate: deletedInsuranceDocuments } = useDeletedOrderInsuranceDocs()
    const [documents, setDocuments] = useState<any[]>([])
    const [openFileDialog, setOpenFileDialog] = useState(false)
    const [insuranceDocumentID, setInsuranceDocumentID] = useState()
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState<number | undefined>(undefined)

    useEffect(() => {
      if (insurance) {
        setInsuranceData((prev) => {
          const { insurance_address: insurer_address, ...restInsurance } = insurance
          return { ...restInsurance, ...(insurer_address && { insurer_address }), ...prev }
        })
      }
    }, [insurance])

    useEffect(() => {
      if (orderInsuranceDocuments) {
        setInsuranceData((prev) => ({ ...prev, insurance_documents: orderInsuranceDocuments }))
        const documentsLocation: any = []
        orderInsuranceDocuments?.map(async (item: any) => {
          const response = await getPreSignedUrl(
            { key: item.document }
          )

          documentsLocation.push({
            ...item,
            location: response
          })
        })
        setDocuments(documentsLocation)
      }
    }, [orderInsuranceDocuments])

    const handleRemoveFile = (_key: string, filedata: any, index: number) => {
      setOpenFileDialog(false)
      setInsuranceDocumentID(filedata?.file?.id)
      setShowDeleteConfirmation(index)
    }

    const handleRemoveFileConfirmation = () => {
      if (insuranceDocumentID) {
        deletedInsuranceDocuments({ insurance_document_id: insuranceDocumentID }, {
          onSuccess(response: any) {
            const updatedDocuments = documents.filter((document: any) => document.id !== response.id)
            setDocuments(updatedDocuments)
            setShowDeleteConfirmation(undefined)
          }
        })
      }
    }

    const handleInsuranceData = (data: any) => {
      const payload = {
        ...data,
        agent_phone: data?.agent_phone?.substring(1),
        expiration_date: dayjs(data.expiration_date).format('YYYY-MM-DD'),
        reference_id: referenceId,
        created_by: 'lender',
        updated_by: 'lender'
      }
      setInsuranceData(data)

      if (insuranceData) {
        updateInsurance(payload, {
          onSuccess() {
            setAddInsurance(true)
            setAddInsuranceVerified(true)
            setInsuranceOpenDialog(false)
          }
        })
      } else {
        addInsurance(
          {
            ...data,
            agent_phone: data?.agent_phone?.substring(1),
            expiration_date: dayjs(data.expiration_date).format('YYYY-MM-DD'),
            order_reference_id: reference_id,
            reference_id: referenceId,
            created_by: 'lender',
            updated_by: 'lender'
          },
          {
            onSuccess() {
              setAddInsurance(true)
              setAddInsuranceVerified(true)
              setInsuranceOpenDialog(false)
            }
          }
        )
      }
    }

    const onViewFile = (_index: number, file: { file: any }) => {
      if (file?.file?.content_type?.includes('image')) return
      if (file?.file?.content_type === 'application/pdf') {
        const isValidUrl = URL_PATTERN_REGEX.test(file.file.document)
        const pdfURL = isValidUrl ? new URL(file.file.document).pathname.substring(1) : file.file.document

        getPreSignedUrl(
          { key: pdfURL },
          {
            onSuccess(res: any) {
              window.open(res, '_blank')
            }
          }
        )
      }
    }

    const address = insuranceData?.insurer_address
    const { address_line_1, address_line_2, city, state_name, zip_code, county } = address || {}
    const formattedAddress = [address_line_1, address_line_2, city, state_name, zip_code, county]
      .filter(Boolean)
      .join(', ')

    return (
      <>
        <DetailCard className="detail-card" mb={2}>
          <Box className="card-body" theme={theme}>
            <Box className="row" theme={theme}>
              <Typography component="h4" variant="h4" theme={theme}>Insurance</Typography>
              {!hideMenuOption && (<Menu
                sx={{ ml: 2 }}
                theme={theme}
                options={[
                  insuranceData
                    ? {
                      optionText: (
                        <>
                          <Icon className="menu-icon" name="EditIcon" /> Edit Insurance
                        </>
                      ),
                      optionkey: 'Edit',
                      optionValue: document,
                      disabled: isInsuranceEditingDisabled
                    }
                    : {
                      optionText: (
                        <>
                          <Icon className="menu-icon" name="AddIcon" /> Add Insurance
                        </>
                      ),
                      optionkey: 'Add',
                      optionValue: document
                    }
                ]}
                handleOptionClick={(_event: any, key: any, _value: any) => {
                  switch (key) {
                    case 'Edit':
                      setInsuranceOpenDialog(true)
                      break
                    case 'Add':
                      setInsuranceOpenDialog(true)
                      break
                    default:
                      break
                  }
                }}
                render={(handleOptionClick: any) => (
                  <Box theme={theme}>
                    <Button
                      theme={theme}
                      defaultBtn
                      iconText={<Icon name="MoreIcon" />}
                      onClick={handleOptionClick}
                    />
                  </Box>
                )}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right'
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
              />)}
            </Box>
            {insuranceData && (
              <Box className="card-body" mt={2} theme={theme}>
                <Box className="secondary-wrap" theme={theme}>
                  <Box className="row" theme={theme}>
                    <Box className="center title" theme={theme}></Box>
                    <Grid theme={theme} className="container" container>
                      <Grid theme={theme} item xl={4} lg={4} md={4} sm={4} xs={12}>
                        <Typography theme={theme} variant="body2" component="h3" className="label">
                          Insurance Provider
                        </Typography>
                      </Grid>
                      <Grid theme={theme} item xl={8} lg={8} md={8} sm={8} xs={12}>
                        <Typography theme={theme} variant="body2" component="span">
                          {insuranceData?.insurer_name ?? '-'}
                        </Typography>
                      </Grid>
                      {/* *********************** */}
                      <Grid theme={theme} item xl={4} lg={4} md={4} sm={4} xs={12}>
                        <Typography theme={theme} variant="body2" component="h3" className="label">
                          Contact Number
                        </Typography>
                      </Grid>
                      <Grid theme={theme} item xl={8} lg={8} md={8} sm={8} xs={12}>
                        <Typography
                          theme={theme}
                          variant="body2"
                          component="h3"
                          className="label-detail"
                        >
                          {formatPhoneNumber(insuranceData?.agent_phone) || '-'}
                        </Typography>
                      </Grid>
                      {/* *********************** */}
                      <Grid theme={theme} item xl={4} lg={4} md={4} sm={4} xs={12}>
                        <Typography theme={theme} variant="body2" component="h3" className="label">
                          Policy Number
                        </Typography>
                      </Grid>
                      <Grid theme={theme} item xl={8} lg={8} md={8} sm={8} xs={12}>
                        <Typography
                          theme={theme}
                          variant="body2"
                          component="h3"
                          className="label-detail"
                        >
                          {insuranceData?.policy_number ?? '-'}
                        </Typography>
                      </Grid>
                      {/* *********************** */}
                      <Grid theme={theme} item xl={4} lg={4} md={4} sm={4} xs={12}>
                        <Typography theme={theme} variant="body2" component="h3" className="label">
                          Expiration
                        </Typography>
                      </Grid>
                      <Grid theme={theme} item xl={8} lg={8} md={8} sm={8} xs={12}>
                        <Typography
                          theme={theme}
                          variant="body2"
                          component="h3"
                          className="label-detail"
                        >
                          {utcToLocal(insuranceData?.expiration_date, 'MM/DD/YYYY') ?? '-'}
                        </Typography>
                      </Grid>
                      {/* *********************** */}
                      <Grid theme={theme} item xl={4} lg={4} md={4} sm={4} xs={12}>
                        <Typography theme={theme} variant="body2" component="h3" className="label">
                          Address
                        </Typography>
                      </Grid>
                      <Grid theme={theme} item xl={8} lg={8} md={8} sm={8} xs={12}>
                        <Typography
                          theme={theme}
                          variant="body2"
                          component="h3"
                          className="label-detail"
                        >
                          {formattedAddress ?? '-'}
                        </Typography>
                      </Grid>
                      {/* *********************** */}
                      {!!documents?.length && (
                        <Grid theme={theme} item xl={4} lg={4} md={4} sm={4} xs={12}>
                          <Typography
                            theme={theme}
                            variant="body2"
                            component="h3"
                            className="label"
                          >
                            Proof of Insurance
                          </Typography>
                        </Grid>
                      )}
                      {!!documents?.length && (
                        <Grid theme={theme} item xl={8} lg={8} md={8} sm={8} xs={12}>
                          <Typography
                            theme={theme}
                            variant="body2"
                            component="h3"
                            className="label-detail">
                            <Link className="link" onClick={() => setOpenFileDialog(true)}>
                              View Details
                            </Link>
                          </Typography>
                        </Grid>
                      )}
                    </Grid>
                  </Box>
                </Box>
              </Box>
            )}
          </Box>
        </DetailCard>

        {(showDeleteConfirmation === 0 || !!showDeleteConfirmation) && (
          <ConfirmationDialog
            openPopUp={showDeleteConfirmation === 0 || !!showDeleteConfirmation}
            confirmationTitle={DialogMessages.removeCoAPplicantSecondaryText}
            primaryBtnText={DialogMessages.fniAndMspRemoveBtnText}
            icon={<Icon className="alert-icon" name="DialogAlertIcon" />}
            hideCancelButton={false}
            setPopUpState={() => { }}
            onConfirm={handleRemoveFileConfirmation}
            onCancel={() => setShowDeleteConfirmation(undefined)}
          />
        )}

        {openFileDialog && (
          <Dialog
            theme={theme}
            title="Insurance Documents"
            size="sm"
            open={openFileDialog}
            onCloseDialog={() => setOpenFileDialog(false)}
            customFooter={
              <>
              </>
            }
          >
            <FileUploadPreview
              files={documents?.map((item, index) => ({
                key: index.toString(),
                file: {
                  ...item,
                  type: item.content_type
                },
                viewIcon: true,
                preview: 'fileIcon',
                onViewFile: onViewFile,
                onRemoveFile: handleRemoveFile
              }))}
              theme={theme}
            />
            <div id="preview-container" style={{ marginTop: '20px', maxHeight: '500px', overflow: 'auto' }}></div>
          </Dialog>
        )}

        {openInsuranceDialog && (
          <InsuranceControl
            openInsuranceDialog={openInsuranceDialog}
            insuranceFormData={{
              ...insuranceData,
              insurer_name: insuranceData?.insurer_name
                ? {
                  id: insuranceData?.insurer_name ?? '',
                  label: insuranceData?.insurer_name ?? ''
                }
                : null
            }}
            setInsuranceFormData={handleInsuranceData}
            setInsuranceOpenDialog={setInsuranceOpenDialog}
          />
        )}
      </>
    )
  }

export default InsuranceDetails
