import { FC } from 'react'
import { Box } from '@ntpkunity/controls'
import { useTheme } from '@mui/material'
import { AvailableDealTypes } from '@helpers/enums'

const ProductFinanceDetails: FC<{
  order: any
}> = ({ order }) => {
  const theme = useTheme()
  return (
    <Box theme={theme} className="action-details">
      <Box theme={theme} className="title-md">
        Net Financed Amount
      </Box>
      <Box theme={theme} className="title-lg">
        {'$'}
        {order?.net_finance_amount &&
          order?.net_finance_amount.toLocaleString(undefined, {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2
          })}
      </Box>
      <Box theme={theme} className="title-sm">
        Type:{' '}
        <b>
          {order?.contract_term} Months {order?.finance_type}
        </b>
        , Rate:{' '}
        <b>
          {order?.apr &&
            order?.apr?.toLocaleString(undefined, {
              maximumFractionDigits: 4,
              minimumFractionDigits: 4
            })}
          %
        </b>
      </Box>
      <Box theme={theme} className="title-sm">
        <>Est. Periodic Payment: </>
        <b>
          {'$'}
          {order?.estimated_monthly_payment &&
            order?.estimated_monthly_payment?.toLocaleString(undefined, {
              maximumFractionDigits: 4,
              minimumFractionDigits: 2
            })}
        </b>
      </Box>
    </Box>
  )
}

const ProductCashBanner: FC<{
  order: any
}> = ({ order }) => {
  const theme = useTheme()
  return (
    <Box theme={theme} className="action-details">
      <Box theme={theme} className="title-md">
        Total Payable Amount
      </Box>
      <Box theme={theme} className="title-lg">
        {'$'}
        {order?.due_at_signing &&
          order?.due_at_signing.toLocaleString(undefined, {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2
          })}
      </Box>
      <Box theme={theme} className="title-sm">
        Deal Type: <b>{order?.finance_type}</b>
      </Box>
    </Box>
  )
}

export const ProductBannerSection: FC<{
  order: any
}> = ({ order }) => {
  const isCashDealType = order?.finance_type == AvailableDealTypes.CASH

  return (
    <>
      {isCashDealType ? (
        <ProductCashBanner order={order} />
      ) : (
        <ProductFinanceDetails order={order} />
      )}
    </>
  )
}
