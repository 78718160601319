import { FC, ReactNode, useEffect, useMemo, useState } from 'react'
import { useTheme } from '@mui/material'
import { ScrollableTabs } from '@ntpkunity/controls'
import VehicleAmountDraft from './vehicle-amount-draft'
import { ScrollableTabsWrap } from '@components'
import AddOpptions from './add-options'
import { useStoreContext } from '@store/storeContext'
import { useCheckFinancialInsuranceByDealerCode } from '@apis/financial-insurance.service'
import { FinanceTypes, useDeskingContext } from './desking-context-provider'
import { TabName } from '@helpers/enums'
import CashVehicleAmountDraft from './cash-vehicle-amount-draft'

interface OrderDetailsProps {
  orderReferenceId?: string | null
}

type OrderDetailsScrollAbleTab = { content: ReactNode; title: ReactNode }

const OrderDetails: FC<OrderDetailsProps> = ({ orderReferenceId }) => {
  const theme = useTheme()
  const [currentTab, setCurrentTab] = useState<number>(0)
  const {
    states: {
      dealDesk: {
        dealerProfile: { dealer_code }
      },
      dealerInfo: { company_id }
    }
  } = useStoreContext()
  const {
    state: { vehiclesData, vehiclesVins, isVehicleSelectedAndOrderSaved, preview, finance_type }
  } = useDeskingContext()

  const isCash = useMemo(() => finance_type == FinanceTypes.CASH, [finance_type])

  const isPreviewPage = useMemo(
    () => !!orderReferenceId && !!dealer_code && !!company_id && isVehicleSelectedAndOrderSaved,
    [dealer_code, company_id, orderReferenceId, isVehicleSelectedAndOrderSaved]
  )

  const { data: fniAndMsp } = useCheckFinancialInsuranceByDealerCode(
    isPreviewPage && {
      dealer_code,
      company_id
    }
  )

  const vin = useMemo(
    () => (isVehicleSelectedAndOrderSaved ? preview.vin : vehiclesVins[0]),
    [isVehicleSelectedAndOrderSaved, preview.vin, vehiclesVins]
  )
  const { fni } = vehiclesData?.get(vin) ?? {}

  const { isFniPresentInOrder, isMSPPresentInOrder } = useMemo(() => {
    const isFniOrMsp = fni?.finance?.length ? fni.finance : fni?.lease ?? []

    return {
      isFniPresentInOrder: isFniOrMsp.some((orderItem: any) => !orderItem?.is_msp),
      isMSPPresentInOrder: isFniOrMsp.some((orderItem: any) => orderItem?.is_msp)
    }
  }, [fni])

  const tabs = useMemo(() => {
    const showFni = (fniAndMsp?.fni && isPreviewPage) || isFniPresentInOrder || isCash
    const showMsp = (fniAndMsp?.msp && isPreviewPage) || isMSPPresentInOrder || isCash

    const items: OrderDetailsScrollAbleTab[] = []

    if (showFni)
      items.push({
        title: 'F&I Products',
        content: <AddOpptions tabName={TabName.FNI} showFni={showFni} showMsp={showMsp} />
      })

    items.push(
      {
        title: 'Add-Ons',
        content: <AddOpptions tabName={TabName.OPTIONS} showFni={showFni} showMsp={showMsp} />
      },
      {
        title: 'Fees',
        content: <AddOpptions tabName={TabName.FEES} showFni={showFni} showMsp={showMsp} />
      }
    )

    if (showMsp)
      items.push({
        title: 'Maint. & Service Products',
        content: <AddOpptions tabName={TabName.MSP} showFni={showFni} showMsp={showMsp} />
      })
    if (isVehicleSelectedAndOrderSaved || isCash) {
      items.push({
        title: 'Rebates',
        content: <AddOpptions tabName={TabName.REBATES} showFni={showFni} showMsp={showMsp} />
      })
    }
    return items
  }, [fniAndMsp, isPreviewPage, isFniPresentInOrder, isFniPresentInOrder, isCash])

  useEffect(() => {
    if (typeof isVehicleSelectedAndOrderSaved !== 'undefined') setCurrentTab(0)
  }, [isVehicleSelectedAndOrderSaved, isCash])

  return (
    <>
      {isCash ? <CashVehicleAmountDraft /> : <VehicleAmountDraft />}
      <ScrollableTabsWrap theme={theme} className="scrollable-tabs-wrap sm">
        <ScrollableTabs
          key={currentTab}
          theme={theme}
          items={tabs}
          scrollButtons={'auto'}
          tabValue={currentTab}
          onChange={(_key, index) => setCurrentTab(Number(index ?? 0))}
        />
      </ScrollableTabsWrap>
    </>
  )
}

export default OrderDetails
