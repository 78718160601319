import { FC, useState } from 'react'
import { useTheme, ToggleButton } from '@mui/material'
import {
  Box,
  Typography,
  Button,
  // Tooltip,
  Icon,
  Menu,
  Grid
} from '@ntpkunity/controls'
import { ToggleCollapse, ToggleCollapseWrap } from 'components'
import { useGetPreSignedUrl } from '@apis/order-management.service'
interface IContractDocumentProps {
  document?: any
  setViewDocument: any
  setOpenDocumentViewer: any
  hasInsurance: any
  setOpenDocumentViewMode?: any
  type?: string
  isLocked?: boolean
}
const ContractDocuments: FC<IContractDocumentProps> = ({
  document,
  setViewDocument,
  setOpenDocumentViewer,
  hasInsurance,
  setOpenDocumentViewMode,
  type,
  isLocked
}) => {
  const theme = useTheme()
  const [ToggleCollapseOpen, ToggleCollapseSetOpen] = useState(true)
  const { mutate: getPreSignedUrl } = useGetPreSignedUrl()

  return (
    <>
      <Box className="row" theme={theme}>
        <ToggleCollapse className="toggle-collapse" theme={theme}>
          <ToggleButton
            value="check"
            selected={ToggleCollapseOpen}
            onChange={() => {
              ToggleCollapseSetOpen(!ToggleCollapseOpen)
            }}
          >
            <Icon name="ChevronDown" />
          </ToggleButton>
        </ToggleCollapse>

        <Box className="center title" theme={theme}>
          <Icon name="DocumentIcon" />
          <Typography
            variant="body2"
            sx={{ ml: 2 }}
            component="h3"
            className="card-text"
            theme={theme}
            children={document?.title}
          />
        </Box>
        <Box className="center actions" theme={theme}>
          <Icon
            className="icon"
            name={`${document?.signatures?.every((signature: any) => signature?.status === 'Signed') ? 'GreenCheckIcon' : 'NotVerifiedIcon'}`}
          />
          {
            (hasInsurance && type !== "Contract") ? <Menu
              sx={{ ml: 2 }}
              theme={theme}
              options={[
                {
                  optionText: (
                    <>
                      View
                    </>
                  ),
                  optionkey: 'View',
                  optionValue: document
                }
              ]}
              handleOptionClick={(_event: any, key: any, value: any) => {
                switch (key) {
                  case 'View':
                    setOpenDocumentViewMode(true)
                    setViewDocument(value)
                    break
                  default:
                    break
                }
              }}
              render={(handleOptionClick: any) => (
                <Box theme={theme}>
                  <Button
                    theme={theme}
                    defaultBtn
                    iconText={<Icon name="MoreIcon" />}
                    onClick={handleOptionClick}
                  />
                </Box>
              )}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right'
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
            /> : <Menu
              sx={{ ml: 2 }}
              theme={theme}
              options={[
                !isLocked ? {
                  optionText: (
                    <>
                      View & Sign
                    </>
                  ),
                  optionkey: 'View & Sign',
                  optionValue: document
                } : {
                  optionText: (
                    <>
                      View
                    </>
                  ),
                  optionkey: 'View',
                  optionValue: document
                },
                {
                  optionText: (
                    <>
                      Download
                    </>
                  ),
                  optionkey: 'Download',
                  optionValue: document
                }
              ]}
              handleOptionClick={(_event: any, key: any, value: any) => {
                switch (key) {
                  case 'Download':
                    getPreSignedUrl(
                      { key: value?.generated_document_uri },
                      {
                        onSuccess(res: any) {
                          window.open(res, '_blank')
                        }
                      }
                    )
                    break
                  case 'View & Sign':
                    setOpenDocumentViewer(true)
                    setViewDocument(value)
                    break
                  case 'View':
                    setOpenDocumentViewMode(true)
                    setViewDocument(value)
                    break
                  default:
                    break
                }
              }}
              render={(handleOptionClick: any) => (
                <Box theme={theme}>
                  <Button
                    theme={theme}
                    defaultBtn
                    iconText={<Icon name="MoreIcon" />}
                    onClick={handleOptionClick}
                  />
                </Box>
              )}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right'
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
            />
          }
        </Box>
      </Box>
      <ToggleCollapseWrap className="toggle-collapse-wrap" theme={theme}>
        <Box
          theme={theme}
          pt={2}
          pl={9}
          pr={9}
          className={ToggleCollapseOpen ? 'hide-slide-top' : 'show-slide-top'}
        >
          {document?.signatures
            ?.filter((item: any) => item.type == 'signature')
            ?.map((item: any, index: number) => (
              <Grid
                theme={theme}
                className="container"
                container
                rowSpacing={0}
                columnSpacing={1}
                key={index}
              >
                <Grid theme={theme} item xl={4} lg={4} md={4} sm={4} xs={6}>
                  <Typography
                    theme={theme}
                    variant="body2"
                    component="h3"
                    className="label"
                    children={item?.signer}
                  />
                </Grid>
                <Grid theme={theme} item xl={8} lg={8} md={8} sm={8} xs={6}>
                  <Typography
                    theme={theme}
                    variant="body2"
                    component="span"
                    children={item?.status === 'NotSigned' ? 'Not Signed' : 'Signed'}
                  />
                </Grid>
              </Grid>
            ))}
        </Box>
      </ToggleCollapseWrap>
      {/* <Box className="line" theme={theme}>
        <hr />
      </Box> */}
    </>
  )
}

export default ContractDocuments
