import { useEffect, useMemo, useState } from "react"
import { TableCell, TableRow, useTheme } from "@mui/material"
import { Box, Button, Checkbox, DataTable, Icon, Menu, Input, Skeleton } from "@ntpkunity/controls"
import { DataTableWrap } from "components/DataTableWrap"
import { useGetRebates } from "@apis/configurations.service"
import { useStoreContext } from "@store/storeContext"
import { IRebate, IRebateDocument, IStates } from "@models"
import { formatCurrency } from "@helpers/methods"
import { DialogTotal } from "@components"
import AddRebateDocumentControl from "controls/rebates-control/add-document"
import { DeskingState } from "./desking-context-provider"
import { LENDER_REBATE } from "@helpers/constants"
import { FinanceType } from "@helpers/enums/finance-type.enum"

interface IRebatesProps {
    rebates: IRebate[],
    selectedRebates: IRebate[],
    defaultCurrency: string,
    deskingState: DeskingState,
    vin: string,
    finance_type: string,
    setRebates: (rebate: IRebate[]) => void
    setSelectedRebates: (selectedRebates: IRebate[]) => void
}

export const Rebates = ({
    rebates,
    defaultCurrency,
    selectedRebates,
    deskingState,
    vin: _vin,
    finance_type,
    setSelectedRebates,
    setRebates
}: IRebatesProps) => {
    const theme = useTheme()
    const { states: { dealerInfo, dealDesk } } = useStoreContext()
    const { isVehicleSelectedAndOrderSaved } = deskingState || {}

    const [isOpen, setIsOpen] = useState<IRebate | undefined>(undefined)
    const [editingPrice, setEditingPrice] = useState<{ [key: number]: boolean }>({})
    const [priceDraft, setPriceDraft] = useState<{ [key: number]: string }>({})

    const dealerState = dealDesk?.states?.find((state: IStates) => state.code === dealDesk.stateCode)
    const { data: resultedRebates, isLoading: fetchRebatesLoading } = useGetRebates(
        dealerInfo.dealer_code,
        finance_type,
        dealerState?.id
    )

    const isCashDeal = finance_type?.toLowerCase() === FinanceType.CASH
    useEffect(() => {
        const receivedRebates = resultedRebates?.result ?? []
        if ((isVehicleSelectedAndOrderSaved || isCashDeal) && resultedRebates && Array.isArray(receivedRebates)) {
            if (selectedRebates?.length > 0) {
                let revisedRebates = receivedRebates.map((i: IRebate) => {
                    const selectedEl: IRebate | undefined = selectedRebates.find((j: IRebate) => (j.rebate_id || j.id) === i.id)
                    if (selectedEl && selectedEl.rebate_id && selectedEl.rebate_id === i.id) return selectedEl
                    if (selectedEl && selectedEl.id === i.id) return { ...selectedEl, ...i, rebate_amount: selectedEl.rebate_amount }
                    return i
                })

                const revisedRebateIds = revisedRebates.map(item => item.id)
                const savedRebates = selectedRebates.filter(item => !revisedRebateIds.includes((item.rebate_id ?? item?.id ?? -1)))
                if (savedRebates?.length > 0) revisedRebates = [...savedRebates, ...revisedRebates]
                setNewSelectedRebates(selectedRebates, revisedRebates)
                setRebates(revisedRebates)
            } else setRebates(receivedRebates)
        }
    }, [resultedRebates])

    const setNewSelectedRebates = (selectedArray: IRebate[], newRebates: IRebate[]) => {
        const setOfIds = newRebates.map(item => item.id)
        setSelectedRebates(selectedArray.filter(item => item.rebate_id || setOfIds.includes(item.id)))
    }

    const rebatesSum = useMemo(() => {
        if (Array.isArray(selectedRebates)) {
            return selectedRebates.reduce((sum: number, item: IRebate) => sum + Number(item.rebate_amount), 0)
        }

        return 0
    }, [selectedRebates])

    const isAllRebatesSelected = useMemo(() => {
        if (selectedRebates?.length > 0) {
            const setOfIds = new Set(selectedRebates.map(item => item.id))
            return rebates.every(item => setOfIds.has(item.id))
        }
        return false
    }, [rebates, selectedRebates])

    const handleEditPrice = (data: IRebate, index: number, type: string) => {
        setEditingPrice((prevState) => ({
            ...prevState,
            [index]: !prevState[index]
        }))
        if (type !== 'edit') return
        setPriceDraft((prevDraft) => ({
            ...prevDraft,
            [index]: data?.rebate_amount
        }))
    }

    const handleCancelEdit = (index: number) => {
        setEditingPrice((prevState) => ({
            ...prevState,
            [index]: false
        }))
        setPriceDraft((prevDraft) => ({
            ...prevDraft,
            [index]: rebates?.[index]?.rebate_amount
        }))
    }

    const handleSetPriceDraftValue = (index: number, newPrice: string) => {
        setPriceDraft((prevDraft) => ({
            ...prevDraft,
            [index]: newPrice
        }))
    }

    const handlePriceChange = (index: number, newPrice: string) => {
        const updatedData = [...rebates]
        updatedData[index].rebate_amount = newPrice
        setRebates(updatedData)

        if (isRebateAdded(updatedData[index]?.id)) {
            setSelectedRebates(selectedRebates?.map(item => {
                if (item.id === updatedData[index]?.id) return {
                    ...item,
                    rebate_amount: newPrice
                }

                return item
            }))
        }
    }

    const isRebateAdded = (id: number) => !!selectedRebates.find(rebate => rebate.id === id)
    const handleCheckboxChange = (rebate: IRebate) => {
        if (isRebateAdded(rebate.id)) {
            setSelectedRebates(selectedRebates?.filter(item => item.id !== rebate.id))
            return
        }

        setSelectedRebates([...selectedRebates, rebate])
    }

    const handleSelectAll = () => {
        if (selectedRebates?.length === rebates?.length) {
            const filteredRebates = selectedRebates?.filter(item => item.type === LENDER_REBATE)
            setSelectedRebates(filteredRebates)
            return
        }

        setSelectedRebates(rebates)
    }

    const handleClose = () => setIsOpen(undefined)
    const handleEditFee = (item: IRebate) => setIsOpen(item)
    const handleSaveDocuments = (documents: IRebateDocument[], rebate_id: number | undefined = 0) => {
        setRebates(rebates?.map(item => {
            if (item.id === rebate_id) return {
                ...item,
                documents: documents
            }

            return item
        }))

        if (isRebateAdded(rebate_id)) {
            setSelectedRebates(selectedRebates?.map(item => {
                if (item.id === rebate_id) return {
                    ...item,
                    documents: documents
                }

                return item
            }))
        }

        handleClose()
    }

    return (
        <>
            {
                !fetchRebatesLoading ? <>
                    <DataTableWrap theme={theme} className="table-pagination table-dialog with-tabs" mt={2}>
                        <Box theme={theme} className="scroll">
                            <Box theme={theme} className="scroll-hide sh-fixed-cell spr-border" />
                        </Box>
                        <DataTable
                            theme={theme}
                            variant="basic"
                            theadChildren={
                                <>
                                    <TableRow>
                                        <TableCell className="checkbox-cell fixed-cell">
                                            <Checkbox
                                                label=""
                                                theme={theme}
                                                checkBoxChecked={isAllRebatesSelected}
                                                onChange={handleSelectAll}
                                            />
                                        </TableCell>
                                        <TableCell className="img-cell">
                                            <Icon name="ImageIcon" />
                                        </TableCell>
                                        <TableCell id="productName">Rebate Name</TableCell>
                                        <TableCell id="category">Rebate Code</TableCell>
                                        <TableCell id="provider">Rebate Amount</TableCell>
                                        <TableCell className="action-cell fixed-cell" />
                                    </TableRow>
                                </>
                            }
                            tbodyChildren={
                                rebates?.map((item: IRebate, index: number) => {
                                    return <TableRow className='child-tr' key={index}>
                                        <TableCell className="checkbox-cell fixed-cell">
                                            <Checkbox
                                                label=""
                                                theme={theme}
                                                checkBoxChecked={isRebateAdded(item?.id)}
                                                onChange={() => handleCheckboxChange(item)}
                                                formControldisabled={item.type === LENDER_REBATE}
                                                checkBoxDisabled={item.type === LENDER_REBATE}
                                            />
                                        </TableCell>
                                        <TableCell className="img-cell">
                                            <img
                                                src={
                                                    item?.rebate_images?.[0]?.location ??
                                                    require('../../public/assets/images/no-img.svg')
                                                }
                                                className="prd-img"
                                                alt="Car"
                                            />
                                        </TableCell>
                                        <TableCell>{item.rebate_name}</TableCell>
                                        <TableCell>{item.rebate_code}</TableCell>
                                        <TableCell className="editable-cell text-right">
                                            <Box theme={theme} className="cell-content" justifyContent={'flex-end'}>
                                                {editingPrice[index] ? (
                                                    <>
                                                        <Input
                                                            theme={theme}
                                                            startAdornment={defaultCurrency}
                                                            type="text"
                                                            value={priceDraft[index]}
                                                            onChange={(e) => handleSetPriceDraftValue(index, e)}
                                                            masking
                                                            maskDecimalScale={2}
                                                            maskNumeric
                                                        />
                                                        <Button
                                                            theme={theme}
                                                            defaultBtn
                                                            className="btn-tick"
                                                            iconText={<Icon name="TickIcon" />}
                                                            onClick={() => {
                                                                handlePriceChange(
                                                                    index,
                                                                    priceDraft[index] || item?.rebate_amount
                                                                )
                                                                handleEditPrice(item, index, 'change')
                                                            }}
                                                        />
                                                        <Button
                                                            theme={theme}
                                                            defaultBtn
                                                            className="btn-close"
                                                            iconText={<Icon name="CloseBlack" />}
                                                            onClick={() => handleCancelEdit(index)}
                                                        />
                                                    </>
                                                ) : (
                                                    <>
                                                        <span>
                                                            {defaultCurrency}{' '}
                                                            {formatCurrency(Number(item.rebate_amount))}
                                                        </span>
                                                        {item.type !== LENDER_REBATE && (
                                                            <Button
                                                                iconText={<Icon name="EditIcon" />}
                                                                defaultBtn
                                                                theme={theme}
                                                                onClick={() => handleEditPrice(item, index, 'edit')}
                                                            />
                                                        )}
                                                    </>
                                                )}
                                            </Box>
                                        </TableCell>
                                        <TableCell className="action-cell fixed-cell">
                                            {item.type !== LENDER_REBATE && (
                                                <Menu
                                                    theme={theme}
                                                    options={[
                                                        {
                                                            optionText: 'Add Documents',
                                                            optionValue: 'Add_Documents'
                                                        }
                                                    ]}
                                                    handleOptionClick={(_event, _key, value) => {
                                                        switch (value) {
                                                            case 'Add_Documents':
                                                                handleEditFee(item)
                                                                break
                                                        }
                                                    }}
                                                    render={(onMenuSelection: any) => (
                                                        <Button
                                                            theme={theme}
                                                            defaultBtn
                                                            iconText={<Icon name="MoreIcon" />}
                                                            onClick={onMenuSelection}
                                                        />
                                                    )}
                                                    anchorOrigin={{
                                                        vertical: 'top',
                                                        horizontal: 'left'
                                                    }}
                                                    transformOrigin={{
                                                        vertical: 'top',
                                                        horizontal: 'right'
                                                    }}
                                                />
                                            )}
                                        </TableCell>
                                    </TableRow>
                                })

                            }
                        />
                    </DataTableWrap>
                    <DialogTotal
                        theme={theme}
                        className="dialog-total"
                        display={'flex'}
                        gap={2}
                        justifyContent={'space-between'}
                    >
                        <Box theme={theme} className="fw-700">
                            Total
                        </Box>
                        <Box theme={theme} className="primary" textAlign="right">
                            {defaultCurrency}
                            {formatCurrency(rebatesSum)}
                        </Box>
                    </DialogTotal>
                </>
                    : <Skeleton
                        theme={theme}
                        variant="circular"
                        width={'100%'}
                        height={'50px'}
                        sx={{ borderRadius: '8px' }}
                    />
            }
            <AddRebateDocumentControl
                rebate={isOpen}
                handleClose={handleClose}
                handleSaveDocumentsCallBack={handleSaveDocuments}
            />
        </>
    )
}
