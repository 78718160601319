import { styled } from '@mui/material/styles'
import { Box } from '@ntpkunity/controls'

export const StickyDuoTab = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'inline-block',
  '.tab-list-underline': {
    backgroundColor: theme.palette.common.white,
    position: 'sticky',
    top: -60,
    left: 0,
    zIndex: 1,
    width: '75%'
  },
  '.TabPanelUnstyled-root': {
    overflow: 'hidden',
    paddingTop: 16,
    marginTop: -16
  },
  '.search': {
    width: '25%',
    display: 'inline-block',
    borderBottom: '1px solid' + theme.palette.divider,
    backgroundColor: theme.palette.common.white,
    position: 'sticky',
    top: -60,
    right: 0,
    zIndex: 1,
    float: 'right',
    paddingLeft: 24,
    paddingTop: 8,
    '.MuiOutlinedInput-notchedOutline': {
      border: '0 solid transparent',
      borderRadius: 0
    },
    '.Mui-focused fieldset': {
      border: '0 solid transparent'
    },
    '.MuiInputAdornment-root': {
      position: 'absolute',
      right: 0,
      zIndex: -1,
      opacity: 0.75
    },
    '.MuiOutlinedInput-input': {
      paddingRight: '15px !important'
    }
  }
}))
