import { FC, ReactNode, useEffect, useState } from 'react'
import { useQueryClient } from 'react-query'
import { Link, useTheme } from '@mui/material'
import { LayoutWithSideNav } from '@styles/layout'
import { Box, Button, Typography, Icon, Input, Select } from '@ntpkunity/controls'
import {
  UseQuery_Get_Work_Order,
  UseQuery_Get_ScheduledPickupOrder,
  UseQuery_Get_CancelledOrder,
  UseQuery_Get_CompletedOrder,
  UseQuery_Get_AppointmentOrders,
  UseQuery_Get_PendingConfirmationOrder
} from '@apis/work-order.service'
import { IMenuOptions, IWorkOrder, IWorkOrderData } from '@models'
import { useNavigate } from 'react-router-dom'
import { useStoreContext } from '@store/storeContext'
import VehicleOrderCard from 'components/VehicleCard/VehicleCard'
import {
  Status,
  WorkQueueMenuOption,
  DialogMessages,
  WorkQueueState,
  QueryKeys
} from '@helpers/enums'
import {
  OrderTable,
  SchedulePickupTable,
  CancelledOrderTable,
  CompletedOrderTable,
  AppointmentOrderTable
} from '@app/index'
import { ConfirmationDialog } from 'components/ConfirmationDialog/ConfirmationDialog'
import { styled } from '@mui/material/styles'
import { useUpdateOrderStatus } from '@apis/dealer-workqueue.service'
import { HeaderSearch, ReactCarousel, WorkQueueHeader } from 'components'
import PendingConfirmationOrderTable from '@app/work-queue-tables/pending-confirmation-order-table'
import PreQualificationTable from '@app/work-queue-tables/pre-qualification-table'
import { PRE_QUALIFICATION_CONSTANTS } from '@helpers/constants'
import { UseQuery_Get_PreQualifiedResponses } from '@apis/customer-management.service'
import PreQualificationAlert from '@app/work-queue-tables/pre-qualification-dialog/pre-qualification-response'
import { IPreQualificationResponse } from 'types/pre-qualified'
import {
  dealexPermissions,
  useHasPermissions
  //@ts-ignore
} from '@ntpkunity/controls-ums'
const VehicleCardWrap = styled('div')(() => ({
  '&.vehicle-card-wrap': {
    width: '100%',
    '.react-multi-carousel-list': {
      padding: 0,
      '.react-multi-carousel-track': {
        '.react-multi-carousel-item': {
          paddingRight: 10,
          paddingLeft: 10,
          '.vehicle-card': {
            maxWidth: '100%',
            minWidth: 'inherit',
            width: '100%',
            marginBottom: 25
          }
        }
      }
    }
  }
}))

const Workqueue: FC = () => {
  const theme = useTheme()
  let navigate = useNavigate()
  const { states, actions } = useStoreContext()
  const [confirmationPopupState, setConfirmationPopupState] = useState<boolean>(false)
  const queryClient = useQueryClient()
  const [defaultCurrency, setDefaultCurrency] = useState('')
  const [orderPageNumber, setOrderPageNumber] = useState<number>(0)
  const [orderPageSize, setOrderPageSize] = useState<number>(10)
  const [schedulePageNumber, setSchedulePageNumber] = useState<number>(0)
  const [schedulePageSize, setSchedulePageSize] = useState<number>(10)
  const [pendingConfirmationPageSize, setPendingConfirmationPageSize] = useState<number>(10)
  const [pendingConfirmationPageNumber, setPendingConfirmationPageNumber] = useState<number>(0)
  const [cancelledPageNumber, setCancelledPageNumber] = useState<number>(0)
  const [cancelledPageSize, setCancelledPageSize] = useState<number>(10)
  const [completedPageNumber, setCompletedPageNumber] = useState<number>(0)
  const [completedPageSize, setCompletedPageSize] = useState<number>(10)
  const [appointmentPageNumber, setAppointmentPageNumber] = useState<number>(0)
  const [appointmentPageSize, setAppointmentPageSize] = useState<number>(10)
  const [orderstatus, setOrderStatus] = useState<Status>(Status.Cancelled)
  const [preQualifiedResponse, setPreQualificationResponse] = useState<
    IPreQualificationResponse | undefined
  >(undefined)
  const [confirmationPopup, setConfirmationPopup] = useState<{
    confirmationText: string
    confirmationTitle: string
    icon: ReactNode
    isButtonTypeDanger: boolean
    optionaldata: boolean
  }>({
    confirmationTitle: DialogMessages.confirmationTitle,
    confirmationText: DialogMessages.confirmationText,
    icon: <Icon name="ErrorTriangle" />,
    isButtonTypeDanger: false,
    optionaldata: false
  })
  const currencies: any = queryClient.getQueryData([
    QueryKeys.GET_ALL_CURRENCIES,
    states?.lenderInfo?.id
  ])
  const [actionData, setActionData] = useState<IWorkOrder>()
  const orderStatusFilter = [
    Status.Draft,
    Status.Submitted,
    Status.Approved,
    Status.Conditioned,
    Status.InReview,
    Status.Reviewed,
    Status.ReSubmitted,
    Status.PendingTradeInInspection,
    Status.PendingTradeInConfirmation,
    Status.AwaitingMore,
    Status.FullyReceived
  ]
    .map((order) => `&multiple_order_status=${order}`)
    .join('')
  const completedStatusFilter = [Status.Converted, Status.Completed]
    .map((order) => `&multiple_order_status=${order}`)
    .join('')
  const [orderColumnFilters, setOrderColumnFilters] = useState(
    `page_number=${orderPageNumber}&page_size=${orderPageSize}`.concat(orderStatusFilter)
  )
  const [scheduleColumnFilters, setScheduleColumnFilters] = useState(
    `page_number=${schedulePageNumber}&page_size=${schedulePageSize}&multiple_order_status=${Status.SchedulePickup}`
  )
  const [pendingConfirmationColumnFilters, setPendingConfirmationColumnFilters] = useState(
    `page_number=${pendingConfirmationPageNumber}&page_size=${pendingConfirmationPageSize}&multiple_order_status=${Status.PendingConfirmation}`
  )
  const [cancelledColumnFilters, setCancelledColumnFilters] = useState(
    `page_number=${cancelledPageNumber}&page_size=${cancelledPageSize}&multiple_order_status=${Status.Cancelled}&multiple_order_status=${Status.Declined}&multiple_order_status=${Status.Withdrawn}`
  )
  const [completedColumnFilters, setCompletedColumnFilters] = useState(
    `page_number=${completedPageNumber}&page_size=${completedPageSize}`.concat(
      completedStatusFilter
    )
  )
  const [appointmentColumnFilters, setAppointmentColumnFilters] = useState(
    `page_number=${appointmentPageNumber}&page_size=${appointmentPageSize}&multiple_order_status=${Status.Appointment}`
  )
  const orderMenuOptions: Array<IMenuOptions> = [
    {
      optionText: WorkQueueMenuOption.EDIT_ORDER,
      optionkey: WorkQueueMenuOption.EDIT_ORDER,
      optionValue: {}
    },
    {
      optionText: WorkQueueMenuOption.ORDER_SUMMARY,
      optionkey: WorkQueueMenuOption.ORDER_SUMMARY,
      optionValue: {}
    },
    {
      optionText: WorkQueueMenuOption.CANCEL_ORDER,
      optionkey: WorkQueueMenuOption.CANCEL_ORDER,
      optionValue: {}
    }
  ]

  const appointmentMenuOptions: Array<IMenuOptions> = [
    {
      optionText: WorkQueueMenuOption.COMPLETE_ORDER,
      optionkey: WorkQueueMenuOption.COMPLETE_ORDER,
      optionValue: {}
    },
    {
      optionText: WorkQueueMenuOption.RESCHEDULE_ORDER,
      optionkey: WorkQueueMenuOption.RESCHEDULE_ORDER,
      optionValue: {}
    },
    {
      optionText: WorkQueueMenuOption.CANCEL_ORDER,
      optionkey: WorkQueueMenuOption.CANCEL_ORDER,
      optionValue: {}
    }
  ]
  const { data: orderData } = UseQuery_Get_Work_Order(orderColumnFilters)
  const { data: schedulePickupData } = UseQuery_Get_ScheduledPickupOrder(scheduleColumnFilters)
  const { data: pendingConfirmationData } = UseQuery_Get_PendingConfirmationOrder(
    pendingConfirmationColumnFilters
  )
  const { data: cancelledOrderData } = UseQuery_Get_CancelledOrder(cancelledColumnFilters)
  const { data: completedOrderData } = UseQuery_Get_CompletedOrder(completedColumnFilters)
  const { data: appointmentOrderData } = UseQuery_Get_AppointmentOrders(appointmentColumnFilters)
  const { data: preQualifiedData } = UseQuery_Get_PreQualifiedResponses()
  const { mutate: cancelOrder } = useUpdateOrderStatus()
  const adminPermission = useHasPermissions(dealexPermissions?.MENUS?.DEALER_SELECTION)
  const disableButton =
    (states?.dealerInfo && (!states?.dealerInfo?.is_active || states?.dealerInfo?.error)) ||
    !adminPermission
  const [cardView, setCardView] = useState<{
    Order: boolean
    SchedulePickup: boolean
    Cancelled: boolean
    Completed: boolean
    Appointment: boolean
    PendingConfirmation: boolean
  }>({
    Order: true,
    SchedulePickup: true,
    Cancelled: true,
    Completed: true,
    Appointment: true,
    PendingConfirmation: true
  })
  const handleOrderSelection = (_event: any, key: string, value: IWorkOrder) => {
    switch (key) {
      case WorkQueueMenuOption.EDIT_ORDER:
        if (value?.status === Status.Conditioned) {
          navigate(
            `/dms/order-stipulation/?vin=${value?.vin}&reference_id=${value?.reference_number}&customer_reference_id=${value?.customer_reference_id}`
          )
        } else if (value?.status == Status.Approved) {
          navigate(`/dms/order-approved/${value?.reference_number}`)
        } else if (value?.status == Status.Submitted) {
          navigate(`/dms/order-summary/${value?.reference_number}`)
        } else if (value?.status == Status.SchedulePickup) {
          navigate(
            `/dms/create-order-ui/${value?.vin}/${value?.customer_reference_id}/${value?.reference_number}`
          )
        } else {
          navigate(
            `/dms/desking?reference_id=${value?.reference_number}&customer_reference_id=${value?.customer_reference_id}`
          )
        }
        return
      case WorkQueueMenuOption.ORDER_SUMMARY:
        navigate(`/dms/order-summary/${value?.reference_number}`)
        return
      case WorkQueueMenuOption.CANCEL_ORDER:
        setConfirmationPopup({
          confirmationText: DialogMessages.confirmationText,
          confirmationTitle: DialogMessages.confirmationTitle,
          icon: <Icon name="ErrorTriangle" />,
          isButtonTypeDanger: true,
          optionaldata: true
        })
        setConfirmationPopupState(true)
        setActionData(value)
        setOrderStatus(Status.Cancelled)
        return
      case WorkQueueMenuOption.RESCHEDULE_ORDER:
        navigate(
          `/dms/create-order-ui/${value?.vin}/${value?.customer_reference_id}/${
            value?.reference_number
          }/${true}`
        )
        return
      case WorkQueueMenuOption.COMPLETE_ORDER:
        setConfirmationPopup({
          confirmationText: 'Once completed, you cannot undo this action.',
          confirmationTitle: 'Are you sure you want to complete order?',
          icon: <Icon name="CircleTickIcon" />,
          isButtonTypeDanger: false,
          optionaldata: false
        })
        setConfirmationPopupState(true)
        setActionData(value)
        setOrderStatus(Status.Completed)
        return
      default:
        return
    }
  }
  function onCancelConfirm(_action: any): void {
    const payload = {
      order_id: actionData?.reference_number,
      reqBody: {
        status: orderstatus,
        reason:
          orderstatus == Status.Cancelled && _action?.reason
            ? _action?.reason
            : 'Cancelled by Dealer',
        user_id: 0
      }
    }
    cancelOrder(payload, {
      onSuccess(_response: any) {
        if (actionData?.status == Status.Draft) {
          actions.setToast({
            toastMessage: 'Order Cancelled Sucessfully',
            toastState: true
          })
          queryClient.invalidateQueries(QueryKeys.GET_ALL_WORK_ORDERS)
        } else {
          const route =
            orderstatus == Status.Cancelled
              ? `/dms/order-cancelled/${actionData?.reference_number}`
              : `/dms/order-summary/${actionData?.reference_number}`
          navigate(route)
        }
        setActionData(undefined)
      },
      onError(error: any) {
        actions.setToast({
          toastMessage: error?.error?.response?.data?.detail?.message ?? 'Internal Server Error',
          toastState: true,
          variant: 'error'
        })
      }
    })
  }
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 4
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  }

  const [open, setOpen] = useState(false)
  const [searchfilterValue, setSearchFilterValue] = useState<{
    Order: string
    SchedulePickup: string
    Cancelled: string
    Completed: string
    Appointment: string
    PendingConfirmation: string
    Declined: string
    Withdrawn: string
  }>({
    Order: '',
    SchedulePickup: '',
    Cancelled: '',
    Completed: '',
    Appointment: '',
    PendingConfirmation: '',
    Declined: '',
    Withdrawn: ''
  })
  const [inputFilterValue, setInputFilterValue] = useState<{
    Order: string
    SchedulePickup: string
    Cancelled: string
    Completed: string
    Appointment: string
    PendingConfirmation: string
    Declined: string
    Withdrawn: string
  }>({
    Order: '',
    SchedulePickup: '',
    Cancelled: '',
    Completed: '',
    Appointment: '',
    PendingConfirmation: '',
    Declined: '',
    Withdrawn: ''
  })
  const [state, setState] = useState<WorkQueueState>()
  const [selectItems, setSelectItems] = useState<Array<any>>([])
  const onSeacrh = () => {
    let query = ''
    switch (state) {
      case WorkQueueState.isOrder:
        query = `page_number=${orderPageNumber}&page_size=${orderPageSize}`.concat(
          orderStatusFilter
        )
        inputFilterValue?.Order &&
          (query = query.concat(`&${searchfilterValue?.Order}=${inputFilterValue.Order}`))
        setOrderColumnFilters(query)
        setOpen(!open)
        return
      case WorkQueueState.isSchedulePickup:
        query = `page_number=${schedulePageNumber}&page_size=${schedulePageSize}&multiple_order_status=${Status.SchedulePickup}`
        inputFilterValue?.SchedulePickup &&
          (query = query.concat(
            `&${searchfilterValue?.SchedulePickup}=${inputFilterValue.SchedulePickup}`
          ))
        setScheduleColumnFilters(query)
        setOpen(!open)
        return
      case WorkQueueState.isComplete:
        query = `page_number=${completedPageNumber}&page_size=${completedPageSize}`.concat(
          completedStatusFilter
        )
        inputFilterValue?.Completed &&
          (query = query.concat(`&${searchfilterValue?.Completed}=${inputFilterValue.Completed}`))
        setCompletedColumnFilters(query)
        setOpen(!open)
        return
      case WorkQueueState.isCancel:
        query = `page_number=${cancelledPageNumber}&page_size=${cancelledPageSize}&multiple_order_status=${Status.Cancelled}&multiple_order_status=${Status.Declined}&multiple_order_status=${Status.Withdrawn}`
        inputFilterValue?.Cancelled &&
          (query = query.concat(
            `&${searchfilterValue?.Cancelled}=${inputFilterValue.Cancelled}&${searchfilterValue?.Declined}=${inputFilterValue.Declined}&${searchfilterValue?.Withdrawn}=${inputFilterValue.Withdrawn}`
          ))
        setCancelledColumnFilters(query)
        setOpen(!open)
        return
      case WorkQueueState.isAppointment:
        query = `page_number=${appointmentPageNumber}&page_size=${appointmentPageSize}&multiple_order_status=${Status.Appointment}`
        inputFilterValue?.Appointment &&
          (query = query.concat(
            `&${searchfilterValue?.Appointment}=${inputFilterValue.Appointment}`
          ))
        setAppointmentColumnFilters(query)
        setOpen(!open)
        return
      case WorkQueueState.isPendingConfirmation:
        query = `page_number=${pendingConfirmationPageNumber}&page_size=${pendingConfirmationPageSize}&multiple_order_status=${Status.PendingConfirmation}`
        inputFilterValue?.PendingConfirmation &&
          (query = query.concat(
            `&${searchfilterValue?.PendingConfirmation}=${inputFilterValue.PendingConfirmation}`
          ))
        setPendingConfirmationColumnFilters(query)
        setOpen(!open)
        return
    }
  }
  const handleChangeView = (state: WorkQueueState) => {
    switch (state) {
      case WorkQueueState.isOrder:
        setCardView({ ...cardView, Order: !cardView.Order })
        setOrderPageSize(!cardView.Order ? 10 : 5)
        setOrderPageNumber(0)
        setOrderColumnFilters(
          `page_number=${0}&page_size=${!cardView.Order ? 10 : 5}`.concat(orderStatusFilter)
        )
        return
      case WorkQueueState.isSchedulePickup:
        setCardView({
          ...cardView,
          SchedulePickup: !cardView.SchedulePickup
        })
        setSchedulePageSize(!cardView.SchedulePickup ? 10 : 5)
        setSchedulePageNumber(0)
        setScheduleColumnFilters(
          `page_number=${0}&page_size=${!cardView.SchedulePickup ? 10 : 5}&multiple_order_status=${
            Status.SchedulePickup
          }`
        )
        return
      case WorkQueueState.isCancel:
        setCardView({
          ...cardView,
          Cancelled: !cardView.Cancelled
        })
        setCancelledPageSize(!cardView.Cancelled ? 10 : 5)
        setCancelledPageNumber(0)
        setCancelledColumnFilters(
          `page_number=${0}&page_size=${!cardView.Cancelled ? 10 : 5}&multiple_order_status=${
            Status.Cancelled
          }&multiple_order_status=${Status.Withdrawn}&multiple_order_status=${Status.Declined}`
        )
        return
      case WorkQueueState.isComplete:
        setCardView({
          ...cardView,
          Completed: !cardView.Completed
        })
        setCompletedPageSize(!cardView.Completed ? 10 : 5)
        setCompletedPageNumber(0)
        setCompletedColumnFilters(
          `page_number=${0}&page_size=${!cardView.Completed ? 10 : 5}`.concat(completedStatusFilter)
        )
        return
      case WorkQueueState.isAppointment:
        setCardView({
          ...cardView,
          Appointment: !cardView.Appointment
        })
        setAppointmentPageSize(!cardView.Appointment ? 10 : 5)
        setAppointmentPageNumber(0)
        setAppointmentColumnFilters(
          `page_number=${0}&page_size=${!cardView.Appointment ? 10 : 5}&multiple_order_status=${
            Status.Appointment
          }`
        )
        return
      case WorkQueueState.isPendingConfirmation:
        setCardView({
          ...cardView,
          PendingConfirmation: !cardView.PendingConfirmation
        })
        setPendingConfirmationPageSize(!cardView.PendingConfirmation ? 10 : 5)
        setPendingConfirmationPageNumber(0)
        setPendingConfirmationColumnFilters(
          `page_number=${0}&page_size=${
            !cardView.PendingConfirmation ? 10 : 5
          }&multiple_order_status=${Status.PendingConfirmation}`
        )
        return
    }
  }
  const handleSearchClick = (state: WorkQueueState) => {
    switch (state) {
      case WorkQueueState.isOrder:
        setState(WorkQueueState.isOrder)
        setSelectItems([
          { text: 'Order ID', value: 'reference_number' },
          { text: 'Creation Date', value: 'created_at' },
          { text: 'Expiry Date', value: 'expiry_date' },
          { text: 'Status', value: 'order_status' },
          { text: 'Selling Price', value: 'selling_price' }
        ])
        setOpen(!open)
        return
      case WorkQueueState.isSchedulePickup:
        setState(WorkQueueState.isSchedulePickup)
        setSelectItems([
          { text: 'Asset Details', value: 'make_model_trim' },
          { text: 'Order ID', value: 'reference_number' },
          { text: 'VIN', value: 'vin' },
          { text: 'Status', value: 'order_status' },
          { text: 'Selling Price', value: 'selling_price' },
          { text: 'Customer Name', value: 'customer_name' }
        ])
        setOpen(!open)
        return
      case WorkQueueState.isCancel:
        setState(WorkQueueState.isCancel)
        setSelectItems([
          { text: 'Asset Details', value: 'make_model_trim' },
          { text: 'Order ID', value: 'reference_number' },
          { text: 'Selling Price', value: 'selling_price' },
          { text: 'Reason', value: 'reason' }
        ])
        setOpen(!open)
        return
      case WorkQueueState.isDeclined:
        setState(WorkQueueState.isDeclined)
        setSelectItems([
          { text: 'Asset Details', value: 'make_model_trim' },
          { text: 'Order ID', value: 'reference_number' },
          { text: 'Selling Price', value: 'selling_price' },
          { text: 'Reason', value: 'reason' }
        ])
        setOpen(!open)
        return
      case WorkQueueState.isWithdrawn:
        setState(WorkQueueState.isWithdrawn)
        setSelectItems([
          { text: 'Asset Details', value: 'make_model_trim' },
          { text: 'Order ID', value: 'reference_number' },
          { text: 'Selling Price', value: 'selling_price' },
          { text: 'Reason', value: 'reason' }
        ])
        setOpen(!open)
        return
      case WorkQueueState.isComplete:
        setState(WorkQueueState.isComplete)
        setSelectItems([
          { text: 'Asset Details', value: 'make_model_trim' },
          { text: 'Selling Price', value: 'selling_price' },
          { text: 'Order ID', value: 'reference_number' },
          { text: 'Reason', value: 'reason' }
        ])
        setOpen(!open)
        return
      case WorkQueueState.isAppointment:
        setState(WorkQueueState.isAppointment)
        setSelectItems([
          { text: 'Asset Details', value: 'make_model_trim' },
          { text: 'Selling Price', value: 'selling_price' },
          { text: 'Order ID', value: 'reference_number' },
          { text: 'VIN', value: 'vin' },
          { text: 'Customer Name', value: 'customer_name' },
          { text: 'Scheduled Date', value: 'appointment_date' }
        ])
        setOpen(!open)
        return
      case WorkQueueState.isPendingConfirmation:
        setState(WorkQueueState.isPendingConfirmation)
        setSelectItems([
          { text: 'Order ID', value: 'reference_number' },
          { text: 'Creation Date', value: 'created_at' },
          { text: 'Expiry Date', value: 'expiry_date' },
          { text: 'Status', value: 'order_status' },
          { text: 'Selling Price', value: 'selling_price' }
        ])
        setOpen(!open)
        return
      case WorkQueueState.isPreQualification:
        setState(WorkQueueState.isPreQualification)
        setSelectItems([
          { text: 'Ref. No.', value: 'reference_number' },
          { text: 'First/Last Name', value: 'name' },
          { text: 'Email Address', value: 'email_address' },
          { text: 'Result', value: 'result' },
          { text: 'Contact Number', value: 'contact_number' }
        ])
        setOpen(!open)
        return
    }
  }
  useEffect(() => {
    queryClient.invalidateQueries(QueryKeys.GET_ALL_COMPLETED_ORDERS)
    queryClient.invalidateQueries(QueryKeys.GET_ALL_CANCELLED_ORDERS)
    queryClient.invalidateQueries(QueryKeys.GET_ALL_WORK_ORDERS)
    queryClient.invalidateQueries(QueryKeys.GET_ALL_SCHEDULE_PICKUP_ORDERS)
    queryClient.invalidateQueries(QueryKeys.GET_ALL_APPOINTMENT_ORDERS)
    queryClient.invalidateQueries(QueryKeys.GET_ALL_PENDING_CONFIRMATION_ORDERS)
  }, [])
  useEffect(() => {
    const filteredCurrency = currencies?.filter(
      (currency: any) => currency.id === states?.lenderInfo?.default_currency
    )
    setDefaultCurrency(
      filteredCurrency && filteredCurrency.length > 0 ? filteredCurrency[0].symbol : ''
    )
  }, [currencies])
  function getSearchText() {
    switch (state) {
      case WorkQueueState.isOrder:
        return 'Search Orders by:'
      case WorkQueueState.isAppointment:
        return 'Search Appointment Orders by:'
      case WorkQueueState.isSchedulePickup:
        return 'Search Scheduling Orders by:'
      case WorkQueueState.isComplete:
        return 'Search Completed Orders by:'
      case WorkQueueState.isCancel:
        return 'Search Canceled orders by:'
      case WorkQueueState.isPendingConfirmation:
        return 'Search Pending Confirmation orders by:'
      case WorkQueueState.isPreQualification:
        return 'Search Pre-Calculations by:'
    }
  }
  const getOrderMenuOptions = (order: IWorkOrder) => {
    let menuOptions: Array<IMenuOptions> = []
    if (order?.request_type == 'Application') {
      menuOptions = orderMenuOptions.filter((x) => x.optionText !== WorkQueueMenuOption.EDIT_ORDER)
    } else {
      menuOptions = orderMenuOptions.filter(
        (x) =>
          x.optionText != WorkQueueMenuOption.ORDER_SUMMARY &&
          x.optionText !== WorkQueueMenuOption.EDIT_ORDER
      )
    }
    return menuOptions
  }
  const getFilterValue = (value: any) => {
    switch (state) {
      case WorkQueueState.isOrder:
        return value.Order
      case WorkQueueState.isSchedulePickup:
        return value.SchedulePickup
      case WorkQueueState.isComplete:
        return value.Completed
      case WorkQueueState.isCancel:
        return value.Cancelled
      case WorkQueueState.isAppointment:
        return value.Appointment
      case WorkQueueState.isPendingConfirmation:
        return value.PendingConfirmation
    }
  }
  const setFilterValue = (setFilter: any, filterValue: any, e: any) => {
    switch (state) {
      case WorkQueueState.isOrder:
        setFilter({ ...filterValue, Order: e })
        return
      case WorkQueueState.isPendingConfirmation:
        setFilter({ ...filterValue, PendingConfirmation: e })
        return
      case WorkQueueState.isSchedulePickup:
        setFilter({ ...filterValue, SchedulePickup: e })
        return
      case WorkQueueState.isComplete:
        setFilter({ ...filterValue, Completed: e })
        return
      case WorkQueueState.isCancel:
        setFilter({ ...filterValue, Cancelled: e })
        return
      case WorkQueueState.isAppointment:
        setFilter({ ...filterValue, Appointment: e })
        return
    }
  }
  return (
    <LayoutWithSideNav theme={theme}>
      <Box theme={theme} sx={{ pb: 5 }} width={'100%'} display={'inline-block'}>
        <WorkQueueHeader
          heading={'Orders'}
          total_results={orderData?.total_results ?? 0}
          header_text={'Below is the list of orders.'}
          state={WorkQueueState.isOrder}
          showCreateOrderButton={true}
          disableButton={disableButton}
          handleSearchClick={handleSearchClick}
          handleChangeView={handleChangeView}
          isCardView={cardView.Order}
          setPreQualificationResponse={setPreQualificationResponse}
        />
        {cardView.Order ? (
          <VehicleCardWrap theme={theme} className="vehicle-card-wrap">
            {orderData?.result && (
              <ReactCarousel
                responsive={responsive}
                arrows={true}
                containerClass="container-with-dots"
                draggable
                minimumTouchDrag={80}
                showDots={false}
                slidesToSlide={1}
                swipeable
              >
                {orderData?.result?.map((workOrder: IWorkOrder, index: number) => (
                  <VehicleOrderCard
                    data={workOrder}
                    key={index}
                    defaultCurrency={defaultCurrency}
                    menuOptions={getOrderMenuOptions(workOrder)}
                    handleOrderSelection={handleOrderSelection}
                    cardType={WorkQueueState.isOrder}
                  />
                ))}
              </ReactCarousel>
            )}
          </VehicleCardWrap>
        ) : (
          <OrderTable
            pageNumber={orderPageNumber}
            pageSize={orderPageSize}
            setPageNumber={setOrderPageNumber}
            setPageSize={setOrderPageSize}
            setColumnFilters={setOrderColumnFilters}
            data={orderData as IWorkOrderData}
            menuOptions={orderMenuOptions}
            handleOrderSelection={handleOrderSelection}
            statusFilter={orderStatusFilter}
          />
        )}
        <WorkQueueHeader
          heading={'Availability Check'}
          total_results={pendingConfirmationData?.total_results ?? 0}
          header_text={
            'Customers have submitted their orders. Finalise by confirming these orders and if the vehicles are available'
          }
          state={WorkQueueState.isPendingConfirmation}
          handleSearchClick={handleSearchClick}
          handleChangeView={handleChangeView}
          isCardView={cardView.PendingConfirmation}
        />
        {cardView.PendingConfirmation ? (
          <VehicleCardWrap theme={theme} className="vehicle-card-wrap">
            {pendingConfirmationData?.result && (
              <ReactCarousel
                responsive={responsive}
                arrows={true}
                containerClass="container-with-dots"
                draggable
                minimumTouchDrag={80}
                showDots={false}
                slidesToSlide={1}
                swipeable
              >
                {pendingConfirmationData?.result?.map((workOrder: IWorkOrder, index: number) => (
                  <VehicleOrderCard
                    data={workOrder}
                    key={index}
                    defaultCurrency={defaultCurrency}
                    handleOrderSelection={handleOrderSelection}
                    menuOptions={orderMenuOptions.filter(
                      (x) =>
                        x.optionText !== WorkQueueMenuOption.EDIT_ORDER &&
                        x.optionText !== WorkQueueMenuOption.ORDER_SUMMARY
                    )}
                    cardType={WorkQueueState.isPendingConfirmation}
                  />
                ))}
              </ReactCarousel>
            )}
          </VehicleCardWrap>
        ) : (
          <PendingConfirmationOrderTable
            pageNumber={pendingConfirmationPageNumber}
            pageSize={pendingConfirmationPageSize}
            setPageNumber={setPendingConfirmationPageNumber}
            setPageSize={setPendingConfirmationPageSize}
            setColumnFilters={setPendingConfirmationColumnFilters}
            menuOptions={orderMenuOptions}
            handleOrderSelection={handleOrderSelection}
            data={pendingConfirmationData as IWorkOrderData}
          />
        )}
        <WorkQueueHeader
          heading={'Schedule Pickup/Delivery'}
          total_results={schedulePickupData?.total_results ?? 0}
          header_text={
            'Customers have signed their contracts. Finalize by confirming these orders and setting your schedule.'
          }
          state={WorkQueueState.isSchedulePickup}
          handleSearchClick={handleSearchClick}
          handleChangeView={handleChangeView}
          isCardView={cardView.SchedulePickup}
        />
        {cardView.SchedulePickup ? (
          <VehicleCardWrap theme={theme} className="vehicle-card-wrap">
            {schedulePickupData?.result && (
              <ReactCarousel
                responsive={responsive}
                arrows={true}
                containerClass="container-with-dots"
                draggable
                minimumTouchDrag={80}
                showDots={false}
                slidesToSlide={1}
                swipeable
              >
                {schedulePickupData?.result?.map((workOrder: IWorkOrder, index: number) => (
                  <VehicleOrderCard
                    data={workOrder}
                    key={index}
                    defaultCurrency={defaultCurrency}
                    handleOrderSelection={handleOrderSelection}
                    menuOptions={orderMenuOptions.filter(
                      (x) =>
                        x.optionText !== WorkQueueMenuOption.EDIT_ORDER &&
                        x.optionText !== WorkQueueMenuOption.ORDER_SUMMARY
                    )}
                    cardType={WorkQueueState.isSchedulePickup}
                  />
                ))}
              </ReactCarousel>
            )}
          </VehicleCardWrap>
        ) : (
          <SchedulePickupTable
            pageNumber={schedulePageNumber}
            pageSize={schedulePageSize}
            setPageNumber={setSchedulePageNumber}
            setPageSize={setSchedulePageSize}
            setColumnFilters={setScheduleColumnFilters}
            menuOptions={orderMenuOptions.filter(
              (x) => x.optionText !== WorkQueueMenuOption.ORDER_SUMMARY
            )}
            handleOrderSelection={handleOrderSelection}
            data={schedulePickupData as IWorkOrderData}
          />
        )}
        <WorkQueueHeader
          heading={'Appointments'}
          total_results={appointmentOrderData?.total_results ?? 0}
          header_text={'Customers have scheduled their pickups/deliveries, please confirm.'}
          state={WorkQueueState.isAppointment}
          handleSearchClick={handleSearchClick}
          handleChangeView={handleChangeView}
          isCardView={cardView.Appointment}
        />
        {cardView.Appointment ? (
          <VehicleCardWrap theme={theme} className="vehicle-card-wrap">
            {appointmentOrderData?.result && (
              <ReactCarousel
                responsive={responsive}
                arrows={true}
                containerClass="container-with-dots"
                draggable
                minimumTouchDrag={80}
                showDots={false}
                slidesToSlide={1}
                swipeable
              >
                {appointmentOrderData?.result?.map((workOrder: IWorkOrder, index: number) => (
                  <VehicleOrderCard
                    data={workOrder}
                    defaultCurrency={defaultCurrency}
                    key={index}
                    menuOptions={appointmentMenuOptions.filter(
                      (x) => x.optionText !== WorkQueueMenuOption.COMPLETE_ORDER
                    )}
                    handleOrderSelection={handleOrderSelection}
                    cardType={WorkQueueState.isAppointment}
                  />
                ))}
              </ReactCarousel>
            )}
          </VehicleCardWrap>
        ) : (
          <AppointmentOrderTable
            pageNumber={appointmentPageNumber}
            pageSize={appointmentPageSize}
            setPageNumber={setAppointmentPageNumber}
            setPageSize={setAppointmentPageSize}
            setColumnFilters={setAppointmentColumnFilters}
            data={appointmentOrderData as IWorkOrderData}
            menuOptions={appointmentMenuOptions}
            handleOrderSelection={handleOrderSelection}
          />
        )}
        <WorkQueueHeader
          heading={'Completed'}
          total_results={completedOrderData?.total_results ?? 0}
          header_text={'Below is the list of completed orders.'}
          state={WorkQueueState.isComplete}
          handleSearchClick={handleSearchClick}
          handleChangeView={handleChangeView}
          isCardView={cardView.Completed}
        />
        {cardView.Completed ? (
          <VehicleCardWrap theme={theme} className="vehicle-card-wrap">
            {completedOrderData?.result && (
              <ReactCarousel
                responsive={responsive}
                arrows={true}
                containerClass="container-with-dots"
                draggable
                minimumTouchDrag={80}
                showDots={false}
                slidesToSlide={1}
                swipeable
              >
                {completedOrderData?.result?.map((workOrder: IWorkOrder, index: number) => (
                  <VehicleOrderCard
                    data={workOrder}
                    defaultCurrency={defaultCurrency}
                    key={index}
                    cardType={WorkQueueState.isComplete}
                  />
                ))}
              </ReactCarousel>
            )}
          </VehicleCardWrap>
        ) : (
          <CompletedOrderTable
            pageNumber={completedPageNumber}
            pageSize={completedPageSize}
            setPageNumber={setCompletedPageNumber}
            setPageSize={setCompletedPageSize}
            setColumnFilters={setCompletedColumnFilters}
            data={completedOrderData as IWorkOrderData}
            statusFilter={completedStatusFilter}
          />
        )}
        <WorkQueueHeader
          heading={'Cancelled / Declined / Withdrawn'}
          total_results={cancelledOrderData?.total_results ?? 0}
          header_text={'Below is the list of cancelled, declined and withdrawn orders.'}
          state={WorkQueueState.isCancel}
          handleSearchClick={handleSearchClick}
          handleChangeView={handleChangeView}
          isCardView={cardView.Cancelled}
        />
        {cardView.Cancelled ? (
          <VehicleCardWrap theme={theme} className="vehicle-card-wrap">
            {cancelledOrderData?.result && (
              <ReactCarousel
                responsive={responsive}
                arrows={true}
                containerClass="container-with-dots"
                draggable
                minimumTouchDrag={80}
                showDots={false}
                slidesToSlide={1}
                swipeable
              >
                {cancelledOrderData?.result?.map((workOrder: IWorkOrder, index: number) => (
                  <VehicleOrderCard
                    data={workOrder}
                    key={index}
                    defaultCurrency={defaultCurrency}
                    cardType={
                      workOrder?.status === Status.Cancelled
                        ? WorkQueueState.isCancel
                        : workOrder?.status === Status.Declined
                        ? WorkQueueState.isDeclined
                        : WorkQueueState.isWithdrawn
                    }
                  />
                ))}
              </ReactCarousel>
            )}
          </VehicleCardWrap>
        ) : (
          <CancelledOrderTable
            pageNumber={cancelledPageNumber}
            pageSize={cancelledPageSize}
            setPageNumber={setCancelledPageNumber}
            setPageSize={setCancelledPageSize}
            setColumnFilters={setCancelledColumnFilters}
            data={cancelledOrderData as IWorkOrderData}
          />
        )}
        <WorkQueueHeader
          heading={PRE_QUALIFICATION_CONSTANTS.tableHeader}
          total_results={0}
          header_text={PRE_QUALIFICATION_CONSTANTS.tableDescription}
          state={WorkQueueState.isPreQualification}
          handleSearchClick={() => {}}
          handleChangeView={() => {}}
          isCardView={false}
          hideActionArea={true}
        />
        <PreQualificationTable
          data={preQualifiedData}
          setPreQualificationResponse={setPreQualificationResponse}
        />
        {confirmationPopupState && (
          <ConfirmationDialog
            openPopUp={confirmationPopupState}
            onConfirm={onCancelConfirm}
            setPopUpState={setConfirmationPopupState}
            confirmationTitle={confirmationPopup.confirmationTitle}
            confirmationText={confirmationPopup.confirmationText}
            primaryBtnText={DialogMessages.cancelBtnText}
            icon={confirmationPopup.icon}
            isButtonTypeDanger={confirmationPopup.isButtonTypeDanger}
            onCancel={function (_action: any): void {
              setConfirmationPopupState(false)
            }}
            secondaryBtnText={'Cancel'}
            optionaldata={confirmationPopup.optionaldata}
            hideCancelButton={false}
          />
        )}
        <HeaderSearch theme={theme} className={open ? 'show-search' : 'hide-search'}>
          <Link className="overlay" onClick={() => setOpen(!open)} />
          <Box theme={theme} className="search-wrap">
            <Box theme={theme} className="search-area">
              <Box theme={theme} className="search-dropdown">
                <Typography
                  theme={theme}
                  variant="body2"
                  component="span"
                  children={getSearchText()}
                  className="main-title"
                />
                <Select
                  disablePortal
                  theme={theme}
                  items={selectItems}
                  value={getFilterValue(searchfilterValue)}
                  onChange={(e: any) => {
                    setFilterValue(setSearchFilterValue, searchfilterValue, e.target.value)
                  }}
                />
              </Box>
              <Input
                type="text"
                label="Enter your search here..."
                theme={theme}
                value={getFilterValue(inputFilterValue)}
                onChange={(e: any) => {
                  setFilterValue(setInputFilterValue, inputFilterValue, e)
                }}
              />
              <Button
                theme={theme}
                primary
                className="btn-search"
                iconText={<Icon name="SearchIcon" />}
                onClick={onSeacrh}
              />
            </Box>
          </Box>
        </HeaderSearch>

        <PreQualificationAlert
          values={preQualifiedResponse}
          onClose={() => setPreQualificationResponse(undefined)}
        />
      </Box>
    </LayoutWithSideNav>
  )
}

export default Workqueue
