import { FC, useEffect, useState } from 'react'
import { useTheme } from '@mui/material'
import { LayoutWithSideNav } from '@styles/layout'
import { ConfirmationDialog, PageHeader } from 'components'
import { Typography, Button, Grid, Box, Menu, Icon, Skeleton } from '@ntpkunity/controls'
import {
  AssetDetails,
  Contracts,
  CustomerDetails,
  InsuranceDetails,
  OrderDetails,
  PaymentBreakdown,
  PaymentDetailsNew,
  TradeInDetails
} from '@app/order-management/order-summary'
import {
  useAddContractDocument,
  useGetAllOrderDocumentPackagesByReferenceNumber,
  useGetOrderInformation,
  useUpdateInsuranceIndicator
} from '@apis/order-management.service'
import { useNavigate, useParams } from 'react-router-dom'
import { DialogMessages, Reasons, Status, InsuranceDocument } from '@helpers/enums'
import { useUpdateOrderStatus } from '@apis/dealer-workqueue.service'
import { useStoreContext } from '@store/storeContext'
import { APP_ROUTES } from '@helpers/links'
import { useSendCustomerOrderLink } from '@apis/customer-management.service'
import DisableLoader from '@public/assets/images/loader-disabled.gif'
import { SignerRole } from '@helpers/enums/contract-documents.enum'
import RebateDetails from '@app/order-management/order-summary/rebate-details'

const OrderApproved: FC = () => {
  let navigate = useNavigate()
  const theme = useTheme()
  const { states } = useStoreContext()
  const { mutate: getOrderInformation, data: orderDetail } = useGetOrderInformation()
  const { mutate: addContractDocument, isLoading: loadingAddContractDocument } =
    useAddContractDocument()
  const [optionObject, setOptionObject] = useState<any>(orderDetail)
  const { reference_id } = useParams()
  const [paymentIndicator, setPaymentIndicator] = useState<boolean>(false)
  const [_trigger, setTrigger] = useState<boolean>(true)
  const [confirmationPopupState, setConfirmationPopupState] = useState<boolean>(false)
  const { mutate: updateOrderStatus, isLoading: loadingUpdateOrderStatus } = useUpdateOrderStatus()
  const { mutate: sendCustomerOrderLink } = useSendCustomerOrderLink()
  const [contractDocument, setContractDocument] = useState<any>()
  const [generalDocument, setGeneralDocument] = useState<any>()
  const [creditDocument, setCreditDocument] = useState<any>()
  const [_, setApprovedDoc] = useState<boolean>(false)
  const [addInsurance, setAddInsurance] = useState<boolean>(false)
  const [_i, setAddInsuranceVerified] = useState<boolean>(false)
  const [lenderName, setLenderName] = useState<string>('')
  const [fetchDocumentPackages, setFetchDocumentPackages] = useState<boolean>(false)
  const [isContractingEnabled, setIsContractingEnabled] = useState<boolean>(true)
  const { mutate: getDocumentPackages, data: _documentPackage } =
    useGetAllOrderDocumentPackagesByReferenceNumber()
  const { mutate: updateInsuranceIndicator, isLoading: loadingUpdateInsuranceIndicator } = useUpdateInsuranceIndicator()
  const { actions } = useStoreContext()
  useEffect(() => {
    if (optionObject?.lender_id && states?.lendersData && states?.lendersData?.length > 0) {
      const selectedLender = states?.lendersData?.find(
        (item) => item.id === optionObject?.lender_id
      )
      if (selectedLender) {
        setLenderName(selectedLender?.name ?? '')
        setIsContractingEnabled(selectedLender?.contracting_type !== 'Not Required')
      }
    }
  }, [optionObject, states?.lendersData])
  useEffect(() => {
    if (reference_id || addInsurance) {
      getOrderInformation(
        { reference_number: reference_id },
        {
          onSuccess(response: any) {
            if (response?.Insurance_Inforation) {
              setAddInsurance(true)
            }
            if (response?.payment_recieved) {
              setPaymentIndicator(response?.payment_recieved)
            }
            if (response?.status === 'Approved') {
              setOptionObject(response)
              setAddInsuranceVerified(!!response?.Insurance_Inforation)
              getDocumentPackages(
                `reference_id=${response?.customer_info.reference_id}&external_order_id=${reference_id}&document_type=General&document_type=Contract&document_type=Credit`,
                {
                  onSuccess: (res: any) => {
                    if (res.length > 0) {
                      const contractDocuments: any = []
                      const generalDocuments: any = []
                      const creditDocuments: any = []
                      res?.forEach((entry: any) => {
                        if (entry.type === InsuranceDocument.Contract) {
                          contractDocuments?.push(entry)
                        } else if (entry.type === InsuranceDocument.General) {
                          generalDocuments?.push(entry)
                        } else if (entry.type === InsuranceDocument.Credit) {
                          creditDocuments?.push(entry)
                        }
                      })
                      setGeneralDocument(generalDocuments)
                      setContractDocument(contractDocuments)
                      setCreditDocument(creditDocuments)
                    }
                  },
                  onError: () => { }
                }
              )
            }
          }
        }
      )
    }
  }, [reference_id, addInsurance])

  useEffect(() => {
    if (optionObject?.status === 'Approved' && fetchDocumentPackages) {
      getDocumentPackages(
        `reference_id=${optionObject?.customer_info.reference_id}&external_order_id=${reference_id}&document_type=${InsuranceDocument.General}&document_type=${InsuranceDocument.Contract}&document_type=${InsuranceDocument.Credit}`,
        {
          onSuccess: (response: any) => {
            const contractDocuments: any = []
            const generalDocuments: any = []
            const creditDocuments: any = []
            response?.forEach((entry: any) => {
              if (entry.type === InsuranceDocument.Contract) {
                contractDocuments?.push(entry)
              } else if (entry.type === InsuranceDocument.General) {
                generalDocuments?.push(entry)
              } else if (entry.type === InsuranceDocument.Credit) {
                creditDocuments?.push(entry)
              }
            })
            setGeneralDocument(generalDocuments)
            setContractDocument(contractDocuments)
            setCreditDocument(creditDocuments)
            setApprovedDoc(false)
            setFetchDocumentPackages(false)
          },
          onError: () => { }
        }
      )
    }
  }, [optionObject, fetchDocumentPackages])

  const onSendOrderLinkToCustomer = () => {
    sendCustomerOrderLink({
      reference_number: orderDetail?.reference_number
    })
  }

  const onDeleteConfirm = (requestPayload: any) => {
    const data = {
      order_id: orderDetail?.reference_number,
      reqBody: {
        status: Status.Cancelled,
        user_id: 0,
        reason: requestPayload?.reason ? requestPayload?.reason : Reasons.CANCELED_BY_DEALER
      }
    }
    updateOrderStatus(data, {
      onSuccess() {
        actions.setToast({
          toastMessage: 'Deal Cancelled',
          toastState: true
        })
        navigate(APP_ROUTES?.WORKQUEUE)
      },
      onError(error: any) {
        actions.setToast({
          toastMessage: error.error.toString(),
          toastState: true,
          variant: 'error'
        })
      }
    })
  }

  const checkAllDealerAdminSignaturesSigned = addInsurance && (_documentPackage)?.every((doc: any) => {
    return doc?.documents.every((document: any) => {
      return document?.signatures?.every((signature: any) => {
        if (signature?.signer === SignerRole.DEALER_ADMIN) {
          return signature?.status === 'Signed'
        } else if (signature?.signer === SignerRole.APPLICANT) {
          return signature?.status === 'Signed'
        }
        return true
      })
    })
  })

  return (
    <>
      <LayoutWithSideNav theme={theme}>
        <Box theme={theme} sx={{ mb: 2.5 }}>
          <PageHeader
            className="main-page-header border-bottom"
            theme={theme}
            container
            item
            spacing={2}
          >
            <Grid theme={theme} container spacing={2}>
              <Grid theme={theme} item lg={7} md={6} sm={12} xs={12}>
                {!orderDetail ? (
                  <Skeleton theme={theme} variant="text" width={'50%'} height={'84px'} />
                ) : (
                  <>
                    <Typography theme={theme} variant="h2" component="h2">
                      {!orderDetail?.has_insurance ? 'Continue Deal' : `Sign & Pay`}
                    </Typography>
                    <Typography theme={theme} variant="body2" component="p">
                      {!orderDetail?.has_insurance ? 'Please sign generated documents and add insurance details to continue.' : `Please add/review the payment details and sign documents to continue.`}
                    </Typography>
                  </>
                )}
              </Grid>
              <Grid
                theme={theme}
                item
                lg={5}
                md={6}
                sm={12}
                xs={12}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'end',
                  gap: 2
                }}
              >
                                <Menu
                  theme={theme}
                  options={[
                    { optionText: 'Send Deal Link', optionValue: 'SendToCustomer' },
                    { optionText: 'Cancel Deal', optionValue: 'Cancel' },
                    { optionText: 'Resend Email Invite', disabled: true }
                  ]}
                  handleOptionClick={(_event, _key, value) => {
                    switch (value) {
                      case 'Cancel':
                        setConfirmationPopupState(true)
                        break
                      case 'SendToCustomer':
                        onSendOrderLinkToCustomer()
                        break
                    }
                  }}
                  render={(cb: any) => (
                    <Button
                      theme={theme}
                      secondary
                      iconText={<Icon name="MoreIcon" />}
                      onClick={cb}
                    />
                  )}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                  }}
                />
                {isContractingEnabled ? (
                  <Button
                    theme={theme}
                    secondary
                    id="continueDeal"
                    text={'Continue Deal'}
                    onClick={() => {
                      if (orderDetail?.has_insurance) {
                        const data = {
                          order_id: orderDetail?.reference_number,
                          reqBody: {
                            status: Status.SchedulePickup,
                            user_id: 0
                          }
                        }
                        updateOrderStatus(data, {
                          onSuccess() {
                            actions.setToast({
                              toastMessage: 'Deal moved to Awaiting Scheduling queue',
                              toastState: true
                            })
                            navigate(
                              `/lane/create-deal-ui/${optionObject?.Vehicle_details?.vin}/${optionObject?.customer_info?.reference_id}/${optionObject?.reference_number}`
                            )
                          }
                        })
                      } else {
                        updateInsuranceIndicator({
                          insurance_indentation: true,
                          reference_number: orderDetail?.reference_number
                        }, {
                          onSuccess() {
                            addContractDocument(
                              {
                                reference_number: reference_id,
                                document_event: 'Insurance Received'
                              },
                              {
                                onSuccess: () => {
                                  getDocumentPackages(
                                    `reference_id=${optionObject?.customer_info.reference_id}&external_order_id=${reference_id}&document_type=${InsuranceDocument.General}&document_type=${InsuranceDocument.Contract}&document_type=${InsuranceDocument.Credit}`,
                                    {
                                      onSuccess: (res: any) => {
                                        if (res.length > 0) {
                                          const contractDocuments: any = []
                                          const generalDocuments: any = []
                                          const creditDocuments: any = []
                                          res?.forEach((entry: any) => {
                                            if (entry.type === InsuranceDocument.Contract) {
                                              contractDocuments?.push(entry)
                                            } else if (entry.type === InsuranceDocument.General) {
                                              generalDocuments?.push(entry)
                                            } else if (entry.type === InsuranceDocument.Credit) {
                                              creditDocuments?.push(entry)
                                            }
                                          })
                                          setGeneralDocument(generalDocuments)
                                          setContractDocument(contractDocuments)
                                          setCreditDocument(creditDocuments)
                                        }
                                      },
                                      onError: () => { }
                                    }
                                  )
                                  getOrderInformation(
                                    { reference_number: reference_id },
                                    {
                                      onSuccess(_response: any) {
                                        setOptionObject(orderDetail)
                                        setTrigger(false)
                                      }
                                    }
                                  )
                                }
                              }
                            )
                          }
                        })
                      }
                    }}
                    disabled={!orderDetail || (orderDetail?.has_insurance ? (!paymentIndicator || !checkAllDealerAdminSignaturesSigned) : !checkAllDealerAdminSignaturesSigned) || loadingUpdateInsuranceIndicator || loadingUpdateOrderStatus || loadingAddContractDocument}
                    startIcon={(loadingUpdateInsuranceIndicator || loadingUpdateOrderStatus || loadingAddContractDocument) && (<img src={DisableLoader} alt="Loader" />)}
                  />
                ) : null}
              </Grid>
            </Grid>
          </PageHeader>
        </Box>
        <AssetDetails
          order_info={optionObject}
          setOptionObject={setOptionObject}
          lenderName={lenderName}
        />
        <Grid theme={theme} className="container" container spacing={2}>
          <Grid theme={theme} item xl={8} lg={8} md={8} sm={12} xs={12}>
            <InsuranceDetails
              insurance={optionObject?.Insurance_Inforation}
              referenceId={orderDetail?.customer_info?.reference_id}
              setAddInsurance={setAddInsurance}
              setAddInsuranceVerified={setAddInsuranceVerified}
              hideMenuOption={orderDetail?.has_insurance}
            />
            {
              (generalDocument?.length > 0 || contractDocument?.length > 0 || creditDocument?.length > 0) ? (
                <Contracts
                  orderStatus={orderDetail?.status}
                  dealerId={orderDetail?.dealer_id}
                  referenceNumber={orderDetail?.reference_number}
                  referenceId={optionObject?.customer_info?.reference_id}
                  generalDocument={generalDocument}
                  contractDocument={contractDocument}
                  creditDocument={creditDocument}
                  setApprovedDoc={setApprovedDoc}
                  orderObject={orderDetail}
                  setFetchDocumentPackages={setFetchDocumentPackages}
                />
              ) : (
                ''
              )}
            {orderDetail?.has_insurance ? (
              <PaymentDetailsNew
                order_detail={optionObject}
                setPaymentIndicator={setPaymentIndicator}
                viewMode={false}
              />
            ) : null}
            <CustomerDetails
              customer_info={optionObject?.customer_info}
              coapplicant_info={optionObject?.coapplicant_info}
            />
            <OrderDetails order_detail={optionObject} lenderName={lenderName} />
            {orderDetail?.order_rebates?.length > 0 && (
              <RebateDetails rebates={orderDetail?.order_rebates} order_id={orderDetail?.id} />
            )}
            {optionObject?.order_tradein ? (
              <TradeInDetails orderDetail={optionObject} setOrderObject={setOptionObject} />
            ) : (
              ''
            )}
          </Grid>
          <PaymentBreakdown order_info={optionObject} />
        </Grid>
      </LayoutWithSideNav>
      {confirmationPopupState && (
        <ConfirmationDialog
          openPopUp={confirmationPopupState}
          onConfirm={onDeleteConfirm}
          setPopUpState={setConfirmationPopupState}
          confirmationTitle={DialogMessages.confirmationTitle}
          confirmationText={DialogMessages.confirmationText}
          primaryBtnText={DialogMessages.cancelBtnText}
          isButtonTypeDanger
          icon={<Icon className="alert-icon" name="ErrorTriangle" />}
          onCancel={function (_action: any): void {
            setConfirmationPopupState(false)
          }}
          optionaldata={true}
          hideCancelButton={false}
        />
      )}
    </>
  )
}

export default OrderApproved
