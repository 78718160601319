// @ts-nocheck
import { FC, useEffect, useState } from 'react'
import { useTheme } from '@mui/material'
import {
  Grid,
  Input,
  Box,
  Button,
  Icon,
  Dialog,
  CircleLoader,
  DatePicker
} from '@ntpkunity/controls'
import { TableList } from '@components'
import {
  DeskingActionTypes,
  FinanceTypes,
  useDeskingContext,
  VehiclesForComparisonTypes
} from './desking-context-provider'
import {
  AvailableFinanceTypes,
  FeeHandlingType,
  InstallationMode,
  AddressType
} from '@helpers/enums'
import { calculateTotalOfPayments, getDealType, roundToTwoDecimal } from './utils'
import { useStoreContext } from '@store/storeContext'
import { useDebouncedState } from '@apis/dealer-configurations.service'
import { StipCodes } from '@helpers/enums/stipulation-type.enum'
import { useEditPermissionHook } from '@hooks/useEditPermissionHook'
import { useGetTaxRates } from '@apis/integrations.service'
import { ITaxRate } from 'controls/multi-lender-dialog'
import { Label } from './finance-lease-calculator'
import { Controller, useForm } from 'react-hook-form'
import {
  feeSumCalculator,
  fniTotalCalculator,
  mspTotalCalculator,
  optionPriceCalculator,
  tradeInAmountCalculator
} from "@helpers/caculationHelper"

export const UpdateCalculationData: FC<{
  property: string
  value: string | number | Date
  calculationData: any
  disable?: boolean
}> = ({ property, value, calculationData, disable = false }) => {
  const theme = useTheme()

  const {
    states: {
      dealDesk: { defaultCurrency }
    }
  } = useStoreContext()
  const { state: deskingState, dispatch } = useDeskingContext()
  const [debouncedState, setState, state] = useDebouncedState(value)
  const finance_type = deskingState?.selection?.finance_type
  const isTerms: boolean = deskingState.stipulationData?.some(
    (item: IStipulationResponse) => item?.stipulation_code === StipCodes.TERMS
  )

  useEffect(() => {
    if (debouncedState !== null && value !== debouncedState) {
      let updatedValue = debouncedState
      let updatedCalculationData = { ...calculationData }

      switch (property) {
        case 'security_deposit':
          updatedCalculationData = {
            ...calculationData,
            [property]: updatedValue
          }
          break
        case 'contract_start_date':
          updatedCalculationData = {
            ...calculationData,
            [property]: new Date(debouncedState).toISOString()
          }
          break
        case 'daysToFirstPayment':
          updatedCalculationData = {
            ...calculationData,
            [property]: Number(debouncedState) >= 1 ? Number(debouncedState) : 30
          }
          break
        case 'tax_on_selling_price':
          updatedValue = Number(debouncedState) > 0 ? Number(debouncedState) : undefined
          updatedCalculationData = {
            ...calculationData,
            [property]: updatedValue,
            tax_amount: updatedValue,
            tax_on_selling_price: updatedValue,
            tspk: updatedValue
          }
          break
      }

      dispatch({
        type: DeskingActionTypes.UPDATE_CALCULATION_DATA,
        payload: {
          property,
          valueUCD: updatedCalculationData
        }
      })
    }
  }, [debouncedState, dispatch, calculationData, property, value, finance_type])

  const isInputDisabled = () => {
    if (disable) return true
    if (property === 'security_deposit') {
      return Boolean(deskingState?.submission_tier) || isTerms
    }
    return (
      finance_type === AvailableFinanceTypes.LEASE ||
      isTerms ||
      Boolean(deskingState?.submission_tier)
    )
  }
  if (property === 'contract_start_date') {
    return (
      <DatePicker
        disablePast
        disablePortal={false}
        theme={theme}
        value={state ?? new Date().toISOString()}
        onChange={(e) => {
          if (e instanceof Date) {
            if (e.getFullYear() === 1970 && e.getMonth() === 0 && e.getDate() === 1) {
              setState(null)
            } else {
              setState(e.toISOString())
            }
          }
        }}
        inputReadOnly
        inputFormat="DD/MM/YYYY"
      />
    )
  }

  if (property === 'daysToFirstPayment') {
    return (
      <Input
        theme={theme}
        type="number"
        min={1}
        value={state}
        onChange={(e) => {
          const newValue = Number(e)
          setState(newValue >= 1 ? newValue : 30)
        }}
      />
    )
  }

  return (
    <Input
      theme={theme}
      type="text"
      fullWidth
      startAdornment={defaultCurrency?.symbol}
      value={state}
      masking
      maskNumeric
      maskDecimalScale={2}
      onChange={(e) => setState(Number(e))}
      disabled={isInputDisabled()}
    />
  )
}

const PaymentDetails: FC = () => {
  const theme = useTheme()
  const { states } = useStoreContext()
  const { state, formatSymbol } = useDeskingContext()
  const { mutate: getTaxRates, data: taxRates, isLoading: getTaxLoading } = useGetTaxRates()
  const [openDialog, setOpenDialog] = useState(false)
  const [daysToFirstPayment, setDaysToFirstPayment] = useState(30)

  const canUserEdit = useEditPermissionHook()
  const {
    control,
    handleSubmit,
    getValues,
    trigger,
    setValue,
    watch,
    register,
    formState: { errors, isDirty }
  } = useForm<any>({
    mode: 'all'
  })
  const isVehicleSelectedAndOrderSaved = state.isVehicleSelectedAndOrderSaved
  const vin = isVehicleSelectedAndOrderSaved ? state.preview.vin : state.selection.vin

  const finance_type = isVehicleSelectedAndOrderSaved
    ? state.preview.finance_type
    : state.selection.finance_type ?? getDealType(state, vin)

  useEffect(() => {
    if (finance_type === FinanceTypes.LEASE) {
      setDaysToFirstPayment(0)
    }
  }, [finance_type])

  const isVehicleSelected = Object.keys(state.selection ?? {}).length > 0
  const vehicleData = state.vehiclesData.get(vin)
  const fees = (vehicleData?.fees ?? {})?.[finance_type] ?? []

  const program = (vehicleData?.program ?? {})?.[finance_type]?.find(
    (p) =>
      p?.contract_term === state?.selection?.quoteParams?.contract_term &&
      (finance_type === FinanceTypes.FINANCE ||
        p?.mileage === state?.selection?.quoteParams?.annual_usage) &&
      p.is_default
  )
  const fni = (vehicleData?.fni ?? {})?.[finance_type?.toLowerCase()] ?? []

  const calculationsData = isVehicleSelectedAndOrderSaved
    ? state.preview.calculationsBoxes
    : isVehicleSelected
      ? state?.selection?.vehicleForComparison === VehiclesForComparisonTypes.SINGLE
        ? state[state?.selection?.vehicleForComparison]?.[state?.selection?.finance_type]
          ?.calculationsBoxes[state.selection.row]?.[state.selection.col]
        : state[state?.selection?.vehicleForComparison]?.[state?.selection?.finance_type]?.[
          state?.selection?.col
        ]?.calculationsBoxes
      : {}

  const quoteParams = isVehicleSelectedAndOrderSaved
    ? state.preview.quote_params
    : isVehicleSelected
      ? state?.selection?.vehicleForComparison === VehiclesForComparisonTypes.SINGLE
        ? state[state?.selection?.vehicleForComparison]?.[state?.selection?.finance_type]
          ?.calculationsBoxes[state.selection?.row]?.[0]
        : state[state?.selection?.vehicleForComparison]?.[state?.selection?.finance_type]?.[
          (state.selection.col)
        ]?.quote_params
      : {}

  const optionsPrice = optionPriceCalculator(vehicleData?.dealer_options ?? [], InstallationMode.PRE_INSTALLED)

  const fniProductsPrice = fniTotalCalculator(fni ?? [])

  const mspProductsPrice = mspTotalCalculator(fni ?? [])

  const financedFeeSum = feeSumCalculator(fees, FeeHandlingType.FINANCE)

  const upfrontFeeSum = feeSumCalculator(fees, FeeHandlingType.UPFRONT)

  const tradeInAmount = tradeInAmountCalculator(state?.tradeIn)

  const leaseRebates = (vehicleData?.rebates ?? []).reduce(
    (sum: number, item: IRebate) => sum + Number(item.rebate_amount),
    0
  )

  const tradeInInEquity = tradeInAmount < 0 ? tradeInAmount : 0

  const financeAmount =
    (financedFeeSum ?? 0) +
    (fniProductsPrice ?? 0) -
    tradeInInEquity +
    (calculationsData?.tax_amount ?? 0) +
    Number(calculationsData?.selling_price ?? 0) +
    (optionsPrice ?? 0) +
    (mspProductsPrice ?? 0)

  const securityDeposit = state?.security_deposit ?? 0
  const paymentDays = state.payment_days ?? state.order?.payment_days ?? 30
  const getDaysToFirstPaymentValue = () => {
    return state?.payment_days ?? 30
  }
  const contractStartdate = state.contract_start_date
  const dueAtSigningTotal =
    (calculationsData?.down_payment ?? 0) +
    (finance_type === FinanceTypes.LEASE ? calculationsData?.monthly_payment ?? 0 : 0) +
    upfrontFeeSum +
    securityDeposit

  const totalOfPayments =
    Object.keys(program ?? {}).length > 0
      ? calculateTotalOfPayments(
        calculationsData?.monthly_payment ?? 0,
        quoteParams?.contract_term ?? 0,
        program,
        calculationsData?.selling_price ?? 0
      )
      : 0

  const buyRate = state.hasUnsavedOrderChanges
    ? state?.preview?.quote_params?.apr
    : isVehicleSelectedAndOrderSaved
      ? state.order.margin
      : state.selection?.quoteParams?.apr ?? program?.interest_chart?.[0]?.margin ?? 0

  const dealerMargin = isVehicleSelected
    ? (finance_type === FinanceTypes.FINANCE
      ? states?.dealDesk?.dealerPreferences?.default_margin_rate_finance
      : states?.dealDesk?.dealerPreferences?.default_margin_rate_lease) ?? 0
    : 0

  const {
    state: { taxes, isTaxUpdated, vehiclesVins },
    dispatch
  } = useDeskingContext()

  const [inputValues, setInputValues] = useState()

  const garagging_address = (state?.customer?.customer_addresses ?? []).find(
    (currentItem) =>
      currentItem?.address_type === AddressType.GARAGING || currentItem?.is_garaging === true
  )

  const handleClose = () => {
    setOpenDialog(false)
  }

  const handleInputChange = (key, value) => {
    setInputValues({
      ...inputValues,
      [finance_type]: {
        ...inputValues[finance_type],
        [key]: parseFloat(value)
      }
    })
  }

  const handleOpen = () => {
    const body = {
      address: `${garagging_address?.address_line_1}, ${garagging_address?.city}`,
      zip_code: Number(garagging_address?.zip_code)
    }
    const isEmptyTaxes = (taxes) =>
      Object.values(taxes).every((value) => Object.keys(value).length === 0)
    if (
      (JSON.stringify((inputValues || {})[finance_type]) !==
        JSON.stringify((taxes || {})[finance_type]) &&
        !isVehicleSelectedAndOrderSaved) ||
      isEmptyTaxes(state?.taxes)
    ) {
      getTaxRates(body, {
        onSuccess(response: any) {
          const updatedTaxRates = {
            Finance: response?.state_fee_tax?.finance
          }
          setInputValues(updatedTaxRates)
          dispatch({
            type: DeskingActionTypes.SAVE_TAXES,
            payload: updatedTaxRates
          })
        }
      })
    } else if (!isTaxUpdated && !isVehicleSelectedAndOrderSaved) {
      getTaxRates(body, {
        onSuccess(response: any) {
          const updatedTaxRates = {
            Finance: response?.state_fee_tax?.finance
          }
          setInputValues(updatedTaxRates)
          dispatch({
            type: DeskingActionTypes.SAVE_TAXES,
            payload: updatedTaxRates
          })
        }
      })
    } else {
      setInputValues(taxes)
      dispatch({
        type: DeskingActionTypes.UPDATE_TAXES,
        payload: false
      })
    }
    setOpenDialog(true)
  }

  const handleContinue = () => {
    if (
      JSON.stringify((inputValues || {})[finance_type]) !==
      JSON.stringify(taxes || {})[finance_type]
    ) {
      if (!isTaxUpdated) {
        dispatch({
          type: DeskingActionTypes.UPDATE_TAXES,
          payload: true
        })
      }
    }
    if (inputValues) {
      dispatch({
        type: DeskingActionTypes.SAVE_TAXES,
        payload: inputValues
      })
    }

    setOpenDialog(false)
  }
  const addDays = (date, days) => {
    const result = new Date(date)
    result.setDate(result.getDate() + days)
    return result
  }

  function formatTaxLabel(label) {
    if (!label) return ''
    return label
      .split('_')
      .map((word) => word?.charAt(0).toUpperCase() + word?.slice(1))
      .join(' ')
  }
  return (
    <>
      <Grid theme={theme} container columnSpacing={3} rowSpacing={2}>
        <Grid theme={theme} item lg={6} md={6} sm={6} xs={12}>
          <TableList
            listTitle="FA/Gross Capitalized Cost"
            items={[
              {
                title: 'Selling Price',
                value: formatSymbol(calculationsData?.selling_price ?? 0)
              },
              {
                title: 'Add-Ons',
                value: formatSymbol(optionsPrice ?? 0)
              },
              {
                title: 'Maint. & Service Products',
                value: formatSymbol(mspProductsPrice ?? 0)
              },
              {
                title: 'F&I Products',
                value: formatSymbol(fniProductsPrice ?? 0)
              },
              {
                title: 'Fees',
                value: formatSymbol(financedFeeSum ?? 0)
              },
              {
                title: 'Trade-In Inequity',
                value: formatSymbol(tradeInInEquity)
              },
              {
                title: 'Taxes Capitalization',
                editable: true,
                value: (
                  <>
                    <UpdateCalculationData
                      key={`tax_on_selling_price-${calculationsData?.tax_amount ?? 0}`}
                      property="tax_on_selling_price"
                      value={calculationsData?.tax_amount ?? 0}
                      calculationData={calculationsData}
                      disable={!canUserEdit || calculationsData?.tax_amount == null}
                    />
                    {garagging_address &&
                      vehiclesVins.length > 0 &&
                      finance_type === FinanceTypes.FINANCE ? (
                      <Button
                        theme={theme}
                        defaultBtn
                        size="xs"
                        className="btn-dialog hide"
                        iconText={<Icon name="MoreIcon" />}
                        onClick={handleOpen}
                      />
                    ) : null}
                    <Dialog
                      variant={undefined}
                      size="xs"
                      title="Rates"
                      open={openDialog}
                      onCloseDialog={handleClose}
                      customFooter={
                        <>
                          <Button theme={theme} secondary text={'Cancel'} onClick={handleClose} />
                          <Button
                            theme={theme}
                            disabled={false}
                            type="submit"
                            primary
                            text="Continue"
                            onClick={handleContinue}
                          />
                        </>
                      }
                      theme={theme}
                      children={
                        <Box theme={theme} minHeight="100px">
                          {getTaxLoading ? (
                            <CircleLoader theme={theme} size="md" />
                          ) : (
                            inputValues &&
                            Object?.keys((inputValues || {})[finance_type])?.map((key, index) => (
                              <Input
                                key={index}
                                theme={theme}
                                fullWidth
                                maskNumeric
                                masking
                                type={'text'}
                                endAdornment="%"
                                label={formatTaxLabel(key)}
                                placeholder="Type here..."
                                value={inputValues[finance_type][key]?.toLocaleString(undefined, {
                                  maximumFractionDigits: 4,
                                  minimumFractionDigits: 4
                                })}
                                onChange={(e) => handleInputChange(key, e)}
                              />
                            ))
                          )}
                        </Box>
                      }
                    />
                  </>
                )
              },
              {
                title: 'Total',
                value: formatSymbol(calculationsData?.gross_capitalized_cost ?? 0),
                hasBoldText: true
              }
            ]}
          />
          <TableList
            listTitle="Capitalized Cost Reduction"
            items={[
              {
                title: 'Down Payment',
                value: formatSymbol(calculationsData?.down_payment ?? 0)
              },
              {
                title: 'Trade-In Equity',
                value: formatSymbol(tradeInAmount > 0 ? tradeInAmount : 0)
              },
              {
                title: 'Rebates & Incentives',
                value: formatSymbol(leaseRebates ?? 0)
              },
              {
                title: 'Total',
                value: formatSymbol(calculationsData?.capitalized_cost_reduction ?? 0),

                hasBoldText: true
              }
            ]}
          />
          <TableList
            listTitle="NFA/Adjusted Capitalized Cost"
            items={[
              {
                title: 'Balance',
                value: formatSymbol(calculationsData?.adjusted_capitalized_cost ?? 0),
                hasBoldText: true
              }
            ]}
          />
        </Grid>
        <Grid theme={theme} item lg={6} md={6} sm={6} xs={12}>
          <TableList
            listTitle="Due at Signing"
            items={[
              {
                title: 'Down Payment',
                value: formatSymbol(calculationsData?.down_payment ?? 0)
              },
              ...(finance_type === FinanceTypes.LEASE
                ? [
                  {
                    title: 'First Periodic Payment',
                    value: formatSymbol(calculationsData?.monthly_payment ?? 0)
                  }
                ]
                : []),
              {
                title: 'Upfront Fees',
                value: formatSymbol(upfrontFeeSum ?? 0)
              },
              ...(finance_type === FinanceTypes.LEASE
                ? [
                  {
                    title: 'Security Deposit',
                    editable: true,
                    value: (
                      <UpdateCalculationData
                        key={`security_deposit-${0}`}
                        property="security_deposit"
                        value={state?.security_deposit ?? 0}
                        calculationData={calculationsData}
                        disable={!canUserEdit}
                      />
                    )
                  }
                ]
                : []),
              {
                title: 'Total',
                value: formatSymbol(dueAtSigningTotal),
                hasBoldText: true
              }
            ]}
          />
          <TableList
            listTitle="Payment Details"
            items={[
              {
                title: 'Monthly Payment',
                value: formatSymbol(calculationsData?.monthly_payment ?? 0)
              },
              {
                title: 'Term',
                value: `${quoteParams?.contract_term ?? '0'}`
              },
              {
                title: 'Buy Rate',
                value: `${Number(buyRate ?? 0)?.toFixed(5)}%`
              },
              {
                title: 'Markup Rate',
                value: `${dealerMargin?.toFixed(5)}%`
              },
              {
                title: 'Sell Rate',
                value: `${(Number(buyRate ?? 0) + Number(dealerMargin))?.toFixed(5)}%`
              },
              {
                title: 'Contract Start Date',
                editable: true,
                value: (
                  <UpdateCalculationData
                    key={`contract_start_date-${contractStartdate ?? new Date().toISOString()
                      }`}
                    property="contract_start_date"
                    value={contractStartdate ?? new Date().toISOString()}
                    calculationData={calculationsData}
                    disable={!canUserEdit}
                  />
                )
              },
              ...(finance_type === FinanceTypes.FINANCE
                ? [
                  {
                    title: 'Days to 1st Payment',
                    editable: true,
                    value: (
                      <UpdateCalculationData
                        key={`daysToFirstPayment-${getDaysToFirstPaymentValue()}`}
                        property="daysToFirstPayment"
                        value={getDaysToFirstPaymentValue()}
                        calculationData={calculationsData}
                        disable={!canUserEdit}
                      />
                    )
                  }
                ]
                : []),
              {
                title: '1st Payment Date',
                value: (() => {
                  const startDate = new Date(contractStartdate)
                  const daysToAdd = finance_type === FinanceTypes.LEASE ? 0 : paymentDays ?? 30

                  const firstPaymentDate = addDays(startDate, daysToAdd)
                  return firstPaymentDate.toLocaleDateString('en-GB', {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric'
                  })
                })()
              },
              ...(finance_type === FinanceTypes.FINANCE
                ? [
                  {
                    title: 'Financed Charges',
                    value: formatSymbol(
                      (quoteParams?.contract_term === program?.contract_term)
                        ? (totalOfPayments ?? 0) - (calculationsData?.adjusted_capitalized_cost ?? 0) : 0
                    )
                  }
                ]
                : []),
              {
                title: 'Total of Payments',
                value: formatSymbol(totalOfPayments ?? 0),
                hasBoldText: true
              }
            ]}
          />
        </Grid>
      </Grid>
    </>
  )
}

export default PaymentDetails
