import { FC, useState, useEffect } from 'react'
import { useForm, Controller, SubmitHandler } from 'react-hook-form'
import { EMAIL_REGEX, PHONE_REGEX, DisclaimerNames } from '@helpers/constants'
import { useTheme, Link } from '@mui/material'
import { CustomTheme } from '@styles/customTheme'
import { Button, Box, Input, Typography, Dialog, Select } from '@ntpkunity/controls'
import { useDisclaimers } from '@hooks/useDisclaimer'
import { validation } from '@helpers/methods'
import { useStoreContext } from '../store/storeContext'
import { useCheckCustomerStatus } from '@apis/customer-management.service'
import { useUpdateCustomerContact } from '@apis/customer-detail.service'
import { useGetRating } from '@apis/configurations.service'
import DisableLoader from '@public/assets/images/loader-disabled.gif'
import { empty, mapPayload } from '@helpers/lead'
import { updateLead } from '@apis/lead-management'
import { LeadKey } from '@helpers/enums/lead'

interface IFormInputs {
  customer_addresses: any
  email: string
  zipCode: string
  preferred_contact: string
  consent: boolean
  mobile_number: string
  credit_score: string
}
const CustomerDetailsControl: FC<{
  defaultData?: any
  closedModelFunction?: any
  creditScore?: boolean
  checkCustomerStatusData: any
}> = ({ defaultData, closedModelFunction, checkCustomerStatusData, creditScore }) => {
  const { states } = useStoreContext()
  const theme = useTheme()
  const form: {
    handleSubmit: any
    control: any
    formState: any
    setError: any
    getValues: any
    setValue: any
    watch: any
    trigger: any
    clearErrors: any
  } = useForm<IFormInputs>({
    defaultValues: defaultData,
    mode: 'onBlur'
  })

  const { disclaimers } = useDisclaimers([
    DisclaimerNames?.TERMS_AND_CONDITIONS,
    DisclaimerNames?.CCPA,
    DisclaimerNames?.TCPA
  ])

  const { actions } = useStoreContext()

  const {
    mutate: customerStatus,
    data: customerStatusData,
    error: customerStatusError,
    isLoading: customerStatusIsLoading
  } = useCheckCustomerStatus()
  const { mutate: updateCustomerByReferenceId, isLoading: creatingUpdatingCustomer } =
    useUpdateCustomerContact()
  const { mutate: getCreditRating, data: _creditRatingData } = useGetRating()

  const [customerData, setCustomerData] = useState<any>(defaultData)
  const [termsConditionsModal, setTermsConditionsModal] = useState<any>(false)
  const [closeTcpaModal, setCloseTcpaModal] = useState<any>(false)
  const [closeCcpaDisclosureModal, setCloseCcpaDisclosureModal] = useState<any>(false)

  useEffect(() => {
    if (customerStatusError) {
      form.setValue('consent', false)
    }
    if (creditScore) {
      getCreditRating({ company_id: states?.dealerInfo?.company_id })
    }
  }, [customerStatusError, form.formState.errors?.email])

  const onSubmit: SubmitHandler<IFormInputs> = (data: IFormInputs) => {
    if (
      data?.preferred_contact != customerData?.preferred_contact ||
      data?.mobile_number != customerData?.mobile_number
    ) {
      updateCustomerByReferenceId(
        {
          customer_profile: {
            updated_by: customerData?.email,
            preferred_contact: data?.preferred_contact,
            mobile_number: data?.mobile_number
          },
          reference_id: customerData?.reference_id
        },
        {
          onSuccess: (_) => {
            checkCustomerStatusData({
              ...customerStatusData,
              ...data,
              credit_rating: data?.credit_score?.split(': ')?.[0]
            })
          },
          onError: (error: any) => {
            actions.setToast({
              toastMessage: error?.error?.response?.data?.detail?.toString(),
              toastState: true,
              variant: 'error'
            })
          }
        }
      )
    } else {
      checkCustomerStatusData({
        ...customerStatusData,
        ...data,
        credit_rating: data?.credit_score?.split(': ')?.[0]
      })
    }

    if (states?.dealerAndLenderInfo?.id && customerData?.reference_id) {
      empty()
      let lead = mapPayload(
        states?.dealerAndLenderInfo?.id,
        customerData?.reference_id,
        LeadKey.Customer_Detail,
        { email: customerData?.email }
      )
      updateLead(lead)
    }
  }

  if (termsConditionsModal) {
    return (
      <CustomTheme className="custom-theme" theme={theme}>
        <Dialog
          disablePortal
          theme={theme}
          title={'Terms and Conditions'}
          open
          onCloseDialog={() => {
            setTermsConditionsModal(false)
          }}
          noFooter
        >
          <form onSubmit={form.handleSubmit()} id="customerForm">
            <Box theme={theme} className="custom-p">
              <div
                dangerouslySetInnerHTML={{
                  __html: disclaimers?.TERMS_AND_CONDITIONS as string
                }}
              ></div>
            </Box>
          </form>
        </Dialog>
      </CustomTheme>
    )
  }
  if (closeCcpaDisclosureModal) {
    return (
      <CustomTheme className="custom-theme" theme={theme}>
        <Dialog
          disablePortal
          theme={theme}
          title={'CCPA Disclosure'}
          open
          onCloseDialog={() => {
            setCloseCcpaDisclosureModal(false)
          }}
          noFooter
        >
          <form onSubmit={form.handleSubmit()} id="customerForm">
            <Box theme={theme} className="custom-p">
              <div dangerouslySetInnerHTML={{ __html: disclaimers?.CCPA as string }}></div>
            </Box>
          </form>
        </Dialog>
      </CustomTheme>
    )
  }
  if (closeTcpaModal) {
    return (
      <CustomTheme className="custom-theme" theme={theme}>
        <Dialog
          disablePortal
          theme={theme}
          title={'TCPA Disclosure'}
          open
          onCloseDialog={() => {
            setCloseTcpaModal(false)
          }}
          noFooter
        >
          <form onSubmit={form.handleSubmit()} id="customerForm">
            <Box theme={theme} className="custom-p">
              <div dangerouslySetInnerHTML={{ __html: disclaimers?.TCPA as string }}></div>
            </Box>
          </form>
        </Dialog>
      </CustomTheme>
    )
  }
  const checkCustomerStatus = () => {
    form.trigger(['email'])
    setTimeout(() => {
      if (!form.formState.errors?.email) {
        customerStatus(
          { email: form.getValues('email') },
          {
            onSuccess(res: any) {
              setCustomerData(res)
              if (res && res?.consent) {
                form.setValue('consent', true)
                form.setValue('preferred_contact', res?.preferred_contact)
              }
              if (res && res?.preferred_contact != 'Email') {
                form.setValue('mobile_number', res?.mobile_number)
              } else {
                form.setValue('consent', false)
              }
              // navigate(`/dms/create-order/:vin/${res?.reference_id}`)
            },
            onError() {
              setCustomerData(null)
            }
          }
        )
      }
    })
  }
  const preferredContactMethodPhoneOrText = form.watch('preferred_contact') === 'Mobile Phone'

  return (
    <CustomTheme className="custom-theme" theme={theme}>
      <Dialog
        disablePortal
        theme={theme}
        title={'Enter Customer’s Details'}
        size="sm"
        open
        onCloseDialog={() => {
          closedModelFunction?.(false)
        }}
        customFooter={
          <>
            <Button
              theme={theme}
              secondary
              text={'Cancel'}
              onClick={() => {
                closedModelFunction?.(false)
              }}
            ></Button>
            <Button
              theme={theme}
              type="submit"
              primary
              disabled={customerStatusIsLoading || creatingUpdatingCustomer}
              text="Save Changes"
              form={'customerForm'}
              startIcon={creatingUpdatingCustomer && <img src={DisableLoader} alt="Loader" />}
            ></Button>
          </>
        }
      >
        <form onSubmit={form.handleSubmit(onSubmit)} id="customerForm">
          <Controller
            name="email"
            control={form.control}
            defaultValue={''}
            rules={validation('Email', true, true, EMAIL_REGEX, 'Valid Email is Required.')}
            render={({ field }) => (
              <Input
                theme={theme}
                testid="email"
                fullWidth
                label={'Email Address'}
                placeholder={'Please enter email'}
                type={'text'}
                {...field}
                onBlur={() => checkCustomerStatus()}
                error={form.formState.errors?.email?.message}
                helperText={customerStatusIsLoading && 'Waiting'}
              />
            )}
          />
          <Controller
            name="preferred_contact"
            rules={validation('Preferred Contact', true)}
            control={form.control}
            defaultValue={''}
            render={({ field }) => (
              <Select
                disablePortal={false}
                theme={theme}
                disabled={customerStatusIsLoading}
                label={'Preferred Communication'}
                items={[
                  { text: 'Mobile Phone', value: 'Mobile Phone' },
                  { text: 'Email', value: 'Email' }
                ]}
                {...field}
                selectError={form?.formState?.errors?.preferred_contact?.message}
              />
            )}
          />
          {preferredContactMethodPhoneOrText && (
            <Controller
              name="mobile_number"
              control={form.control}
              defaultValue={''}
              rules={validation('Phone', true, true, PHONE_REGEX, 'Valid Phone number is Required')}
              render={({ field }) => (
                <Box theme={theme} mb={3}>
                  <Input
                    theme={theme}
                    masking
                    maskNumeric
                    format="###-###-####"
                    fullWidth
                    label={'Mobile Phone Number'}
                    type={'text'}
                    {...field}
                    error={form.formState.errors?.mobile_number?.message}
                  />
                </Box>
              )}
            />
          )}
          {creditScore && (
            <Controller
              name="credit_score"
              rules={validation('Credit Score', true)}
              control={form.control}
              defaultValue={''}
              render={({ field }) => (
                <Select
                  disablePortal={false}
                  theme={theme}
                  disabled={customerStatusIsLoading}
                  label={'Credit Score'}
                  items={_creditRatingData?.map((item: any) => ({
                    text: `${item?.description}: ${item?.score_from} - ${item?.score_to}`,
                    value: `${item?.description}: ${item?.score_from} - ${item?.score_to}`
                  }))}
                  {...field}
                  selectError={form?.formState?.errors?.credit_score?.message}
                />
              )}
            />
          )}
          <Box theme={theme}>
            <Typography theme={theme} variant="caption" component="span" className="text-muted">
              {(customerData?.consent?.length >= 2 && form.watch('preferred_contact') == 'Email') ||
              (customerData?.consent?.length >= 3 &&
                ['Mobile Phone'].includes(form.watch('preferred_contact'))) ? (
                false
              ) : (
                <span>
                  By continuing, you agree to the
                  {
                    <Link
                      href="javascript:void(0)"
                      className="link hover-underline"
                      onClick={() => {
                        setTermsConditionsModal(true)
                      }}
                    >
                      {' '}
                      terms and conditions ,{' '}
                    </Link>
                  }
                  to the
                  {
                    <Link
                      href="javascript:void(0)"
                      className="link hover-underline"
                      onClick={() => {
                        setCloseCcpaDisclosureModal(true)
                      }}
                    >
                      {' '}
                      CCPA disclosure{' '}
                    </Link>
                  }
                  {preferredContactMethodPhoneOrText ? (
                    <>
                      , to the
                      {
                        <Link
                          href="javascript:void(0)"
                          className="link hover-underline"
                          onClick={() => {
                            setCloseTcpaModal(true)
                          }}
                        >
                          {' '}
                          TCPA disclosure{' '}
                        </Link>
                      }
                    </>
                  ) : null}
                  and to receive marketing material related to product(s).
                </span>
              )}
            </Typography>
          </Box>
        </form>
      </Dialog>
    </CustomTheme>
  )
}

export default CustomerDetailsControl
