export enum QueryKeys {
  GET_ALL_FINANCIAL_INSURANCE_PRODUCT = 'GET ALL FINANCIAL PRODUCT',
  GET_ALL_WORK_ORDERS = 'GET ALL WORK ORDERS',
  GET_ALL_ORDERS = 'GET ALL ORDERS',
  GET_ALL_SCHEDULE_PICKUP_ORDERS = 'GET ALL SCHEDULE PICKUP ORDERS',
  GET_ALL_PENDING_CONFIRMATION_ORDERS = 'GET ALL PENDING CONFIRMATION ORDERS',
  GET_ALL_CANCELLED_ORDERS = 'GET ALL CANCELLED ORDERS',
  GET_ALL_COMPLETED_ORDERS = 'GET ALL COMPLETED ORDERS',
  GET_ALL_APPOINTMENT_ORDERS = 'GET ALL APPOINTMENT ORDERS',
  GET_ALL_PREFIX = 'GET_ALL_PREFIX',
  GET_ALL_COUTRIES = 'GET_ALL_COUTRIES',
  GET_ALL_STATES = 'GET_ALL_STATES',
  GET_CUSTOMER = 'GET_CUSTOMER',
  GET_ALL_CURRENCIES = 'GET_ALL_CURRENCIES',
  GET_CUSTOMER_CONTACT_DETAILS = 'GET_CUSTOMER_CONTACT_DETAILS',
  GET_CUSTOMER_EMPLOYMENT_DETAILS = 'GET_CUSTOMER_EMPLOYMENT_DETAILS',
  GET_PERSONAL_FINANCE = 'GET_PERSONAL_FINANCE_DETAILS',
  GET_LICENSE_DETAILS = 'GET_LICENSE_DETAILS',
  GET_ALL_ACTIVE_ASSET_USAGE = 'GET_ALL_ACTIVE_ASSET_USAGE',
  GET_LENDER_PROFILE = 'GET LENDER PROFILE',
  GET_ALL_LENDER = 'GET ALL LENDER',
  GET_THEME_BUILDER = 'GET THEME BUILDER',
  GET_ALL_OPTION_CATEGORIES = 'GET_ALL_OPTION_CATEGORIES',
  GET_ALL_ASSET_CONDITION = 'GET_ALL_ASSET_CONDITION',
  GET_ADD_ON = 'GET_ADD_ON',
  GET_PRE_QUALIFIED_RESPONSES = 'GET_PRE_QUALIFIED_RESPONSES',
  GET_LENDER_BY_ID = 'GET_LENDER_BY_ID',
  GET_ALL_LENDERS = 'GET_ALL_LENDERS',
  GET_ALL_ADMIN_WORK_ORDERS = 'GET_ALL_ADMIN_WORK_ORDERS',
  EP_GET_DISCLAIMERS_BY_NAME = 'EP_GET_DISCLAIMERS_BY_NAME',
  DISCLAIMER_AGREEMENT = 'DISCLAIMER_AGREEMENT',
  GET_ALL_INDIVIDUAL_AGREEMENT = 'INDIVIDUAL_AGREEMENT',
  GET_ALL_ORDER_DOCUMENTS = 'GET_ALL_ORDER_DOCUMENTS',
  GET_INDIVIDUALIZED_AGREEMENT = 'GET_INDIVIDUALIZED_AGREEMENT',
  GET_CUSTOMER_ORDER_SUBMISSIONS = 'GET_CUSTOMER_ORDER_SUBMISSIONS',
  GET_ALL_ADMIN_CANCELLED_ORDERS = 'GET_ALL_ADMIN_CANCELLED_ORDERS',
  GET_ALL_ADMIN_SCHEDULE_PICKUP_ORDERS = 'GET_ALL_ADMIN_SCHEDULE_PICKUP_ORDERS',
  GET_ALL_ADMIN_PENDING_CONFIRMATION_ORDERS = 'GET_ALL_ADMIN_PENDING_CONFIRMATION_ORDERS',
  GET_ALL_ADMIN_COMPLETED_ORDERS = 'GET_ALL_ADMIN_COMPLETED_ORDERS',
  GET_ALL_ADMIN_APPOINTMENT_ORDERS = 'GET_ALL_ADMIN_APPOINTMENT_ORDERS',
  GET_INVENTORY_BY_FILTERS = 'GET_INVENTORY_BY_FILTERS',
  GET_REFERENCE_DATA_INVENTORY = 'GET_REFERENCE_DATA_INVENTORY',
  GET_INVENTORY_MODELS = 'GET_INVENTORY_MODELS',
  GET_INVENTORY_TRIMS = 'GET_INVENTORY_TRIMS',
  GET_INSURANCE_COMPANIES_BY_ID = 'GET_INSURANCE_COMPANIES_BY_ID',
  GET_INTEGRATIONS_BY_COMPANY_ID = 'GET_INTEGRATIONS_BY_COMPANY_ID',
  GET_DEALER_PERMISSION = 'GET_DEALER_PERMISSION',
  GET_FINANCIAL_INSURANCE_PRODUCTS = 'GET_FINANCIAL_INSURANCE_PRODUCTS',
  CHECK_FINANCIAL_INSURANCE_BY_DEALER_CODE = 'CHECK_FINANCIAL_INSURANCE_BY_DEALER_CODE',
  GET_PROVIDERS_BY_INTEGRATION_TYPE = 'GET_PROVIDERS_BY_INTEGRATION_TYPE',
  GET_FINANCIAL_INSURANCE_PRODUCT_BUNDLES = 'GET_FINANCIAL_INSURANCE_PRODUCT_BUNDLES',
  EP_GET_ALL_FINANCIAL_INSURANCE_PRODUCTS = 'GET_ALL_FINANCIAL_INSURANCE_PRODUCTS',
  EP_GET_REBATES = 'GET_ALL_REBATES',
  GET_CUSTOMER_DISCLOSURE = 'GET_CUSTOMER_DISCLOSURE',
  GET_PROGRAMS_BY_FINANCE = 'GET_PROGRAMS_BY_FINANCE',
  GET_INTEGRATION_BY_TYPE = 'GET_INTEGRATION_BY_TYPE'
}
