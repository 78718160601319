import { FC, useEffect, useMemo, useState } from 'react'
import { useTheme } from '@mui/material'
import { Button, Grid, Input, Radio, Select, Typography, MultiSelect, Icon } from '@ntpkunity/controls'
import { ConfirmationDialog, RadioGroupWrap } from '@components'
import { useStoreContext } from '@store/storeContext'
import {
  PROGRAM_OPTIONS,
  CONFIRM_OPTIONS,
  TRANSMISSION_TYPE,
  VEHICLE_PRIOR_USE,
  EXEMPTION_LEVELS,
  STATES_FOR_MARRIED_APPLICANT,
  STATES_FOR_NEGOTIATED_DEAL,
  STATES_FOR_TAX_CREDIT_AMOUNT,
  STATES_FOR_ODOMETER,
  STATES_FOR_RIGHT_TO_CANCEL,
  NEGOTIATED_LANGUAGES
} from '@helpers/constants'
import { Controller, useForm } from 'react-hook-form'
import { findCustomerAddress, validateObject, validation } from '@helpers/methods'
import { useGetDisclaimersDataByName } from '@apis/dealer-configurations.service'
import { IAddress } from '@models'
import { getDealerState, isDealerInUs, isNotNullOrUndefined } from '@app/desking/utils'
import { yupResolver } from '@hookform/resolvers/yup'
import { createDisclosureValidatorsSchema } from '@helpers/validators/disclosureYupValidator'
import AddressDetailsComponent from './address-detail'
import { useDeskingContext } from '@app/desking/desking-context-provider'
import { AddressType, DialogMessages } from '@helpers/enums'
import { FinanceType } from '@helpers/enums/finance-type.enum'
import { ExemptionLevel, MaritalStatus } from '@helpers/enums/customer.enum'
import { useSaveCustomerDisclosure } from '@apis/customer-detail.service'
import { usePreventNavigation } from '@hooks/usePreventNavigation'
import { useNavigate } from 'react-router-dom'

const engine_cylinder = Array.from({ length: 18 }, (_, i) => ({
  text: (i + 3).toString(),
  value: i + 3
}))

interface IFormInputs {
  customer?: {
    marital_status?: string | null
  }
  spouse?: {
    first_name?: string | null
    last_name?: string | null
    address?: IAddress | null
  }
  is_spouse_as_coapplicant?: boolean | null
  is_negotiated_with_applicant?: boolean | null
  is_negotiated_with_coapplicant?: boolean | null
  tax_credit_amount?: number | null
  right_to_cancel?: number | null
  purchase_option_fee?: number | null
  tax_exemption?: boolean | null
  exemption_level?: string | null
  applicant_negotiated_languages?: string[] | null
  coapplicant_negotiated_languages?: string[] | null
  order_asset?: {
    type?: string | null
    engine_cylinder?: number | null
    program_options?: string[] | null
    transmission_type?: string | null
    vehicle_uses?: string | null
    mileage?: number | null
    odometer_disclaimer?: string | null
  }
  reference_number: string
  customer_id: number
}

interface PermisionsField {
  isMandatory: false
  isShow: false
}

interface PermisionsFieldForm {
  assetCondition: PermisionsField
  programOption: PermisionsField
  isPrimaryApplicantMarried: PermisionsField
  isSpouseListedCoapplicant: PermisionsField
  isNegotiatedPrimaryApplicant: PermisionsField
  taxCreditAmount: PermisionsField
  transmissionType: PermisionsField
  engineCylinders: PermisionsField
  vehiclePriorUse: PermisionsField
  currentMileage: PermisionsField
  odometerQuestion: PermisionsField
  rightToCancel: PermisionsField
  purchaseOptionFee: PermisionsField
  taxExemption: PermisionsField
  addressLocationType: PermisionsField
}

export const Disclosures: FC<{
  setIsDisclosure: any
  setIsDisclosureErrors: any
  disclosurePermissions: PermisionsFieldForm
  disclosureData: any
  pendingRoute: any,
  setPendingRoute: any
  setRoute: any
}> = ({ setIsDisclosureErrors, setIsDisclosure, disclosurePermissions, disclosureData, pendingRoute, setPendingRoute, setRoute }) => {
  const theme = useTheme()
  const { states, actions } = useStoreContext()
  const navigate = useNavigate()
  const { state } = useDeskingContext()
  const { mutate: saveCustomerDiscloure } = useSaveCustomerDisclosure()
  const { data: disclaimers } = useGetDisclaimersDataByName('odometer')
  const [isMarried, setIsMarried] = useState(true)
  const [isSpouseCoapplicant, setIsSpouseCoapplicant] = useState(true)
  const [isNegotiatedAapplicant, setIsNegotiatedAapplicant] = useState(false)
  const [isNegotiatedCoapplicant, setIsNegotiatedCoapplicant] = useState(false)
  const [isShowExemptionLevel, setIsShowExemptionLevel] = useState(false)
  const [_isAddressDirty, setIsAddressDirty] = useState(false)
  const dealType = state?.order?.finance_type?.toLowerCase()
  const isUsAddress = isDealerInUs(states)
  const dealerState = getDealerState(states)
  const vehicle_condition = states?.dealDesk?.dealerProfile?.vehicle_condition?.map((d: string) => {
    return { text: d, value: d }
  })
  const conApplicant = state?.order?.coapplicant_info
  const isCoapplicant = validateObject(conApplicant)
  const applicantMailingAddress = findCustomerAddress(
    state?.order?.customer_info?.customer_addresses || [],
    AddressType.MAILING
  )
  const applicantGaragingAddress = findCustomerAddress(
    state?.order?.customer_info?.customer_addresses || [],
    AddressType.GARAGING
  )
  const coApplicantMailingAddress = findCustomerAddress(
    conApplicant?.customer_addresses || [],
    AddressType.MAILING
  )
  const garagingAddressState =
    applicantGaragingAddress?.entity_type == AddressType.MAILING
      ? applicantMailingAddress?.state_name
      : applicantGaragingAddress?.state_name
  const isShowMaritalStatus =
    isCoapplicant &&
    STATES_FOR_MARRIED_APPLICANT.includes(applicantMailingAddress?.state_name) &&
    applicantMailingAddress?.state_name == coApplicantMailingAddress?.state_name
  const fieldPermissions = useMemo(() => {
    const showSpouseListed = isShowMaritalStatus && isMarried
    const showSpouseAddress = showSpouseListed && !isSpouseCoapplicant
    const isSpouseListedPermission = disclosurePermissions.isSpouseListedCoapplicant.isShow
    const isRightToCancel = (STATES_FOR_RIGHT_TO_CANCEL.includes(dealerState) &&
                              disclosurePermissions.rightToCancel.isShow)
    return {
      customer: {
        marital_status: {
          isMandatory: false,
          isShow: isShowMaritalStatus && disclosurePermissions.isPrimaryApplicantMarried.isShow
        }
      },
      spouse: {
        first_name: {
          isMandatory: showSpouseAddress,
          isShow: showSpouseAddress && isSpouseListedPermission
        },
        last_name: {
          isMandatory: showSpouseAddress,
          isShow: showSpouseAddress && isSpouseListedPermission
        },
        address: {
          isMandatory: showSpouseAddress,
          isShow: showSpouseAddress && isSpouseListedPermission
        }
      },
      is_spouse_as_coapplicant: {
        isMandatory: showSpouseListed,
        isShow: showSpouseListed && isSpouseListedPermission
      },
      is_negotiated_with_applicant: {
        isMandatory: STATES_FOR_NEGOTIATED_DEAL.includes(dealerState),
        isShow:
          STATES_FOR_NEGOTIATED_DEAL.includes(dealerState) &&
          disclosurePermissions.isNegotiatedPrimaryApplicant.isShow
      },
      is_negotiated_with_coapplicant: {
        isMandatory: isCoapplicant && STATES_FOR_NEGOTIATED_DEAL.includes(dealerState),
        isShow:
          isCoapplicant &&
          STATES_FOR_NEGOTIATED_DEAL.includes(dealerState) &&
          disclosurePermissions.isNegotiatedPrimaryApplicant.isShow
      },
      tax_credit_amount: {
        isMandatory: false,
        isShow:
          dealType == FinanceType.LEASE &&
          STATES_FOR_TAX_CREDIT_AMOUNT.includes(dealerState) &&
          STATES_FOR_TAX_CREDIT_AMOUNT.includes(garagingAddressState) &&
          disclosurePermissions.taxCreditAmount.isShow
      },
      right_to_cancel: {
        isMandatory: isRightToCancel,
        isShow: isRightToCancel
      },
      purchase_option_fee: {
        isMandatory: false,
        isShow: disclosurePermissions.purchaseOptionFee.isShow
      },
      tax_exemption: {
        isMandatory: disclosurePermissions.taxExemption.isShow,
        isShow: disclosurePermissions.taxExemption.isShow
      },
      exemption_level: {
        isMandatory: isShowExemptionLevel,
        isShow: isShowExemptionLevel && disclosurePermissions.taxExemption.isShow
      },
      applicant_negotiated_languages: {
        isMandatory: false,
        isShow: false
      },
      coapplicant_negotiated_languages: {
        isMandatory: false,
        isShow: false
      },
      order_asset: {
        type: {
          isMandatory: disclosurePermissions.assetCondition.isMandatory,
          isShow: disclosurePermissions.taxExemption.isShow
        },
        engine_cylinder: {
          isMandatory: STATES_FOR_ODOMETER.includes(dealerState),
          isShow:
            STATES_FOR_ODOMETER.includes(dealerState) &&
            disclosurePermissions.engineCylinders.isShow
        },
        program_options: {
          isMandatory: disclosurePermissions.programOption.isMandatory,
          isShow: disclosurePermissions.programOption.isShow
        },
        transmission_type: {
          isMandatory: STATES_FOR_ODOMETER.includes(dealerState),
          isShow:
            STATES_FOR_ODOMETER.includes(dealerState) &&
            disclosurePermissions.transmissionType.isShow
        },
        vehicle_uses: {
          isMandatory: STATES_FOR_ODOMETER.includes(dealerState),
          isShow:
            STATES_FOR_ODOMETER.includes(dealerState) &&
            disclosurePermissions.vehiclePriorUse.isShow
        },
        mileage: {
          isMandatory: disclosurePermissions.vehiclePriorUse.isShow,
          isShow: disclosurePermissions.vehiclePriorUse.isShow
        },
        odometer_disclaimer: {
          isMandatory: disclosurePermissions.odometerQuestion.isShow,
          isShow: disclosurePermissions.odometerQuestion.isShow
        }
      }
    }
  }, [
    isShowMaritalStatus,
    isMarried,
    isSpouseCoapplicant,
    isShowExemptionLevel,
    dealType,
    dealerState
  ])
  // ======== Disclosure Form Implmentation
  const validationSchema = createDisclosureValidatorsSchema(fieldPermissions, isUsAddress)
  const form = useForm<IFormInputs>({
    defaultValues: disclosureData,
    resolver: yupResolver(validationSchema),
    mode: 'onTouched'
  })
  const {
    handleSubmit,
    formState: { errors, isDirty },
    reset,
    getValues,
    setValue,
    control,
    clearErrors,
    watch
  } = form
  const addressPayloadName = () => {
    return {
      ADDRESS_LAYOUT_ADDRESS_LINE_1: `Address Line 1 *`,
      ADDRESS_LAYOUT_CITY: `City *`,
      ADDRESS_LAYOUT_STATE: `State *`,
      ADDRESS_LAYOUT_POSTAL_CODE: `Zip code *`,
      ADDRESS_LAYOUT_ADDRESS_LINE_2_OPTIONAL: `Address Line 2`
    }
  }
  useEffect(() => {
    const taxExemption = getValues('tax_exemption') ?? false
    setIsShowExemptionLevel(taxExemption)
    setValue('exemption_level', taxExemption ? ExemptionLevel.State : null)
  }, [watch('tax_exemption')])
  useEffect(() => {
    if (fieldPermissions.customer.marital_status.isShow) {
      setValue(
        'customer.marital_status',
        isMarried ? MaritalStatus.Married : MaritalStatus.Unmarried
      )
    }
    if (fieldPermissions.is_spouse_as_coapplicant.isShow) {
      setValue('is_spouse_as_coapplicant', isSpouseCoapplicant)
    }
    if (fieldPermissions.is_negotiated_with_applicant.isShow) {
      setValue('is_negotiated_with_applicant', isNegotiatedAapplicant)
      setValue(
        'applicant_negotiated_languages',
        isNegotiatedAapplicant ? NEGOTIATED_LANGUAGES : null
      )
    }
    if (fieldPermissions.is_negotiated_with_coapplicant.isShow) {
      setValue('is_negotiated_with_coapplicant', isNegotiatedCoapplicant)
      setValue(
        'coapplicant_negotiated_languages',
        isNegotiatedCoapplicant ? NEGOTIATED_LANGUAGES : null
      )
    }
  }, [
    fieldPermissions,
    isMarried,
    isSpouseCoapplicant,
    isNegotiatedAapplicant,
    isNegotiatedCoapplicant
  ])
  useEffect(() => {
    setIsDisclosureErrors(validateObject(errors))
  }, [errors])
  useEffect(() => {
    setValue('customer_id', state?.order?.customer_id)
    setValue('reference_number', state?.order?.reference_number)
  }, [state.order])
  useEffect(() => {
    if (validateObject(disclosureData)) {
      if (disclosureData?.customer?.marital_status) {
        setIsMarried(disclosureData?.customer?.marital_status == MaritalStatus.Married)
        setIsSpouseCoapplicant(disclosureData?.is_spouse_as_coapplicant)
      }
      setIsNegotiatedAapplicant(disclosureData?.is_negotiated_with_applicant == true)
      setIsNegotiatedCoapplicant(disclosureData?.is_negotiated_with_coapplicant == true)
      reset(disclosureData)
      if (
        STATES_FOR_RIGHT_TO_CANCEL.includes(dealerState) &&
        disclosurePermissions.rightToCancel.isShow
      ) {
        setValue('right_to_cancel', disclosureData?.right_to_cancel || 20)
      }
    }
  }, [disclosureData])

  const onSubmit = async (payload: IFormInputs) => {
    await saveCustomerDiscloure(payload, {
      onSuccess() {
        actions.setToast({
          toastMessage: 'Record updated successfully',
          toastState: true
        })
        setIsDisclosure(true)
      }
    })
  }
  const isMandatoryField = (isMandatory: boolean) => (isMandatory ? '*' : '')

  const handleDiscard = () => {
    if (isNotNullOrUndefined(pendingRoute)) {
      setRoute(pendingRoute)
      setPendingRoute(null)
    } else {
      if (nextLocation) {
        navigate(nextLocation, { replace: true })
      }
    }
  }

  const [
    { showDialog, setShowDialog, nextLocation },
    { handleSaveAndLeave }
  ] = usePreventNavigation({
    isDirty: isDirty,
    onSave: async () => {
        const isValidForm = await form.trigger()

        if (!isValidForm) {
          setPendingRoute(null)
          throw new Error('Form has validation errors')
        }

        const values = getValues()

        await onSubmit(values)

        if (nextLocation) {
            if (isNotNullOrUndefined(pendingRoute)) {
              setRoute(pendingRoute)
              setPendingRoute(null)
              return
            }
            navigate(nextLocation, { replace: true })
        }
    }
  })

  useEffect(() => {
    if (isNotNullOrUndefined(pendingRoute) && (isDirty || _isAddressDirty)) {
      setShowDialog(true)
    } else {
    if (isNotNullOrUndefined(pendingRoute)) {
        setRoute(pendingRoute)
        setPendingRoute(null)
      }
    }
  }, [pendingRoute, isDirty, _isAddressDirty])

  return (
    <>
      <Typography theme={theme} component="h3" variant="h3" children="Disclosures" mb={3} />
      <Grid
        theme={theme}
        container
        rowSpacing={3}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        sx={{ mb: 4 }}
      >
        {fieldPermissions.order_asset.type.isShow && (
          <Grid theme={theme} item xs={6}>
            <Controller
              name="order_asset.type"
              control={control}
              defaultValue={''}
              rules={validation('Asset Condition', true)}
              render={({ field: { value, onChange } }) => (
                <Select
                  theme={theme}
                  fullWidth
                  label={`Asset Condition ${isMandatoryField(
                    fieldPermissions.order_asset.type.isMandatory
                  )}`}
                  items={vehicle_condition}
                  disablePortal={false}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                  }}
                  value={value}
                  onChange={(e) => {
                    onChange(e)
                  }}
                  selectError={(errors?.order_asset?.type as any)?.message as never}
                />
              )}
            />
          </Grid>
        )}

        {fieldPermissions.order_asset.program_options.isShow && (
          <Grid theme={theme} item xs={6}>
            <Controller
              name="order_asset.program_options"
              control={control}
              render={({ field: { value, onChange } }) => (
                <>
                  <MultiSelect
                    theme={theme}
                    fullWidth
                    label={`Program Options ${isMandatoryField(
                      fieldPermissions.order_asset.program_options.isMandatory
                    )}`}
                    items={PROGRAM_OPTIONS}
                    value={Array.isArray(value) ? value : []}
                    onChange={(e) => {
                      onChange(e?.target?.value)
                      clearErrors('order_asset.program_options')
                    }}
                    selectError={(errors?.order_asset?.program_options as any)?.message as never}
                  />
                </>
              )}
            />
          </Grid>
        )}

        {fieldPermissions.tax_exemption.isShow && (
          <Grid theme={theme} item xs={6}>
            <Controller
              name="tax_exemption"
              control={control}
              defaultValue={false}
              render={({ field: { value, onChange } }) => (
                <Select
                  theme={theme}
                  fullWidth
                  label={`Tax Exemption ${isMandatoryField(
                    fieldPermissions.tax_exemption.isMandatory
                  )}`}
                  items={CONFIRM_OPTIONS}
                  disablePortal={false}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                  }}
                  value={value}
                  onChange={(e) => {
                    onChange(e)
                  }}
                  selectError={errors?.tax_exemption?.message as never}
                />
              )}
            />
          </Grid>
        )}
        {fieldPermissions.exemption_level.isShow && (
          <Grid theme={theme} item xs={6}>
            <Controller
              name="exemption_level"
              control={control}
              render={({ field: { value, onChange } }) => (
                <Select
                  theme={theme}
                  fullWidth
                  label={`Exemption Level ${isMandatoryField(
                    fieldPermissions.exemption_level.isMandatory
                  )}`}
                  items={EXEMPTION_LEVELS}
                  disablePortal={false}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                  }}
                  value={value}
                  onChange={(e) => {
                    onChange(e)
                  }}
                  selectError={errors?.exemption_level?.message as never}
                />
              )}
            />
          </Grid>
        )}
        {fieldPermissions.tax_credit_amount.isShow && (
          <Grid theme={theme} item xs={6}>
            <Controller
              name="tax_credit_amount"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  value={field?.value as number}
                  theme={theme}
                  fullWidth
                  startAdornment="$"
                  label={`Tax Credit Amount ${isMandatoryField(
                    fieldPermissions.tax_credit_amount.isMandatory
                  )}`}
                  placeholder="0,000.00"
                  maskDecimalScale={2}
                  type={'number'}
                  onChange={(val) => {
                    clearErrors('tax_credit_amount')
                    val = Number.parseFloat(val).toFixed(2)
                    field.onChange(val)
                  }}
                  error={errors?.tax_credit_amount?.message?.toString()}
                />
              )}
            />
          </Grid>
        )}
        {fieldPermissions.purchase_option_fee.isShow && (
          <Grid theme={theme} item xs={6}>
            <Controller
              name="purchase_option_fee"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  value={field?.value as number}
                  theme={theme}
                  fullWidth
                  startAdornment="$"
                  label={`Purchase Option Fee ${isMandatoryField(
                    fieldPermissions.purchase_option_fee.isMandatory
                  )}`}
                  placeholder="0,000.00"
                  maskDecimalScale={2}
                  type={'number'}
                  onChange={(val) => {
                    clearErrors('purchase_option_fee')
                    val = Number.parseFloat(val).toFixed(2)
                    field.onChange(val)
                  }}
                  error={errors?.purchase_option_fee?.message?.toString()}
                />
              )}
            />
          </Grid>
        )}
        {fieldPermissions.order_asset.transmission_type.isShow && (
          <Grid theme={theme} item xs={6}>
            <Controller
              name="order_asset.transmission_type"
              control={control}
              render={({ field: { value, onChange } }) => (
                <Select
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                  }}
                  disablePortal={false}
                  theme={theme}
                  label={`Transmission Type ${isMandatoryField(
                    fieldPermissions.order_asset.transmission_type.isMandatory
                  )}`}
                  items={TRANSMISSION_TYPE}
                  value={value}
                  onChange={(e) => {
                    onChange(e)
                  }}
                  selectError={errors?.order_asset?.transmission_type?.message as never}
                />
              )}
            />
          </Grid>
        )}
        {fieldPermissions.order_asset.engine_cylinder.isShow && (
          <Grid theme={theme} item xs={6}>
            <Controller
              name="order_asset.engine_cylinder"
              control={control}
              render={({ field: { value, onChange } }) => (
                <Select
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                  }}
                  disablePortal={false}
                  theme={theme}
                  label={`Engine Cylinders ${isMandatoryField(
                    fieldPermissions.order_asset.engine_cylinder.isMandatory
                  )}`}
                  items={engine_cylinder}
                  value={value}
                  onChange={(e) => {
                    onChange(e)
                  }}
                  selectError={errors?.order_asset?.engine_cylinder?.message as never}
                />
              )}
            />
          </Grid>
        )}
        {fieldPermissions.order_asset.vehicle_uses.isShow && (
          <Grid theme={theme} item xs={6}>
            <Controller
              name="order_asset.vehicle_uses"
              control={control}
              render={({ field: { value, onChange } }) => (
                <Select
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                  }}
                  disablePortal={false}
                  theme={theme}
                  label={`Vehicle Prior Use ${isMandatoryField(
                    fieldPermissions.order_asset.vehicle_uses.isMandatory
                  )}`}
                  items={VEHICLE_PRIOR_USE}
                  value={value}
                  onChange={(e) => {
                    onChange(e)
                  }}
                  selectError={errors?.order_asset?.vehicle_uses?.message as never}
                />
              )}
            />
          </Grid>
        )}
        {fieldPermissions.right_to_cancel.isShow && (
          <Grid theme={theme} item xs={6}>
            <Controller
              name="right_to_cancel"
              control={control}
              defaultValue={20}
              render={({ field }) => (
                <Input
                  {...field}
                  value={field?.value as number}
                  theme={theme}
                  fullWidth
                  type={'number'}
                  endAdornment="Day(s)"
                  label={`Right To Cancel ${isMandatoryField(
                    fieldPermissions.order_asset.vehicle_uses.isMandatory
                  )}`}
                  placeholder="Type here.."
                  onChange={(val) => {
                    clearErrors('right_to_cancel')
                    field.onChange(val)
                  }}
                  error={errors?.right_to_cancel?.message?.toString()}
                />
              )}
            />
          </Grid>
        )}
        <Grid theme={theme} item xs={12}>
          <hr />
        </Grid>
        {fieldPermissions.order_asset.mileage.isShow && (
          <Grid theme={theme} item xs={12}>
            <Controller
              name="order_asset.mileage"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  value={field?.value as number}
                  theme={theme}
                  testid="mileage"
                  fullWidth
                  label={`Current Mileage (Odometer) ${isMandatoryField(
                    fieldPermissions.order_asset.mileage.isMandatory
                  )}`}
                  placeholder={'Add here..'}
                  type={'number'}
                  onChange={(val) => {
                    clearErrors('order_asset.mileage')
                    field.onChange(val)
                  }}
                  error={errors?.order_asset?.mileage?.message?.toString()}
                />
              )}
            />
          </Grid>
        )}
        {fieldPermissions.order_asset.odometer_disclaimer.isShow && (
          <Grid theme={theme} item xs={12}>
            <Controller
              name="order_asset.odometer_disclaimer"
              control={control}
              render={({ field }) => (
                <Radio
                  {...field}
                  options={disclaimers?.map((disclaimer: any, index: number) => ({
                    label: (
                      <Typography theme={theme} component="p" variant="caption" key={index}>
                        {disclaimer.description}
                      </Typography>
                    ),
                    value: disclaimer.description,
                    disabled: false
                  }))}
                  radioError={errors?.order_asset?.odometer_disclaimer?.message as never}
                  row
                  theme={theme}
                  fullWidth
                />
              )}
            />
          </Grid>
        )}

        {fieldPermissions.customer.marital_status.isShow && (
          <>
            <Grid theme={theme} item xs={12}>
              <hr />
            </Grid>
            <Grid theme={theme} item xs={6}>
              <Typography
                variant="subtitle1"
                component={'div'}
                theme={theme}
                sx={{ mb: 1 }}
                children="Is the primary applicant married?"
              />
              <RadioGroupWrap theme={theme} className="radio-group-wrap">
                <Radio
                  className="custom-radio"
                  theme={theme}
                  row={true}
                  value={isMarried}
                  onChange={(e: any) => {
                    setIsMarried(e.toLowerCase() == 'true')
                  }}
                  options={CONFIRM_OPTIONS}
                />
              </RadioGroupWrap>
            </Grid>
          </>
        )}
        {fieldPermissions.is_spouse_as_coapplicant.isShow && (
          <Grid theme={theme} item xs={6}>
            <Typography
              variant="subtitle1"
              component={'div'}
              theme={theme}
              sx={{ mb: 1 }}
              children="Is the spouse listed as co-applicant on this deal?"
            />
            <RadioGroupWrap theme={theme} className="radio-group-wrap">
              <Radio
                className="custom-radio"
                theme={theme}
                row={true}
                value={isSpouseCoapplicant}
                onChange={(e: any) => {
                  setIsSpouseCoapplicant(e.toLowerCase() == 'true')
                }}
                options={CONFIRM_OPTIONS}
              />
            </RadioGroupWrap>
          </Grid>
        )}
        {fieldPermissions.spouse.first_name.isShow && (
          <Grid theme={theme} item xs={6}>
            <Controller
              name="spouse.first_name"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  value={field?.value as string}
                  theme={theme}
                  fullWidth
                  type={'text'}
                  label={`First Name ${isMandatoryField(
                    fieldPermissions.spouse.first_name.isMandatory
                  )}`}
                  placeholder="Type here.."
                  onChange={(val) => {
                    clearErrors('spouse.first_name')
                    field.onChange(val)
                  }}
                  error={errors?.spouse?.first_name?.message?.toString()}
                />
              )}
            />
          </Grid>
        )}
        {fieldPermissions.spouse.last_name.isShow && (
          <Grid theme={theme} item xs={6}>
            <Controller
              name="spouse.last_name"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  value={field?.value as string}
                  theme={theme}
                  fullWidth
                  type={'text'}
                  label={`Last Name ${isMandatoryField(
                    fieldPermissions.spouse.last_name.isMandatory
                  )}`}
                  placeholder="Type here.."
                  onChange={(val) => {
                    clearErrors('spouse.last_name')
                    field.onChange(val)
                  }}
                  error={errors?.spouse?.first_name?.message?.toString()}
                />
              )}
            />
          </Grid>
        )}
        {fieldPermissions.spouse.address.isShow && (
          <Grid theme={theme} item xs={12}>
            <AddressDetailsComponent
              form={form}
              isGoogleEnabled={false}
              addressValue={'spouse.address'}
              setIsAddressDirty={setIsAddressDirty}
              errorProps={errors?.spouse?.address}
              placeholders={addressPayloadName()}
              moveIn={false}
            />
          </Grid>
        )}

        {fieldPermissions.is_negotiated_with_applicant.isShow && (
          <>
            <Grid theme={theme} item xs={12}>
              <hr />
            </Grid>
            <Grid theme={theme} item xs={6}>
              <Typography
                variant="subtitle1"
                component={'div'}
                theme={theme}
                sx={{ mb: 1 }}
                children="Was the deal negotiated in Spanish with Primary Applicant?"
              />
              <RadioGroupWrap theme={theme} className="radio-group-wrap">
                <Radio
                  className="custom-radio"
                  theme={theme}
                  row={true}
                  options={CONFIRM_OPTIONS}
                  value={isNegotiatedAapplicant}
                  onChange={(e: any) => {
                    setIsNegotiatedAapplicant(e)
                  }}
                />
              </RadioGroupWrap>
            </Grid>
          </>
        )}
        {fieldPermissions.is_negotiated_with_coapplicant.isShow && (
          <Grid theme={theme} item xs={6}>
            <Typography
              variant="subtitle1"
              component={'div'}
              theme={theme}
              sx={{ mb: 1 }}
              children="Was the deal negotiated in Spanish with Co-Applicant?"
            />
            <RadioGroupWrap theme={theme} className="radio-group-wrap">
              <Radio
                className="custom-radio"
                theme={theme}
                row={true}
                options={CONFIRM_OPTIONS}
                value={isNegotiatedCoapplicant}
                onChange={(e: any) => {
                  setIsNegotiatedCoapplicant(e)
                }}
              />
            </RadioGroupWrap>
          </Grid>
        )}
        <Grid theme={theme} item xs={12}>
          <hr />
        </Grid>
        <Grid theme={theme} item xs={12} textAlign={'right'}>
          <Button
            type="submit"
            onClick={handleSubmit(onSubmit)}
            theme={theme}
            primary
            text="Save Disclosures"
          />
        </Grid>
      </Grid>
      <ConfirmationDialog
        openPopUp={showDialog}
        confirmationTitle={DialogMessages.saveChanges}
        confirmationText={DialogMessages.unsaveChanges}
        primaryBtnText="Save"
        secondaryBtnText="Discard"
        icon={<Icon className="alert-icon" name="DialogAlertIcon" />}
        hideCancelButton={false}
        setPopUpState={setShowDialog}
        onConfirm={handleSaveAndLeave}
        onCancel={handleDiscard}
      />
    </>
  )
}
