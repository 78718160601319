import { FC, useEffect, useState } from 'react'
import { useTheme } from '@mui/material'
import {
  Box,
  Typography,
  Button,
  // Tooltip,
  Icon,
  Menu
} from '@ntpkunity/controls'
import { DocumentViewModal } from '@ntpkunity/controls-common'
import { DetailCard } from 'components'
import { useGetPreSignedUrl } from '@apis/order-management.service'
import { Status } from '@helpers/enums'
import ContractDocuments from './contract-documents'
interface IContractsProps {
  orderStatus: string
  dealerId: string
  referenceNumber: string
  referenceId: string
  contractDocument?: any
  generalDocument?: any
  setApprovedDoc?: any
}
const Contracts: FC<IContractsProps> = ({
  orderStatus,
  dealerId,
  referenceNumber,
  referenceId,
  contractDocument,
  generalDocument,
  setApprovedDoc
}) => {
  const { mutate: getPreSignedUrl } = useGetPreSignedUrl()
  const theme = useTheme()
  const [openDocumentViewer, setOpenDocumentViewer] = useState<boolean>(false)
  const [viewDocument, setViewDocument] = useState<any>({})

  useEffect(() => {
    if (viewDocument && Object.keys(viewDocument).length > 0 && !openDocumentViewer) {
      if (setApprovedDoc) {
        setApprovedDoc(!openDocumentViewer)
      }
    }
  }, [openDocumentViewer])

  const handleDownloadAll = (type: string) => {
    if (type == 'Contract') {
      contractDocument[0]?.documents?.forEach((document: any) => {
        getPreSignedUrl(
          { key: document?.generated_document_uri },
          {
            onSuccess(res: any) {
              window.open(res, '_blank')
            }
          }
        )
      })
    } else {
      generalDocument[0]?.documents?.forEach((document: any) => {
        getPreSignedUrl(
          { key: document?.generated_document_uri },
          {
            onSuccess(res: any) {
              window.open(res, '_blank')
            }
          }
        )
      })
    }
  }

  return (
    <>
      {contractDocument &&
        Array.isArray(contractDocument) &&
        contractDocument[0] &&
        contractDocument[0].documents &&
        contractDocument[0].documents.length > 0 && (
          <DetailCard className="detail-card" mb={2}>
            <Box className="card-body" theme={theme}>
              <Typography component="h4" variant="h4" theme={theme} mb={2.5} children="Documents" />
            </Box>
            {contractDocument[0]?.documents?.length > 0 && (
              <Box className="card-body" theme={theme}>
                <Box className="row" mb={3} theme={theme}>
                  <Typography
                    component="b"
                    variant="caption"
                    theme={theme}
                    className="fw-600 text-uppercase"
                    children="On-screen Signature Document(s)"
                  />
                  {orderStatus == Status.Approved ? (
                    <Button
                      defaultBtn
                      iconText={<Icon name="SaveIcon" />}
                      onClick={() => {
                        handleDownloadAll('Contract')
                      }}
                    />
                  ) : (
                    ''
                  )}
                </Box>
                {contractDocument[0]?.documents?.map((document: any, index: number) => (
                  <div key={index} className="secondary-wrap">
                    <ContractDocuments
                      document={document}
                      setOpenDocumentViewer={setOpenDocumentViewer}
                      setViewDocument={setViewDocument}
                    />
                  </div>
                ))}
              </Box>
            )}
            {generalDocument[0]?.documents?.length > 0 && (
              <Box className="card-body" theme={theme}>
                <Box className="row" mb={3} theme={theme}>
                  <Typography
                    component="b"
                    variant="caption"
                    theme={theme}
                    className="fw-600 text-uppercase"
                    children="General Document(S)"
                  />
                  {orderStatus == Status.Approved ? (
                    <Button
                      defaultBtn
                      iconText={<Icon name="SaveIcon" />}
                      onClick={() => {
                        handleDownloadAll('General')
                      }}
                    />
                  ) : (
                    ''
                  )}
                </Box>
                {generalDocument[0]?.documents?.map((document: any, index: number) => (
                  <div key={index} className="secondary-wrap">
                    <Box className="row" theme={theme}>
                      <Box className="center title" theme={theme}>
                        <Icon name="DocumentIcon" />
                        <Typography
                          variant="body2"
                          sx={{ ml: 2 }}
                          component="h3"
                          className="card-text"
                          theme={theme}
                          children={document?.title}
                        />
                      </Box>
                      <Box className="center actions" theme={theme}>
                        <Menu
                          sx={{ ml: 2 }}
                          theme={theme}
                          options={[
                            {
                              optionText: (
                                <>
                                  <Icon className="menu-icon" name="IcView" /> View
                                </>
                              ),
                              optionkey: 'View',
                              optionValue: document
                            },

                            {
                              optionText: (
                                <>
                                  <Icon className="menu-icon" name="SaveIcon" /> Download
                                </>
                              ),
                              optionkey: 'Download',
                              optionValue: document
                            }
                          ]}
                          handleOptionClick={(_event: any, key: any, value: any) => {
                            switch (key) {
                              case 'Download':
                                getPreSignedUrl(
                                  { key: value?.generated_document_uri },
                                  {
                                    onSuccess(res: any) {
                                      window.open(res, '_blank')
                                    }
                                  }
                                )
                                break
                              case 'View':
                                setOpenDocumentViewer(true)
                                setViewDocument(value)
                                break
                              default:
                                break
                            }
                          }}
                          render={(handleOptionClick: any) => (
                            <Box theme={theme}>
                              <Button
                                theme={theme}
                                defaultBtn
                                iconText={<Icon name="MoreIcon" />}
                                onClick={handleOptionClick}
                              />
                            </Box>
                          )}
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right'
                          }}
                          transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right'
                          }}
                        />
                      </Box>
                    </Box>
                    <Box className="line" theme={theme}>
                      <hr />
                    </Box>
                  </div>
                ))}
              </Box>
            )}
          </DetailCard>
        )}
      {openDocumentViewer && (
        <>
          <DocumentViewModal
            theme={theme}
            selectedDocument={viewDocument}
            openPopup={openDocumentViewer}
            referenceId={referenceId}
            referenceNumber={referenceNumber}
            dealerId={dealerId}
            setOpenPopup={setOpenDocumentViewer}
          />
        </>
      )}
    </>
  )
}

export default Contracts
