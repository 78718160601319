import { FC, useEffect, useState } from 'react'
import { useTheme } from '@mui/material'
import {
  unityTheme,
  Select,
  Radio,
  Grid,
  Input,
  Box,
  Typography,
  Button,
  Icon
} from '@ntpkunity/controls'
import { Controller, useForm } from 'react-hook-form'
import { IPersonalFinance } from '../../models/customer-details.interface'
import { isEmpty, validation } from '../../helpers/methods'
import {
  useAddPersonalFinance,
  useUpdatePeronalFinanceInfo
} from '../../apis/customer-detail.service'
import { ResidenceType } from '../../helpers/enums/personal-finance.enum'
import { useQueryClient } from 'react-query'
import { useStoreContext } from '@store/storeContext'
import { DialogMessages, QueryKeys } from '@helpers/enums'
import { ConfirmationDialog, RadioGroupWrap } from '@components'
import { usePreventNavigation } from '@hooks/usePreventNavigation'
import { useNavigate } from 'react-router-dom'
import { isNotNullOrUndefined } from '@app/desking/utils'

const residenceType = [
  { text: 'Mortgage', value: 'Mortgage' },
  { text: 'Renting', value: 'Renting' },
  { text: 'Personal Ownership', value: 'Personal Ownership' },
  { text: 'With Relatives', value: 'With Relatives' }
]
const messages = {
  name: {
    residence: 'residence',
    monthlyPayment: 'monthly_payment',
    bankruptcy: 'bankruptcy',
    assetUsage: 'asset_usage'
  },
  label: {
    residence: 'Residence',
    monthlyPayment: 'Monthly Payment',
    bankruptcy: 'Bankruptcy'
  },
  placeholder: {
    typeHere: 'Type here...',
    monthlyPayment: '0.00'
  }
}
interface IPersonalFinanceComponentProps {
  // setIsOrderSubmit: (Boolean: any) => void
  setIsPersonalFinanceErrors?: (value: boolean) => void
  setPersonalFinance?: (value: boolean) => void
  personalFinanceData?: any
  customerReferenceId: any
  dealer_code?: string
  permissionName?: any
  pendingRoute?: any
  setPendingRoute?: any
  setRoute?: any
  setNextRoute?: any
}

const PersonalFinanceDetailsComponent: FC<IPersonalFinanceComponentProps> = ({
  // setIsOrderSubmit,
  setPersonalFinance,
  setIsPersonalFinanceErrors,
  personalFinanceData,
  customerReferenceId,
  dealer_code,
  permissionName,
  pendingRoute,
  setPendingRoute,
  setRoute,
  setNextRoute
}) => {
  const theme = useTheme()
  const {
    control,
    formState: { errors, isDirty },
    handleSubmit,
    reset,
    trigger,
    getValues,
    setValue,
    watch
  } = useForm<IPersonalFinance>({
    shouldUnregister: true,
    mode: 'onBlur'
  })
  const { mutate: createPersonalFinance } = useAddPersonalFinance(dealer_code)
  const { mutate: updatePersonalFinance } = useUpdatePeronalFinanceInfo(dealer_code)
  // const { data: personalFinanceData } = UseQuery_Get_Personal_Finance(customerReferenceId as any)
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const { states } = useStoreContext()
  const [defaultCurrency, setDefaultCurrency] = useState('')
  const currencies: any = queryClient.getQueryData([
    QueryKeys.GET_ALL_CURRENCIES,
    states?.lenderInfo?.id
  ])
  useEffect(() => {
    const filteredCurrency = currencies?.filter(
      (currency: any) => currency.id === states?.lenderInfo?.default_currency
    )
    setDefaultCurrency(
      filteredCurrency && filteredCurrency.length > 0 ? filteredCurrency[0].symbol : ''
    )
  }, [currencies])

  useEffect(() => {
    if (personalFinanceData) {
      const { residence, monthly_payment, bankruptcy } = personalFinanceData
      const monthlyPayment = monthly_payment
      reset({
        residence,
        monthly_payment: monthlyPayment > 0 ? monthlyPayment : '',
        bankruptcy
      })
    }
  }, [personalFinanceData])
  const hasErrors = Object.keys(errors).length > 0
  if (hasErrors) {
    setPersonalFinance?.(false)
    setIsPersonalFinanceErrors?.(hasErrors)
  }

  useEffect(() => {
    return () => {
      if (personalFinanceData) {
        setIsPersonalFinanceErrors?.(true)
        setPersonalFinance?.(true)
      }
    }
  }, [personalFinanceData])

  const onSubmit = async (data: IPersonalFinance) => {
    let { residence, monthly_payment, bankruptcy } = data
    const residenceType =
      residence === ResidenceType.Renting || residence === ResidenceType.Mortgage ? true : false
    if (!residenceType) {
      monthly_payment = 0
    }
    const payload = {
      residence,
      monthly_payment,
      bankruptcy,
      reference_id: customerReferenceId
    }
    if (isEmpty(personalFinanceData)) {
      createPersonalFinance(payload, {
        onSuccess: () => {
          setPersonalFinance?.(true)
          // setIsOrderSubmit(true)
          setIsPersonalFinanceErrors?.(true)
          setNextRoute()
        }
      })
    } else if (isDirty) {
      updatePersonalFinance(payload, {
        onSuccess: () => {
          setPersonalFinance?.(true)
          // setIsOrderSubmit(true)
          setIsPersonalFinanceErrors?.(true)
          setNextRoute()
        }
      })
    } else {
      setPersonalFinance?.(true)
      // setIsOrderSubmit(true)
      setIsPersonalFinanceErrors?.(true)
      setNextRoute()
    }
  }

  const handleDiscard = () => {
    if (isNotNullOrUndefined(pendingRoute)) {
      setRoute(pendingRoute)
      setPendingRoute(null)
    } else {
      if (nextLocation) {
        navigate(nextLocation, { replace: true })
      }
    }
  }

  const [
    { showDialog, setShowDialog, nextLocation },
    { handleSaveAndLeave }
  ] = usePreventNavigation({
    isDirty: isDirty,
    onSave: async () => {
        const isValidForm = await trigger()

        if (!isValidForm) {
          setPendingRoute(null)
          throw new Error('Form has validation errors')
        }
        const values = getValues()

        await onSubmit(values)

        if (nextLocation) {
          if (isNotNullOrUndefined(pendingRoute)) {
            setNextRoute()
            setRoute(pendingRoute)
            setPendingRoute(null)
            return
          }
          navigate(nextLocation, { replace: true })
        }
    }
  })

  useEffect(() => {
    if (isNotNullOrUndefined(pendingRoute) && isDirty) {
      setShowDialog(true)
    } else {
      if (isNotNullOrUndefined(pendingRoute)) {
        setRoute(pendingRoute)
        setPendingRoute(null)
      }
    }
  }, [pendingRoute, isDirty])

  return (
    <>
      <Typography theme={theme} component="h3" variant="h3" sx={{ mb: 3 }}>
        Personal Finance Details
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid
          theme={theme}
          container
          rowSpacing={3}
          sx={{ mb: 2 }}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        >
          <Grid theme={theme} item xs={12} sm={6} sx={{ mb: 1 }}>
            <Controller
              name={messages.name.residence as any}
              rules={validation(messages.label.residence, true)}
              control={control}
              defaultValue=""
              render={({ field }) => (
                <Select
                  theme={theme}
                  {...field}
                  label={messages.label.residence}
                  items={residenceType}
                  selectError={errors?.residence?.message}
                  sxProps={''}
                  onChange={(e: any) => {
                    field.onChange(e.target.value)
                    if (
                      e.target.value === ResidenceType.Renting ||
                      e.target.value === ResidenceType.Mortgage
                    ) {
                      setValue(
                        'monthly_payment',
                        personalFinanceData?.monthly_payment > 0
                          ? personalFinanceData?.monthly_payment
                          : ''
                      )
                    }
                  }}
                />
              )}
            />
          </Grid>
          {(watch('residence') === ResidenceType.Renting ||
            watch('residence') === ResidenceType.Mortgage) && (
            <Grid theme={theme} item xs={12} sm={6} sx={{ mb: 1 }}>
              <Controller
                name={messages.name.monthlyPayment as any}
                control={control}
                rules={validation(
                  messages.label.monthlyPayment,
                  permissionName?.personalFinanceMonthlyDetails
                )}
                render={({ field }) => (
                  <Input
                    {...field}
                    theme={unityTheme}
                    fullWidth
                    masking
                    maskNumeric
                    maskDecimalScale={2}
                    placeholder={messages?.placeholder?.monthlyPayment}
                    label={
                      messages?.label?.monthlyPayment +
                      `${permissionName?.personalFinanceMonthlyDetails ? '*' : ''}`
                    }
                    startAdornment={defaultCurrency}
                    type="text"
                    error={errors?.monthly_payment?.message}
                  />
                )}
              />
            </Grid>
          )}
        </Grid>
        <Typography variant="subtitle1" component={'div'} theme={theme} sx={{ mb: 1 }}>
          Have you ever filed for bankruptcy?
        </Typography>
        <RadioGroupWrap theme={theme} className="radio-group-wrap">
          <Controller
            name={messages?.name?.bankruptcy as any}
            control={control}
            defaultValue={false}
            render={({ field }: any) => (
              <Radio
                className="custom-radio"
                {...field}
                theme={theme}
                row={true}
                options={[
                  {
                    label: 'Yes',
                    value: true
                  },
                  {
                    label: 'No',
                    value: false
                  }
                ]}
              />
            )}
          />
        </RadioGroupWrap>

        <Box theme={theme} sx={{ mb: 4 }}>
          <hr />
        </Box>
        {isDirty ? (
          <Box theme={theme} textAlign="right" sx={{ mb: 5 }}>
            <Button theme={theme} primary text=" Save Personal Finance Details" type="submit" />
          </Box>
        ) : null}
      </form>
      <ConfirmationDialog
        openPopUp={showDialog}
        confirmationTitle={DialogMessages.saveChanges}
        confirmationText={DialogMessages.unsaveChanges}
        primaryBtnText="Save"
        secondaryBtnText="Discard"
        icon={<Icon className="alert-icon" name="DialogAlertIcon" />}
        hideCancelButton={false}
        setPopUpState={setShowDialog}
        onConfirm={handleSaveAndLeave}
        onCancel={handleDiscard}
      />
    </>
  )
}
export default PersonalFinanceDetailsComponent
