import { DataTableWrap, DialogTotal } from '@components'
import {
  Box,
  Button,
  DataTable,
  Dialog,
  Grid,
  Icon,
  Input,
  Menu,
  Select
} from '@ntpkunity/controls'
import { FC, useState, useEffect } from 'react'
import { TableCell, TableRow, useTheme } from '@mui/material'
import { useForm, Controller } from 'react-hook-form'
import { EditFeesProps, Fee } from '@models'

interface FormValues {
  feeType: string
  vendorName: string
  feeAmount: string
}

const EditFees: FC<EditFeesProps> = ({
  dealerFees,
  setDealerFees,
  financeType,
  defaultCurrency,
  vin: _vin
}) => {
  const theme = useTheme()
  const {
    control,
    watch,
    reset,
    formState: { isDirty }
  } = useForm<FormValues>()
  const [selectedFee, setSelectedFee] = useState<Fee | null>(null)
  const [isOpen, setIsOpen] = useState(false)
  const [localDealerFees, setLocalDealerFees] = useState(dealerFees)
  const [searchFilters, _setSearchFilters] = useState({
    feeName: '',
    feeType: '',
    vendorName: ''
  })

  useEffect(() => {
    setLocalDealerFees(dealerFees)
  }, [dealerFees])

  const getFeeTypeOptions = () => {
    if (selectedFee) {
      return [
        { text: 'Upfront', value: 'Upfront' },
        { text: 'Financed', value: 'Financed' }
      ]
    }
    return []
  }

  const handleClose = () => {
    setIsOpen(false)
    setSelectedFee(null)
    reset()
  }

  const openEditFee = (fee: Fee) => {
    setSelectedFee(fee)
    setIsOpen(true)
  }

  useEffect(() => {
    if (selectedFee) {
      reset({
        feeType: selectedFee.fee_handling || '',
        vendorName: selectedFee.vendor || '',
        feeAmount: (
          selectedFee.applied_price?.toFixed(2) ||
          selectedFee.default_amount?.toFixed(2) ||
          ''
        ).toString()
      })
    }
  }, [selectedFee, reset])

  const filteredFees = localDealerFees[financeType]?.filter((fee: Fee) => {
    return (
      fee.fee_name.toLowerCase().includes(searchFilters.feeName.toLowerCase()) &&
      fee.fee_handling.toLowerCase().includes(searchFilters.feeType.toLowerCase()) &&
      (!searchFilters.vendorName ||
        (fee.vendor?.toLowerCase() || '').includes(searchFilters.vendorName.toLowerCase()))
    )
  })

  const calculateTotal = () => {
    return filteredFees?.reduce((sum: number, fee: Fee) => {
      const value = parseFloat(
        fee?.applied_price?.toString() || fee?.default_amount?.toString() || '0'
      )
      return sum + value
    }, 0)
  }

  const totalAmount = calculateTotal()

  const onFeeUpdate = () => {
    if (!selectedFee) return

    const formData = watch()
    const updatedAmount = parseFloat(formData.feeAmount)

    const updatedFee: Fee = {
      ...selectedFee,
      fee_handling: formData.feeType,
      vendor: formData.vendorName,
      applied_price: updatedAmount,
      default_amount: selectedFee.default_amount !== undefined ? updatedAmount : undefined
    }

    const updatedDealerFees = {
      ...localDealerFees,
      [financeType]: localDealerFees[financeType].map((fee: Fee) => {
        if (fee.fee_name === selectedFee.fee_name) {
          return updatedFee
        }
        return fee
      })
    }

    setLocalDealerFees(updatedDealerFees)
    setDealerFees(updatedDealerFees)
    setSelectedFee(updatedFee)
    handleClose()
  }

  return (
    <>
      <DataTableWrap theme={theme} className="table-pagination table-dialog">
        <Box theme={theme} className="scroll">
          <Box theme={theme} className="scroll-hide sh-fixed-cell spr-border" />
        </Box>
        <DataTable
          theme={theme}
          variant="basic"
          theadChildren={
            <>
              <TableRow>
                <TableCell>Fee Name</TableCell>
                <TableCell>Fee Type</TableCell>
                <TableCell>Vendor Name</TableCell>
                <TableCell>Fee Amount</TableCell>
                <TableCell className="action-cell fixed-cell"></TableCell>
              </TableRow>
            </>
          }
          tbodyChildren={
            <>
              {filteredFees?.map((fee: Fee, index: number) => (
                <TableRow className="child-tr" key={index}>
                  <TableCell>{fee.fee_name}</TableCell>
                  <TableCell>{fee.fee_handling}</TableCell>
                  <TableCell>{fee.vendor}</TableCell>
                  <TableCell className="editable-cell text-right">
                    {defaultCurrency}{' '}
                    {(fee.applied_price ?? fee.default_amount ?? 0).toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2
                    }) || '0.00'}
                  </TableCell>
                  <TableCell className="action-cell fixed-cell">
                    <Menu
                      theme={theme}
                      disablePortal
                      options={[{ optionText: 'Edit Details', optionValue: 'edit_details' }]}
                      handleOptionClick={(_event, _key, value) => {
                        if (value === 'edit_details') {
                          openEditFee(fee)
                        }
                      }}
                      render={(onMenuSelection: any) => (
                        <Button
                          theme={theme}
                          defaultBtn
                          iconText={<Icon name="MoreIcon" />}
                          onClick={onMenuSelection}
                        />
                      )}
                      anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </>
          }
        />
      </DataTableWrap>

      <Dialog
        theme={theme}
        title={selectedFee ? `${selectedFee.fee_name}` : 'License, Title, Registration'}
        size="xs"
        open={isOpen}
        onCloseDialog={handleClose}
        customFooter={
          <>
            <Button theme={theme} secondary text="Cancel" onClick={handleClose} />
            <Button theme={theme} primary text="Save" onClick={onFeeUpdate} disabled={!isDirty} />
          </>
        }
      >
        <Grid theme={theme} container spacing={2}>
          <Grid theme={theme} item xs={12}>
            <Controller
              name="feeType"
              control={control}
              defaultValue={selectedFee?.fee_handling || ''}
              render={({ field }) => (
                <Select
                  theme={theme}
                  label="Fee Type"
                  fullWidth
                  {...field}
                  disabled={!selectedFee?.fee_handling_override}
                  items={getFeeTypeOptions()}
                />
              )}
            />
          </Grid>
          <Grid theme={theme} item xs={12}>
            <Controller
              name="vendorName"
              control={control}
              defaultValue={selectedFee?.vendor || ''}
              render={({ field }) => (
                <Input
                  theme={theme}
                  label="Vendor Name (Optional)"
                  fullWidth
                  type="text"
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid theme={theme} item xs={12}>
            <Controller
              name="feeAmount"
              control={control}
              defaultValue={selectedFee?.applied_price?.toString() || ''}
              render={({ field }) => (
                <Input
                  theme={theme}
                  fullWidth
                  label="Fee Amount"
                  type={'text'}
                  masking
                  maskNumeric
                  placeholder={'0.00'}
                  maskDecimalScale={2}
                  startAdornment={defaultCurrency}
                  {...field}
                />
              )}
            />
          </Grid>
        </Grid>
      </Dialog>

      <DialogTotal
        theme={theme}
        className="dialog-total"
        display="flex"
        gap={2}
        justifyContent="space-between"
      >
        <Box theme={theme} className="fw-700">
          Total
        </Box>
        <Box theme={theme} className="primary" textAlign="right">
          {defaultCurrency}{' '}
          {(totalAmount ?? 0).toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          }) || '0.00'}
        </Box>
      </DialogTotal>
    </>
  )
}

export default EditFees
